import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { CampaignDurationView } from './views';

export const CampaignDuration = ({
  startDate,
  endDate,
  minDate,
  minEndDate,
  onStartChange,
  onEndChange,
  startDisabled,
  endDateVisible,
}) => {
  const intl = useIntl();
  const [endDateEnabled, setEndDateEnabled] = useState(!!endDate || endDateVisible);
  const [startDateMoment, setStartDate] = useState(startDate ? moment(startDate) : null);
  const [endDateMoment, setEndDate] = useState(endDate ? moment(endDate) : null);

  const handleEndDateEnable = value => {
    setEndDateEnabled(value);
    if (!value) {
      setEndDate(null);
      onEndChange(null);
    }
  };

  const handleStartDateChange = (mmntObj, date) => {
    setStartDate(mmntObj);
    onStartChange(date);
    if (endDateEnabled && endDateMoment && moment(mmntObj).isSameOrAfter(endDateMoment)) {
      handleEndDateChange(mmntObj, date);
    }
  };

  const handleEndDateChange = (mmntObj, date) => {
    setEndDate(mmntObj);
    onEndChange(date);
  };

  return (
    <CampaignDurationView
      {...{
        intl,
        startDateMoment,
        endDateMoment,
        endDateEnabled,
        minDate,
        minEndDate,
        handleStartDateChange,
        handleEndDateChange,
        handleEndDateEnable,
        startDisabled,
        endDateVisible,
      }}
    />
  );
};
