export const currencyFormat = value => {
  return new Intl.NumberFormat('en-IN').format(value);
};

export const currencyWithPrefix = value =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
  }).format(value);

export const dateFormat = value => {
  const date = new Date(value);
  return new Intl.DateTimeFormat('en-US').format(date);
};

export const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};
