import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'components/Container';
import { Row } from 'components/Row';
import { Col } from 'components/Col';
import { DatePickerRange } from 'components/DatePicker';
import { ReactComponent as IconHome } from 'assets/icons/IconHome.svg';
import styles from './overview_header.module.scss';

export const OverviewHeaderView = ({ dateRange, onChange, maxDate, account, campaign }) => (
  <Container>
    <Row className={styles.row}>
      <Col>
        <div className={styles.breadcrumbContainer}>
          <IconHome className={styles.homeIcon} />
          <Link to="/dashboard">
            <div className={styles.breadcrumbItemMain}>Home</div>
          </Link>
          {account && (
            <Fragment>
              <span className={styles.separator}>・</span>
              <Link to={`/dashboard/${account.accountId}`}>
                <div className={styles.breadcrumbItem}>{account.name}</div>
              </Link>
            </Fragment>
          )}
          {campaign && (
            <Fragment>
              <span className={styles.separator}>・</span>
              <Link to={`/dashboard/${account.accountId}/${campaign.id}`}>
                <div className={styles.breadcrumbItem}>{campaign.name}</div>
              </Link>
            </Fragment>
          )}
        </div>
      </Col>
      <Col className={styles.rangeContainer}>
        <DatePickerRange value={dateRange} onChange={onChange} maxDate={maxDate} />
      </Col>
    </Row>
  </Container>
);
