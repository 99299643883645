import { createSlice } from '@reduxjs/toolkit';

export const editCampaignSlice = createSlice({
  name: 'edit_campaign',
  initialState: {
    campaign: {},
    initialItems: [],
    createdCampaign: {},
    step: 0,
    steps: null,
    loading: false,
  },
  reducers: {
    setStepAction: (state, action) => {
      state.step = action.payload;
    },
    setStepsAction: (state, action) => {
      state.steps = action.payload;
    },
    setLoadingAction: (state, action) => {
      state.loading = action.payload;
    },
    setCampaignCreatedAction: (state, action) => {
      state.campaign = {};
      state.createdCampaign = action.payload;
    },
    setInitialCampaignAction: (state, action) => {
      state.campaign = action.payload;
    },
    setCampaignAction: (state, action) => {
      state.campaign[action.payload.key] = action.payload.value;
    },
    setAdsetAction: (state, action) => {
      state.adset = action.payload;
    },
    setItemsAction: (state, action) => {
      state.initialItems = action.payload;
    },
  },
});

export const {
  setStepAction,
  setStepsAction,
  setLoadingAction,
  setCampaignCreatedAction,
  setInitialCampaignAction,
  setCampaignAction,
  setAdsetAction,
  setItemsAction,
} = editCampaignSlice.actions;

export const stepSelector = state => state.editCampaign.step;
export const stepsSelector = state => state.editCampaign.steps;
export const loadingSelector = state => state.editCampaign.loading;
export const campaignSelector = state => state.editCampaign.campaign;
export const createdCampaignSelector = state => {
  const { campaigns } = state.editCampaign.createdCampaign;
  return campaigns ? campaigns[0] : {};
};
export const initialItemsSelector = state => state.editCampaign.initialItems;

export default editCampaignSlice.reducer;
