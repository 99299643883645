import React, { Fragment } from 'react';
import { FormContent } from 'components/FormContent';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { PasswordInput } from 'components/TextInput';
import { FormGroup } from 'components/FormGroup';

export const ChangePasswordFormView = ({
  password,
  newPassword,
  newPasswordRepeat,
  handlePasswordChange,
  handleNewPasswordChange,
  handlePasswordRepeatChange,
  error,
  submitDisabled,
  handleSubmit,
  loading,
}) => (
  <FormContent
    title={<FormattedMessage id="title_account_settings" />}
    footerControls={
      <Fragment>
        <Link to="/dashboard">
          <Button type="ghost" size="large">
            <FormattedMessage id="btn_back" />
          </Button>
        </Link>
        <Button size="large" onClick={handleSubmit} loading={loading} disabled={submitDisabled}>
          <FormattedMessage id="btn_change_password" />
        </Button>
      </Fragment>
    }
  >
    <FormGroup title={<FormattedMessage id="form_change_password_old" />}>
      <PasswordInput
        value={password}
        onChange={handlePasswordChange}
        label={<FormattedMessage id="register_form_password" />}
        error={error.password}
        errorText={<FormattedMessage id="form_change_password_error" />}
      />
    </FormGroup>
    <FormGroup title={<FormattedMessage id="form_change_password_new" />}>
      <PasswordInput
        value={newPassword}
        onChange={handleNewPasswordChange}
        label={<FormattedMessage id="form_change_password_new" />}
        error={error.newPassword}
        errorText={<FormattedMessage id="register_form_password_requirements" />}
      />
    </FormGroup>
    <FormGroup title={<FormattedMessage id="form_change_password_repeat" />}>
      <PasswordInput
        value={newPasswordRepeat}
        onChange={handlePasswordRepeatChange}
        label={<FormattedMessage id="reset_password_form_repeat" />}
        error={error.newPasswordRepeat}
        errorText={<FormattedMessage id="reset_password_form_missmatch" />}
      />
    </FormGroup>
  </FormContent>
);
