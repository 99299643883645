import React from 'react';
import { classList } from 'utils/classList';
import { ReactComponent as IconDown } from 'assets/icons/IconDown.svg';
import styles from './Drawer.module.scss';

export const DrawerView = ({ expanded, title, content, handleExpand }) => (
  <div className={styles.drawer}>
    <div className={classList(styles.titleContainer, expanded && styles.expanded)} onClick={handleExpand}>
      <div>{title}</div>
      <IconDown className={classList(styles.icon, expanded && styles.expanded)} />
    </div>
    {expanded && <div className={styles.contentContainer}>{content}</div>}
  </div>
);
