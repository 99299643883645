import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { ReactComponent as IconTrash } from 'assets/icons/IconTrash.svg';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import styles from './DeliveryRules.module.scss';

const ruleOptions = [
  {
    name: <FormattedMessage id="form_delivery_rules_contains" />,
    value: 'contains',
  },
  {
    name: <FormattedMessage id="form_delivery_rules_not_contain" />,
    value: 'notContains',
  },
  {
    name: <FormattedMessage id="form_delivery_rules_equal" />,
    value: 'equal',
  },
  {
    name: <FormattedMessage id="form_delivery_rules_not_equal" />,
    value: 'notEqual',
  },
];

export const DeliveryRulesView = ({ rules, handleRuleChange, handleUrlChange, handleAddRule, handleRemoveRule }) => (
  <div className={styles.container}>
    {rules.map((rule, id) => (
      <div key={id} className={styles.ruleContainer}>
        <SelectInput
          label={<FormattedMessage id="form_delivery_rules_page_url" />}
          options={ruleOptions}
          value={rule.rule}
          onChange={e => handleRuleChange(e, id, rule)}
        />
        <TextInput
          label={<FormattedMessage id="form_delivery_rules_enter_page_url" />}
          value={rule.url}
          onChange={e => handleUrlChange(e, id, rule)}
        />
        {id > 0 && (
          <Button
            type="ghost"
            iconOnly
            className={styles.remove}
            size="large"
            icon={<IconTrash />}
            onClick={() => handleRemoveRule(id)}
          />
        )}
      </div>
    ))}
    <Button className={styles.add} type="primary" icon={<IconCreate />} onClick={handleAddRule}>
      <FormattedMessage id="form_delivery_rules_add_option" />
    </Button>
  </div>
);
