import React from 'react';
import { Container } from '../Container';
import styles from './Footer.module.scss';

export const Footer = ({ children }) => (
  <footer>
    <div className={styles.visible}>
      <Container className={styles.container}>{children}</Container>
    </div>
    <div className={styles.marginDummy} />
  </footer>
);
