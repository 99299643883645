import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from 'components/Header';
import { setInitialStateAction } from './redux';
import { ChangePasswordForm } from './change_password_form';

const ChangePassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setInitialStateAction());
  }, []);

  return (
    <Fragment>
      <Header />
      <ChangePasswordForm />
    </Fragment>
  );
};

export default ChangePassword;
