import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { settingsSelector } from 'store/settings';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  stepSelector,
  stepsSelector,
} from '../../redux';
import { SponsoredBrandsAddView } from './views';

export const SponsoredBrandsAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector(settingsSelector);
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const brandOptions = settings.brands;

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleNext = async () => {
    history.push(`/edit-campaign/${campaign.campaignId}/sponsored-brands-budget`);
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(2));
  }, []);

  return (
    <SponsoredBrandsAddView
      {...{
        step,
        steps,
        campaign,
        brandOptions,
        loading,
        handleBack,
        handleNext,
        handleChange,
      }}
    />
  );
};
