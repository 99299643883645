import React from 'react';
import { BannerBudgetForm } from 'forms/banner/banner_budget_form';

export const BannerBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <BannerBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
  />
);
