import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { VendorBannerBudgetFormView } from './views';

const BidOptions = [15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50].map(item => ({
  name: `€${item.toFixed(2)}`,
  value: item,
}));

export const VendorBannerBudgetForm = ({
  step,
  steps,
  campaign,
  loading,
  handleBack,
  handleNext,
  handleChange,
  isEdit,
}) => {
  const { dailyBudget, totalBudget, maxCPC, startDate, endDate } = campaign;
  const [dailyError, setDailyError] = useState(null);
  const [totalError, setTotalError] = useState(null);
  const [minDate] = useState(moment().startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [totalBudgetEnabled, setTotalBudgetEnabled] = useState(!!totalBudget);
  const [nextDisabled, setNextDisabled] = useState(false);

  const handleDailyBudgetChange = value => {
    if (value < 1) {
      setDailyError('form_daily_budget_too_small_error');
    } else if (totalBudgetEnabled && value > totalBudget) {
      setDailyError('form_daily_budget_too_big_error');
    } else if (maxCPC && value < maxCPC) {
      setDailyError('form_max_cpc_daily_small_error');
    } else {
      setDailyError();
    }
    handleChange({ key: 'dailyBudget', value });
  };

  const handleTotalBudgetChange = value => {
    if (totalBudgetEnabled && dailyBudget > value) {
      setTotalError('form_total_budget_too_small_error');
    } else {
      setTotalError();
    }
    handleChange({ key: 'totalBudget', value });
  };

  const handleCPCChange = value => {
    handleChange({ key: 'maxCPC', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handleTotalBudgetToggle = value => {
    setTotalBudgetEnabled(value);
    if (!value) {
      handleTotalBudgetChange(0);
      setTotalError();
      setDailyError();
    }
  };

  useEffect(() => {
    setNextDisabled(
      (totalBudgetEnabled && !totalBudget) || !dailyBudget || !maxCPC || !startDate || dailyError || totalError
    );
  }, [totalBudgetEnabled, totalBudget, dailyBudget, maxCPC, startDate, dailyError, totalError]);

  return (
    <VendorBannerBudgetFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        dailyBudget,
        handleDailyBudgetChange,
        totalBudgetEnabled,
        totalBudget,
        handleTotalBudgetToggle,
        handleTotalBudgetChange,
        maxCPC,
        handleCPCChange,
        startDate,
        endDate,
        minDate,
        minEndDate,
        handleStartDateChange,
        handleEndDateChange,
        dailyError,
        totalError,
        BidOptions,
        isEdit,
      }}
    />
  );
};
