import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'components/Container';
import { Table } from 'components/Table';
import { DownloadXlsForm } from 'components/Table/download';
import { TextInput } from 'components/TextInput';
import { ReactComponent as IconSearch } from 'assets/icons/IconSearch.svg';
import { fetchDashboardDataCampaign } from '../async';
import { Charts } from '../charts';
import { OverviewHeader } from '../overview_header';
import styles from './admin_dashboard.module.scss';

export const AdminDashboardView = ({
  chartData,
  columnConfig,
  accounts,
  handleQueryChange,
  pageSize,
  handlePageSizeChange,
  loading,
}) => (
  <Fragment>
    <OverviewHeader fetchAction={fetchDashboardDataCampaign} />
    <Charts chartData={chartData} />
    <Container className={styles.searchContainer}>
      <div className={styles.searchInput}>
        <TextInput label={<FormattedMessage id="input_search_accounts_placeholder" />} prefixIcon={<IconSearch />} onChange={handleQueryChange} />
      </div>
      <div style={{marginLeft: "8px"}}>
        <DownloadXlsForm
          columnConfig={columnConfig}
          tableData={accounts}/>
      </div>
    </Container>
    <Container>
      <Table
        columnConfig={columnConfig}
        tableData={accounts}
        loading={loading}
        pageSize={pageSize}
        onUpdatePageSize={handlePageSizeChange}
      />
    </Container>
  </Fragment>
);
