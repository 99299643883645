import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmpty, isNotEmptyArray } from 'utils/validator';
import { ProductFormView } from './views';
import { getProductCount, getSellerList } from 'pages/new_campaign/async';

export const ProductAddForm = ({
  step,
  steps,
  campaign,
  shopOptions,
  initialItems = [],
  searchItems = [],
  invalidItems,
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleItemsChange,
  handleAdd,
  handleSearch,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { enabled_all_product_selection_shops, piguSellerId} = useSelector(state => state.settings);
  const { name, shopId, sellerId, sellerOptions, itemUrlText, items = [] } = campaign;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [allProductSelected, setAllProductSelected] = useState(false);
  const [productCount, setProductCount] = useState(0);

  const products = uniqBy([...items, ...searchItems], 'itemId');

  useEffect(() => {
    handleChange({ key: 'all_product', value: allProductSelected });
  }, [allProductSelected]);

  useEffect(() => {
    const getNumberofProduct = async shop_id => {
      const { payload } = await dispatch(getProductCount(shop_id));
      setProductCount(payload?.count);
    };
    if (shopId) {
      getNumberofProduct(shopId);
    }
  }, [shopId, sellerId]);

  const handleRemoveAllItems = () => {
    handleChange({ key: 'items', value: [] });
  };

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = async value => {
    handleChange({ key: 'shopId', value });
    const { payload } = await dispatch(getSellerList(value));
    const options = payload.map(item => ({ name: item, value: item }));
    handleChange({ key: 'sellerOptions', value: options });
    handleRemoveAllItems();
  };

  const handleSellerChange = value => {
    handleChange({ key: 'sellerId', value });
  };

  const handleItemsUrlChange = ({ target: { value } }) => {
    handleChange({ key: 'itemUrlText', value });
  };

  const handleInitialItemsChange = (id, value) => {
    const newItems = initialItems.map(item => {
      if (item.id === id) {
        return {
          ...item,
          disabled: !value,
        };
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  const handleRemoveItem = itemId => {
    const newItems = items.filter(item => item.itemId !== itemId);
    handleChange({ key: 'items', value: newItems });
  };

  const handleSelectedItemsChange = (itemId, selected, all) => {
    if (all) {
      handleChange({
        key: 'items',
        value: uniqBy(
          products.map(item => ({ ...item, selected: true })),
          'itemId'
        ),
      });
      return;
    }
    if (selected) {
      const newItem = products.find(item => item.itemId === itemId);
      handleChange({ key: 'items', value: uniqBy([...items, { ...newItem, selected }], 'itemId') });
    } else {
      handleRemoveItem(itemId);
    }
  };


  const handleUpdateItem = value => {
    handleChange({
      key: 'items',
      value: items.map(item => {
        if (item.itemId === value.itemId) {
          return value;
        }
        return item;
      }),
    });
  };

  useEffect(() => {
    if (!enabled_all_product_selection_shops.includes(shopId)) setAllProductSelected(false);
    else setAllProductSelected(true);
  }, [shopId]);

  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name)
      : !isNotEmpty(name) || !shopId || (!allProductSelected && !isNotEmptyArray(items));
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, items, allProductSelected]);

  return (
    <ProductFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        sellerId,
        sellerOptions,
        handleSellerChange,
        itemUrlText,
        handleItemsUrlChange,
        handleSelectedItemsChange,
        handleRemoveItem,
        handleRemoveAllItems,
        initialItems,
        handleInitialItemsChange,
        products,
        invalidItems,
        handleAdd,
        handleSearch,
        items,
        handleUpdateItem,
        isEdit,
        allProductSelected,
        setAllProductSelected,
        productCount,
        enabled_all_product_selection_shops,
        piguSellerId
      }}
    />
  );
};
