import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectInput } from 'components/SelectInput';

const statusOptions = [
  {
    name: <FormattedMessage id="label_all" />,
    value: 'all',
  },
  {
    name: <FormattedMessage id="status_enabled" />,
    value: 'enabled',
  },
  {
    name: <FormattedMessage id="status_enabled_paused" />,
    value: 'enabled_paused',
  },
  {
    name: <FormattedMessage id="status_active" />,
    value: 'active',
  },
  {
    name: <FormattedMessage id="status_paused" />,
    value: 'paused',
  },
  {
    name: <FormattedMessage id="status_ended" />,
    value: 'ended',
  },
  {
    name: <FormattedMessage id="status_low_bid" />,
    value: 'low_bid',
  },
  {
    name: <FormattedMessage id="status_out_of_budget" />,
    value: 'out_of_budget',
  },
  {
    name: <FormattedMessage id="status_under_review" />,
    value: 'under_review',
  },
  {
    name: <FormattedMessage id="status_lost_buybox" />,
    value: 'lost_buybox',
  },
  // {
  //   name: <FormattedMessage id="status_scheduled" />,
  //   value: 'scheduled',
  // },
  {
    name: <FormattedMessage id="status_duplicated" />,
    value: 'duplicated',
  },
  {
    name: <FormattedMessage id="status_deleted" />,
    value: 'deleted',
  },
];

export const StatusSelect = ({ value, onChange }) => (
  <SelectInput
    value={value}
    onChange={onChange}
    label={<FormattedMessage id="status_label" defaultMessage="Campaign status" />}
    options={statusOptions}
  />
);
