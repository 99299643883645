import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { userSelector } from '../../login/redux';
import { selectDateRangeAction, dateRangeSelector, accountByIdSelector, campaignSelector } from '../redux';
import { OverviewHeaderView } from './views';

export const OverviewHeader = ({ fetchAction, isProduct }) => {
  let { accountId, campaignId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const account = useSelector(state => accountByIdSelector(state, accountId));
  const campaign = useSelector(state => campaignSelector(state, '', campaignId)); //TODO IMPROVE

  const initDateRange = useSelector(dateRangeSelector);
  const [maxDate] = useState(moment().endOf('day'));

  const [dateRange, setDateRange] = useState([moment(initDateRange[0]), moment(initDateRange[1])]);

  const onChange = value => {
    setDateRange(value);
    dispatch(selectDateRangeAction([...value, true]));
    const payload = isProduct ? campaignId : (accountId || user.accountId)
    dispatch(fetchAction(payload));
  };

  return (
    <OverviewHeaderView
      {...{
        accountId,
        dateRange,
        onChange,
        maxDate,
        account,
        campaign,
      }}
    />
  );
};
