import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { VendorProductBudgetFormView } from './views';

export const VendorProductBudgetForm = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange, isEdit }) => {
  const { dailyBudget, totalBudget, maxCPC, startDate, endDate } = campaign;
  const [dailyError, setDailyError] = useState(null);
  const [totalError, setTotalError] = useState(null);
  const [maxCPCError, setmaxCPCError] = useState(null);
  const [maxCPCWarning, setmaxCPCWarning] = useState(null);
  const [minDate] = useState(moment().startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [totalBudgetEnabled, setTotalBudgetEnabled] = useState(!!totalBudget);
  const [nextDisabled, setNextDisabled] = useState(false);

  const handleDailyBudgetChange = value => {
    if (value < 1) {
      setDailyError('form_daily_budget_too_small_error');
    } else if (totalBudgetEnabled && value > totalBudget) {
      setDailyError('form_daily_budget_too_big_error');
    } else if (maxCPC && value < maxCPC) {
      setDailyError('form_max_cpc_daily_small_error');
    } else {
      setDailyError();
    }
    handleChange({ key: 'dailyBudget', value });
  };

  const handleTotalBudgetChange = value => {
    if (totalBudgetEnabled && dailyBudget > value) {
      setTotalError('form_total_budget_too_small_error');
    } else {
      setTotalError();
    }
    handleChange({ key: 'totalBudget', value });
  };

  const handleCPCChange = value => {
    if (value < 0.2) {
      setmaxCPCError('form_max_cpc_too_small_error');
    } else if (value > 2) {
      setmaxCPCError('form_max_cpc_too_big_error');
    } else if (value > dailyBudget) {
      setmaxCPCError('form_max_cpc_daily_small_error');
    } else {
      setmaxCPCError();
    }

    if (value <= dailyBudget) {
      setDailyError();
    }

    if (value > 1 && value < 2) {
      setmaxCPCWarning('form_max_cpc_warning');
    } else {
      setmaxCPCWarning();
    }
    handleChange({ key: 'maxCPC', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handleTotalBudgetToggle = value => {
    setTotalBudgetEnabled(value);
    if (!value) {
      handleTotalBudgetChange(0);
      setTotalError();
      setDailyError();
    }
  };

  useEffect(() => {
    setNextDisabled(
      (totalBudgetEnabled && !totalBudget) ||
        !dailyBudget ||
        !maxCPC ||
        !startDate ||
        dailyError ||
        totalError ||
        maxCPCError
    );
  }, [totalBudgetEnabled, totalBudget, dailyBudget, maxCPC, startDate, dailyError, totalError, maxCPCError]);

  return (
    <VendorProductBudgetFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        dailyBudget,
        handleDailyBudgetChange,
        totalBudgetEnabled,
        totalBudget,
        handleTotalBudgetToggle,
        handleTotalBudgetChange,
        maxCPC,
        handleCPCChange,
        startDate,
        endDate,
        minDate,
        minEndDate,
        handleStartDateChange,
        handleEndDateChange,
        dailyError,
        totalError,
        maxCPCError,
        maxCPCWarning,
        isEdit,
      }}
    />
  );
};
