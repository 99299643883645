import React from 'react';
import { FormattedNumber } from 'react-intl';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import moment from 'moment';
import './styles.scss';

const CustomizedDot = ({ cx, cy }) => {
  return <circle cx={cx} cy={cy} r={4} stroke="#fff" strokeWidth={2} fill="#001B3A" />;
};

const CustomTooltip = ({ active, payload, label, field }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <div className="value">
          {field}: <FormattedNumber value={payload && payload[0] && payload[0].value} />
        </div>
        <div className="date">{moment(label).format('MM-DD')}</div>
      </div>
    );
  }

  return null;
};

export const Chart = ({ data = [], label = '', valueY, yTickFormatter = undefined }) => {
  return (
    <div className={'line-chart-wrapper'}>
      <div className="title">{label}</div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#BBBBBB" horizontal={false} />
          <XAxis
            dataKey="date"
            height={100}
            tickMargin={20}
            tickLine={false}
            axisLine={false}
            tickFormatter={tick => moment(tick).format('MM-DD')}
          />
          <YAxis
            dataKey={valueY}
            tickLine={false}
            axisLine={false}
            width={100}
            tickMargin={20}
            tickSize={1}
            tickFormatter={yTickFormatter}
          />
          <Tooltip content={<CustomTooltip field={label} />} />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(71, 156, 255, 0.2)" stopOpacity={0.8} />
              <stop offset="100%" stopColor=" rgba(71, 156, 255, 0)" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="linear"
            dataKey={valueY}
            stroke="#479cff"
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={<CustomizedDot />}
            strokeWidth={3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
