import React, { Fragment } from 'react';
import { Footer } from 'components/Footer';
import { Container } from 'components/Container';
import styles from './FormContent.module.scss';

export const FormContent = ({ title, footerControls, children }) => (
  <Fragment>
    <Container>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </Container>
    <Footer>
      <div className={styles.footerContent}>{footerControls}</div>
    </Footer>
  </Fragment>
);
