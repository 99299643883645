import React from 'react';
import { ProductAddForm } from 'forms/product/product_add_form';

export const ProductAddView = ({
  step,
  steps,
  campaign,
  shopOptions,
  invalidItems,
  searchItems,
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleSearch,
  handleAdd,
}) => (
  <ProductAddForm
    {...{
      step,
      steps,
      campaign,
      shopOptions,
      invalidItems,
      searchItems,
      loading,
      handleBack,
      handleNext,
      handleChange,
      handleSearch,
      handleAdd,
    }}
  />
);
