import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BannerBudgetFormView } from './views';

export const BannerBudgetForm = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange, isEdit }) => {
  const {
    priorityType,
    startDate,
    endDate,
    pricingModel,
    pricingModelPrice,
    totalBudget,
    limit,
    limitAmount,
    limitType,
  } = campaign;
  const [minDate] = useState(moment().startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [advancedSettingsEnabled, setAdvancedSettingsEnabled] = useState(false);

  const handlePriorityTypeChange = value => {
    handleChange({ key: 'priorityType', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handlePricingModelChange = value => {
    handleChange({ key: 'pricingModel', value });
  };

  const handlePricingModelPriceChange = value => {
    handleChange({ key: 'pricingModelPrice', value });
  };

  const handleTotalBudgetChange = value => {
    handleChange({ key: 'totalBudget', value });
  };

  const handleLimitChange = value => {
    handleChange({ key: 'limit', value });
  };

  const handleLimitAmountChange = ({ target: { value } }) => {
    handleChange({ key: 'limitAmount', value });
  };

  const handleLimitTypeChange = value => {
    handleChange({ key: 'limitType', value });
  };

  const handleEnableAdvancedSettings = () => {
    setAdvancedSettingsEnabled(!advancedSettingsEnabled);
  };

  useEffect(() => {
    const pricingValid = pricingModel === 'none' ? true : pricingModelPrice;
    setNextDisabled(!startDate || !endDate || !pricingValid);
  }, [startDate, endDate, pricingModel, pricingModelPrice]);

  return (
    <BannerBudgetFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        priorityType,
        minDate,
        minEndDate,
        startDate,
        endDate,
        pricingModel,
        pricingModelPrice,
        totalBudget,
        limit,
        limitAmount,
        limitType,
        advancedSettingsEnabled,
        handlePriorityTypeChange,
        handleStartDateChange,
        handleEndDateChange,
        handlePricingModelChange,
        handlePricingModelPriceChange,
        handleTotalBudgetChange,
        handleLimitChange,
        handleLimitAmountChange,
        handleLimitTypeChange,
        handleEnableAdvancedSettings,
        isEdit,
      }}
    />
  );
};
