import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uniqBy, debounce } from 'lodash';
import { editHomeAndProductsSteps } from 'components/ProgressBar';
import { itemUrlLookup, itemSearch } from '../../async';
import {
  setStepAction,
  setStepsAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setLoadingAction,
  stepSelector,
  stepsSelector,
  initialItemsSelector,
  setItemsAction,
} from '../../redux';
import { ProductAddView } from './views';

export const ProductAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const initialItems = useSelector(initialItemsSelector);
  const [invalidItems, setInvalidItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleAdd = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemUrlLookup({ shopId: campaign.shopId, itemUrlText: campaign.itemUrlText }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    if (payload.some(item => !item.itemId)) {
      setInvalidItems(payload.filter(item => !item.itemId));
    } else {
      dispatch(setCampaignAction({ key: 'items', value: uniqBy(payload, 'itemId') }));
      setInvalidItems([]);
    }
    dispatch(setLoadingAction(false));
  };

  const handleSearch = async (query = '', limit = 50) => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemSearch({ shopId: campaign.shopId, query, limit, }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    setSearchItems(
      payload.map(item => ({
        id: item.itemId,
        title: item.name,
        ...item,
      }))
    );
    dispatch(setLoadingAction(false));
  };

  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (campaign.shopId) handleSearch();
  }, [campaign.shopId]);

  const handleNext = async () => {
    history.push(`/edit-campaign/${campaign.campaignId}/product-budget`);
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  const handleItemsChange = items => {
    dispatch(setItemsAction(items));
  };

  useEffect(() => {
    dispatch(setStepsAction(editHomeAndProductsSteps));
    dispatch(setStepAction(1));
  }, []);

  return (
    <ProductAddView
      {...{
        step,
        steps,
        campaign,
        initialItems,
        invalidItems,
        searchItems,
        loading,
        handleBack,
        handleNext,
        handleAdd,
        handleChange,
        handleItemsChange,
        handleSearch: debouncedSearch,
      }}
    />
  );
};
