import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uniqBy, debounce } from 'lodash';
import { accountSelector } from 'pages/dashboard/redux';
import { itemUrlLookup, itemSearch } from '../../async';
import { settingsSelector } from 'store/settings';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../../redux';
import { shopMap } from '../../shared';
import { RetailProductAddView } from './views';

export const RetailProductAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector(settingsSelector);
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const userAccount = useSelector(accountSelector);
  const shopOptions = userAccount ? userAccount.shopIds.map(id => ({ name: shopMap[id] || id, value: id })) : [];
  const [invalidItems, setInvalidItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [shopPreselected, setShopPreselected] = useState(false);

  const handleBack = () => {
    history.push('/new-campaign');
  };

  const handleAdd = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemUrlLookup({ shopId: campaign.shopId, itemUrlText: campaign.itemUrlText }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    if (payload.some(item => !item.itemId)) {
      setInvalidItems(payload.filter(item => !item.itemId));
    } else {
      dispatch(setCampaignAction({ key: 'items', value: uniqBy(payload, 'itemId') }));
      setInvalidItems([]);
    }
    dispatch(setLoadingAction(false));
  }

  const handleSearch = async (query = '', limit = 50) => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemSearch({ shopId: campaign.shopId, query, limit, }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    setSearchItems(payload.map(item => ({
      id: item.itemId,
      title: item.name,
      ...item,
    })));
    dispatch(setLoadingAction(false));
  }

  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (campaign.shopId && settings.has_item_search) handleSearch();
  }, [campaign.shopId]);

  const handleNext = async () => {
    history.push('/new-campaign/retail-product-budget');
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(2));

    const isEuroVaistine = userAccount.shopIds.includes('eurovaistinelt');
    const isRadiopopular = userAccount.shopIds.includes('radiopopularpt');
    
    if (isEuroVaistine) {
      // default values
      if (!campaign.priorityType) dispatch(setCampaignAction({key: 'priorityType', value: 'contract'}));
      if (!campaign.pricingModel) dispatch(setCampaignAction({key: 'pricingModel', value: 'fixed'}));
    }
    if (isRadiopopular) {
      if (!campaign.priorityType) dispatch(setCampaignAction({key: 'priorityType', value: 'internal'}));
      if (!campaign.pricingModel) dispatch(setCampaignAction({key: 'pricingModel', value: 'cpc'}));
      dispatch(setCampaignAction({key: 'shopId', value: 'radiopopularpt'}));
      setShopPreselected(true);
    }
  }, []);

  return (
    <RetailProductAddView
      {...{
        step,
        steps,
        campaign,
        userAccount,
        shopOptions,
        invalidItems,
        searchItems,
        loading,
        handleBack,
        handleNext,
        handleAdd,
        handleChange,
        handleSearch: debouncedSearch,
        shopPreselected,
        settings
      }}
    />
  );
};
