const regExps = {
  // eslint-disable-next-line no-useless-escape
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[A-Z])(?=.*[0-9]).{8,100}$/,
};

export const validateEmail = value => {
  return regExps.email.test(value);
};

export const validatePassword = value => {
  return regExps.password.test(value);
};

export const isNotEmpty = (value = '') => {
  return value && !!value.trim().length;
};

export const isNotEmptyArray = (value = []) => {
  return value && !!value.length;
};

export const isUrl = str => /^https?:\/\//.test(str);
