import React, { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { isNotEmpty, isNotEmptyArray } from 'utils/validator';
import { VendorProductFormView } from './views';

export const VendorProductAddForm = ({
  step,
  steps,
  campaign,
  userAccount,
  shopOptions,
  initialItems = [],
  searchItems = [],
  invalidItems,
  shopPreselected,
  loading,
  handleBack,
  handleAdd,
  handleNext,
  handleChange,
  handleSearch,
  handleItemsChange,
  isEdit,
}) => {
  const { name, shopId, itemUrlText, items = [] } = campaign;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [initialItemsExpanded, setInitialItemsExpanded] = useState(false);
  
  const products = uniqBy([...items, ...searchItems], 'itemId');

  const handleItemsExpand = value => {
    setInitialItemsExpanded(value);
  };

  const handleRemoveAllItems = () => {
    handleChange({ key: 'items', value: [] });
  };

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = value => {
    handleChange({ key: 'shopId', value });
    handleRemoveAllItems();
  };

  const handleItemsUrlChange = ({ target: { value } }) => {
    handleChange({ key: 'itemUrlText', value });
  };

  const handleInitialItemsChange = value => {
    const newItems = initialItems.map(item => {
      if (item.id === value.id) {
        return value;
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  const handleRemoveItem = itemId => {
    const newItems = items.filter(item => item.itemId !== itemId);
    handleChange({ key: 'items', value: newItems });
  };

  const handleSelectedItemsChange = (itemId, selected, all) => {
    if (all) {
      handleChange({ key: 'items', value: uniqBy(products.map(item => ({ ...item, selected: true})), 'itemId') });
      return;
    }
    if (selected) {
      const newItem = products.find(item => item.itemId === itemId);
      handleChange({ key: 'items', value: uniqBy([...items, { ...newItem, selected }], 'itemId') });
    } else {
      handleRemoveItem(itemId);
    }
  }

  const handleUpdateItem = value => {
    handleChange({
      key: 'items',
      value: items.map(item => {
        if (item.itemId === value.itemId) {
          return value;
        }
        return item;
      }),
    });
  };

  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name)
      : !isNotEmpty(name) || !shopId || !isNotEmptyArray(items);
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, items]);

  return (
    <VendorProductFormView
      {...{
        step,
        steps,
        handleBack,
        handleAdd,
        handleNext,
        handleSelectedItemsChange,
        loading,
        nextDisabled,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        itemUrlText,
        handleItemsUrlChange,
        initialItems,
        handleInitialItemsChange,
        products,
        invalidItems,
        items,
        shopPreselected,
        handleUpdateItem,
        handleRemoveItem,
        handleRemoveAllItems,
        handleSearch,
        initialItemsExpanded,
        handleItemsExpand,
        isEdit,
      }}
    />
  );
};
