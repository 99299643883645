import React, { Fragment, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { persistStore } from 'redux-persist';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import Router from './router';
import store from './store';
import { getLogin, statusSelector, userSelector } from './pages/login/redux';
import { accountSelector } from './pages/dashboard/redux';
import { languageSelector, setAccountAction, themeSelector } from 'store/settings';
import { Spinner } from './components/Spinner';

import { UserRegister } from 'pages/user_register_popup';
import { ResetPassword } from 'pages/password_reset_popup';

import messages_en from './lang/en.json';
import messages_lt from './lang/lt.json';
import messages_lv from './lang/lv.json';
import messages_ee from './lang/ee.json';
import messages_fr from './lang/fr.json';
import messages_pl from './lang/pl.json';
import messages_fi from './lang/fi.json';

import moment from 'moment';
import 'moment/locale/et';
import 'moment/locale/lv';
import 'moment/locale/lt';
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/fi';
import { NewAppNotificationModal } from './newAppNotificationModal';

moment.locale('en');

const messages = {
  en: messages_en,
  lt: messages_lt,
  lv: messages_lv,
  ee: messages_ee,
  fr: messages_fr,
  pl: messages_pl,
  fi: messages_fi,
};

const Root = () => {
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const themeClass = useSelector(themeSelector);
  const user = useSelector(userSelector);
  const account = useSelector(accountSelector);

  useEffect(() => {
    dispatch(getLogin());
  }, []);

  useEffect(() => {
    document.body.classList.add(themeClass);
  }, [themeClass]);

  useEffect(() => {
    dispatch(setAccountAction(account));
  }, [account]);

  return (
    <Fragment>
      {status === 'loading' ? (
        <Spinner fullScreen size="large" />
      ) : (
        <Fragment>
          <Router isAuthorized={user.accountId} />
          {user.accountId && user.piguNewSeller && <UserRegister />}
          {user.accountId && user.shouldResetPassword && <ResetPassword />}
        </Fragment>
      )}
    </Fragment>
  );
};

// separate component for getting access to store
const Intl = ({ children }) => {
  const selectedLanguage = useSelector(languageSelector);
  return (
    <IntlProvider messages={messages[selectedLanguage]} locale={selectedLanguage} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

const persistor = persistStore(store);

const App = () => {
  // Only show alert for pigu, as some clients haven't been migrated yet
  const isPigu = window.location.hostname.includes('pigu');

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Intl>
            {isPigu && <NewAppNotificationModal />}
            <Root />
          </Intl>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
