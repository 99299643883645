import React from 'react';
import { ProductAddForm } from 'forms/product/product_add_form';

export const ProductAddView = ({
  step,
  steps,
  campaign,
  initialItems,
  invalidItems,
  searchItems,
  loading,
  handleBack,
  handleNext,
  handleAdd,
  handleChange,
  handleItemsChange,
  handleSearch,
}) => (
  <ProductAddForm
    {...{
      step,
      steps,
      campaign,
      initialItems,
      invalidItems,
      searchItems,
      loading,
      handleBack,
      handleNext,
      handleAdd,
      handleChange,
      handleItemsChange,
      handleSearch,
    }}
    isEdit
  />
);
