import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { settingsSelector } from 'store/settings';
import { editSponsoredBrandsCampaign } from '../../async';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../../redux';
import { SponsoredBrandsBudgetView } from './views';

export const SponsoredBrandsBudget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const settings = useSelector(settingsSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const [showModal, setShowModal] = useState(false);

  const handleBack = () => {
    history.push(`/edit-campaign/${campaign.campaignId}/sponsored-brands`);
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(editSponsoredBrandsCampaign());
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(4));
    history.replace(`/edit-campaign/${campaign.campaignId}/success`);
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    // setShowModal(false);
  };

  useEffect(() => {
    dispatch(setStepAction(3));
  }, []);

  return (
    <SponsoredBrandsBudgetView
      {...{
        step,
        steps,
        campaign,
        loading,
        handleBack,
        handleNext,
        handleChange,
        showModal, 
        openModal, 
        closeModal,
      }}
    />
  );
};
