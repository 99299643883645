import React, { useState } from 'react';
import { FaqView } from './views';

export const Faq = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpanded = id => {
    setExpanded(id);
  };

  return (
    <FaqView
      {...{
        expanded,
        handleExpanded,
      }}
    />
  );
};
