import React from 'react';
import { ProductBudgetForm } from 'forms/product/product_budget_form';

export const ProductBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <ProductBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
    isEdit
  />
);
