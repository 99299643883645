import React, { Fragment } from 'react';
import { ProductBudgetForm } from 'forms/product/product_budget_form';
import { FormattedMessage } from 'react-intl';
import { Dialog } from 'components/Dialog';
import styles from './budget.module.scss';
import { Spinner } from 'components/Spinner';

export const ProductBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange, showModal, openModal, closeModal }) => (
  <Fragment>
    <ProductBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
  />
    {
      showModal &&
      (<Dialog title={<FormattedMessage id="form_campaign_created_title" />} open={openModal} onClose={closeModal}>
        <div className={styles.dialogContainer}>
          <div className={styles.dialogContent}>
            <div className={styles.subtitle}>
              <FormattedMessage id="form_campaign_created_description" />
            </div>
            <div className={styles.spinner}>
              <Spinner size="large"/>
            </div>
          </div>
        </div>
      </Dialog>)
    }
    </Fragment>
);
