const campaignTypes = {
  'HP': ['demo-product', 'vendor-product', 'retail-product', 'product'],
  'C': ['demo-category', 'cpm-category'],
  'B': ['banner', 'retail-banner', 'vendor-banner'],
  'FB': ['facebook-ads'],
  'SPA': ['sponsored-product'],
};

export const filterTables = (arr, query, type, status) => {
  if (arr && arr.length) {
    let result = arr.filter(o => {
      return o.name && o.name.toLowerCase().includes(query.toLowerCase());
    });

    if (type && type !== 'all') {
      result = result.filter(o => campaignTypes[type].includes(o.campaignType));
    }

    if (status === 'all') {
      return result;
    } else if (status === 'deleted') {
      return result.filter(o => o.status === 'deleted');
    } else if (status === 'enabled') {
      return result.filter(o => !o.disabled && o.status !== 'deleted');
    } else if (status === 'paused') {
      return result.filter(o => o.disabled);
    } else if (status === 'enabled_paused') {
      return result.filter(o => o.status !== 'deleted');
    } else {
      let enabled = result.filter(o => !o.disabled);
      return enabled.filter(o => o.status === status);
    }
  }
};
