import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container } from 'components/Container';
import { Row } from 'components/Row';
import { Col } from 'components/Col';
import styles from './dashboard.module.scss';
import { BannerZonesTable } from './table';
import { bannerZonesSelector } from '../redux';

export const BannerZonesDashboard = () => {
  const items = useSelector(bannerZonesSelector);

  return (
    <Container>
      <Row>
        <Col>
          <h1 className={styles.title}>
            <FormattedMessage id="banner_zones_title" />
          </h1>
        </Col>
      </Row>
      <Row>
        <BannerZonesTable
          items={items}
        />
      </Row>
    </Container>
  );
};
