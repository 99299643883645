import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import FacebookPreview from 'assets/images/FacebookPreview.png';
import styles from './facebook_ads_form.module.scss';

export const FacebookAdsFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  dailyBudget,
  handleDailyBudgetChange,
  dailyBudgetOptions,
  duration,
  handleCampaignDurationChange,
  campaignDurationOptions,
  loading,
  nextDisabled,
  totalCosts,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create_campaign" />}
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    {window.location.hostname !== 'pigu.martailer.com' && (
      <Fragment>
        <FormGroup title={<FormattedMessage id="form_facebook_pre_payment" />}>
          <div className={styles.info}>
            <FormattedMessage id="form_facebook_pre_payment_description" />
          </div>
        </FormGroup>
        <FormGroup title={<FormattedMessage id="form_facebook_promo" />}>
          <div className={styles.info}>
            <FormattedMessage id="form_facebook_promo_description"  values={{
              br: <br />}}/>
          </div>
        </FormGroup>
      </Fragment>
    )}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    <FormGroup
      className={styles.costs}
      title={<FormattedMessage id="form_daily_budget" />}
      subtitle={<FormattedMessage id="form_daily_budget_description" />}
      tooltip={<FormattedMessage id="form_daily_budget_tooltip" />}
    >
      <SelectInput
        label={<FormattedMessage id="form_daily_budget_placeholder" />}
        value={dailyBudget}
        options={dailyBudgetOptions}
        onChange={handleDailyBudgetChange}
      />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_campaign_duration" />}
      subtitle={<FormattedMessage id="form_campaign_duration_months" />}
    >
      <SelectInput
        label={<FormattedMessage id="form_campaign_duration_description" />}
        value={duration}
        options={campaignDurationOptions}
        onChange={handleCampaignDurationChange}
      />
    </FormGroup>
    {!!dailyBudget && duration && (
      <FormGroup
        className={styles.costs}
        title={<FormattedMessage id="form_total_budget" />}
        subtitle={<FormattedMessage id="form_total_budget_facebook_description" values={{ br: <br /> }} />}
        tooltip={<FormattedMessage id="form_total_budget_facebook_tooltip" />}
      >
        <div className={styles.costsSum}>€ {totalCosts}</div>
        <div className={styles.info}>
          <FormattedMessage id="form_total_budget_facebook_example" values={{ br: <br /> }} />
        </div>
      </FormGroup>
    )}
    <FormGroup
      className={styles.costs}
      title={<FormattedMessage id="form_facebook_preview" />}
      subtitle={
        <FormattedMessage
          id="form_facebook_preview_description"
          values={{
            br: <br />,
            link: (
              <a
                href="https://www.facebook.com/business/help/397103717129942?id=1913105122334058"
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="form_facebook_preview_learn_more" />
              </a>
            ),
          }}
        />
      }
    >
      <img className={styles.image} src={FacebookPreview} alt="Facebook ad preview" />
    </FormGroup>
  </FormContent>
);
