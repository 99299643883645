import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { TextArea } from 'components/TextArea';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { Placement } from 'components/Placement';
import { RetailProductItem } from 'components/RetailProductItem';
import styles from './retail_product_add_form.module.scss';

export const RetailProductFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  handleAdd,
  loading,
  nextDisabled,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  itemUrlText,
  handleItemsUrlChange,
  initialItems,
  handleInitialItemsChange,
  items,
  handleUpdateItem,
  handleRemoveItem,
  invalidItems,
  allPlacements,
  handleSelectPlacement,
  isSearch,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          <FormattedMessage id="btn_next" />
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    {isEdit && !!initialItems.length && (
      <Fragment>
      <FormGroup style={{ marginBottom: '0px' }}  title={<FormattedMessage id="form_products" />} />
        <div>
          {initialItems.map(item => (
            <RetailProductItem
              key={item.id}
              product={item}
              itemId={item.itemId}
              name={item.name}
              url={item.url}
              status={!item.disabled}
              onUpdate={handleInitialItemsChange}
              isSearch={isSearch}
              isEdit
            />
          ))}
        </div>
        </Fragment>
    )}
    <FormGroup
      title={<FormattedMessage id="form_product_ad_placement" />}
      subtitle={<FormattedMessage id="form_product_ad_placement_description" />}
    >
      <Placement items={allPlacements} handleSelect={handleSelectPlacement} noSearch />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_product_urls" />}
      subtitle={<FormattedMessage id="form_product_urls_description" />}
      tooltip={<FormattedMessage id="form_product_urls_tooltip" />}
    >
      <TextArea
        value={itemUrlText}
        onChange={handleItemsUrlChange}
        label={<FormattedMessage id="form_product_urls_placeholder" />}
        error={invalidItems.length}
      />
      {!!invalidItems.length && (
        <div className={styles.error}>
          <div>
            <FormattedMessage id="form_product_urls_bad_url" />
          </div>
          {invalidItems && invalidItems.map(({ url }, idx) => <div key={`${idx}${url}`}>{url}</div>)}
        </div>
      )}
      <div className={styles.addContainer}>
        <Button
          type="primary"
          size="large"
          icon={<IconCreate />}
          disabled={!itemUrlText || !shopId}
          loading={loading}
          onClick={handleAdd}
        >
          <FormattedMessage id="btn_add" />
        </Button>
      </div>
    </FormGroup>
    {!!items.length && (
      <div>
        {items.map(item => (
          <RetailProductItem
            key={item.itemId}
            product={item}
            itemId={item.itemId}
            name={item.name}
            url={item.url}
            status={!item.disabled}
            onRemove={handleRemoveItem}
            onUpdate={handleUpdateItem}
            isSearch={isSearch}
          />
        ))}
      </div>
    )}
  </FormContent>
);
