import React, { Fragment } from 'react';
import { classList } from 'utils/classList';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconDown } from 'assets/icons/IconDown.svg';
import { Button } from 'components/Button';
import { FormGroup } from 'components/FormGroup';
import { TextInput, MoneyInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { CampaignDuration } from 'components/CampaignDuration';
import { pricingModelOptions, limitOptions, limitTypeOptions, priorityTypeOptions } from '../../shared/constants';
import styles from './banner_budget_form.module.scss';

export const BannerBudgetFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  priorityType,
  minDate,
  startDate,
  endDate,
  minEndDate,
  pricingModel,
  pricingModelPrice,
  totalBudget,
  limit,
  limitAmount,
  limitType,
  advancedSettingsEnabled,
  handlePriorityTypeChange,
  handleStartDateChange,
  handleEndDateChange,
  handlePricingModelChange,
  handlePricingModelPriceChange,
  handleTotalBudgetChange,
  handleLimitChange,
  handleLimitAmountChange,
  handleLimitTypeChange,
  handleEnableAdvancedSettings,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create_campaign" />}
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_priority" />}
      subtitle={<FormattedMessage id="form_campaign_priority_description" />}
      // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <SelectInput
        className={styles.select}
        label={<FormattedMessage id="form_campaign_priority" />}
        value={priorityType}
        options={priorityTypeOptions}
        onChange={handlePriorityTypeChange}
      />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_campaign_duration" />}
      subtitle={<FormattedMessage id="form_campaign_duration_description" />}
      tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <CampaignDuration
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        minEndDate={minEndDate}
        onStartChange={handleStartDateChange}
        onEndChange={handleEndDateChange}
        startDisabled={isEdit}
        endDateVisible
      />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_pricing" />}
      subtitle={<FormattedMessage id="form_pricing_description" />}
      // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <div className={styles.fieldsContainer}>
        <SelectInput
          className={styles.select}
          label={<FormattedMessage id="form_pricing_description" />}
          value={pricingModel}
          options={pricingModelOptions}
          onChange={handlePricingModelChange}
        />
        <MoneyInput
          addonBefore={'€'}
          label={<FormattedMessage id="form_pricing_placeholder" />}
          value={pricingModelPrice}
          onChange={handlePricingModelPriceChange}
        />
      </div>
    </FormGroup>
    <a className={styles.advancedSettingsToggle} onClick={handleEnableAdvancedSettings}>
      <FormattedMessage id="form_advanced_settings" />
      <IconDown className={classList(styles.icon, advancedSettingsEnabled && styles.expanded)} />
    </a>
    {advancedSettingsEnabled && (
      <Fragment>
        <FormGroup
          title={
            <span style={{ letterSpacing: '-0.9px' }}>
              <FormattedMessage id="form_total_budget_optional" />
            </span>
          }
          subtitle={<FormattedMessage id="form_total_budget_description" />}
          tooltip={<FormattedMessage id="form_total_budget_tooltip" />}
        >
          <MoneyInput
            addonBefore={'€'}
            label={<FormattedMessage id="form_pricing_placeholder" />}
            value={totalBudget}
            onChange={handleTotalBudgetChange}
          />
        </FormGroup>
        <FormGroup
          title={<FormattedMessage id="form_delivery_settings" />}
          subtitle={<FormattedMessage id="form_delivery_settings_description" />}
          // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
        >
          <div className={styles.fieldsContainer}>
            <SelectInput
              // allowClear
              label={<FormattedMessage id="form_delivery_settings_type" />}
              options={limitTypeOptions}
              value={limitType}
              onChange={handleLimitTypeChange}
            />
            {limitType && limitType !== 'none' && (
              <Fragment>
                <SelectInput
                  label={<FormattedMessage id="form_delivery_settings_limit" />}
                  options={limitOptions}
                  value={limit}
                  onChange={handleLimitChange}
                />
                <TextInput
                  type="number"
                  label={<FormattedMessage id="form_delivery_settings_amount" />}
                  value={limitAmount}
                  onChange={handleLimitAmountChange}
                />
              </Fragment>
            )}
          </div>
        </FormGroup>
      </Fragment>
    )}
  </FormContent>
);
