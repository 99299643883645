import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectInput } from 'components/SelectInput';

const typeOptions = [
  {
    name: <FormattedMessage id="label_all" />,
    value: 'all',
  },
  {
    name: <FormattedMessage id="campaign_type_HP" />,
    value: 'HP',
  },
  {
    name: <FormattedMessage id="campaign_type_C" />,
    value: 'C',
  },
  {
    name: <FormattedMessage id="campaign_type_FB" />,
    value: 'FB',
  },
  {
    name: <FormattedMessage id="campaign_type_B" />,
    value: 'B',
  },
];

const notinoTypeOptions = [
  {
    name: <FormattedMessage id="label_all" />,
    value: 'all',
  },
  {
    name: <FormattedMessage id="campaign_type_SPA" />,
    value: 'SPA',
  },
];

export const TypeSelect = ({ value, onChange, isNotino }) => (
  <SelectInput
    value={value}
    onChange={onChange}
    label={<FormattedMessage id="type_label" defaultMessage="Campaign type" />}
    options={isNotino? notinoTypeOptions : typeOptions}
  />
);
