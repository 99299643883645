import React from 'react';
import { DrawerView } from './views';

export const Drawer = ({ id, expanded, onExpand, ...props }) => {
  const handleExpand = () => {
    onExpand(expanded ? null : id);
  };

  return <DrawerView expanded={expanded} handleExpand={handleExpand} {...props} />;
};
