import { createSlice } from '@reduxjs/toolkit';
import { languages, demoLanguages } from 'lang';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    language: 'en',
    languages: languages,
    theme: 'martailer',

    has_bid: true,
    has_budget: true,
    has_totals: false,

    has_product_campaigns: true,
    has_category_campaigns: false,
    has_facebook_ads_campaigns: false,

    has_vendor_product_campaigns: false, //product flow for vendor
    has_retail_product_campaigns: false, //product flow for retailer
    has_banner_campaigns: false, // banner flow for retailer
    has_retail_banner_campaigns: false, // banner flow for retailere
    has_vendor_banner_campaigns: false, // banner flow for vendor
    has_demo_product_campaigns: false, // product flow for vendor
    has_demo_category_campaigns: false, // category flow for vendor

    has_item_search: true,
    enabled_all_product_selection_shops: ['pigult', '220lv', 'kaup24ee'],
    piguSellerId: 0,
    brands: [],
  },
  reducers: {
    changeLanguageAction: (state, action) => {
      state.language = action.payload;
    },
    setAccountAction: (state, action) => {
      const account = action.payload;
      if (!account) return;

      state.piguSellerId = account.piguSellerId;
      state.has_banner_campaigns = false;
      state.has_retail_product_campaigns = false;
      //todo  get settings here
      state.brands = (account?.brands || []).map((item, idx) => ({ id: idx+1, title: item}));

      if (account.shopIds.includes('publicgr')) {
        state.languages = languages.filter(l => l.value === 'en');
        state.language = 'en';

        state.has_category_campaigns = false;
      }
      if (account.shopIds.includes('senukailt') || account.shopIds.includes('eurovaistinelt')) {
        state.languages = languages;

        state.has_banner_campaigns = false;
        state.has_retail_product_campaigns = true;
        state.has_demo_product_campaigns = false;
        state.has_demo_category_campaigns = false;
        state.has_product_campaigns = false;
        state.has_bid = false;
        state.has_budget = false;

        if (account.shopIds.includes('senukailt')) {
          state.has_banner_campaigns = true;
        }
      } else if (account.shopIds.includes('radiopopularpt')) {
        state.has_product_campaigns = false;
        if ([1011].includes(account.accountId)) {
          // retail
          state.has_retail_product_campaigns = true;
          state.has_retail_banner_campaigns = true;
          state.has_vendor_product_campaigns = false;
          state.has_vendor_banner_campaigns = false;
        }

        if ([1023].includes(account.accountId)) {
          // vendor
          state.has_vendor_product_campaigns = true;
          state.has_vendor_banner_campaigns = true;
          state.has_retail_product_campaigns = false;
          state.has_retail_banner_campaigns = false;
        }
      } else if (account.shopIds.includes('your_shop_domain1.com')) {
        state.languages = languages.filter(l => l.value === 'en');
        state.language = 'en';
      } else {
        // TODO: if (account.shopIds.includes("pigult") || account.shopIds.includes("pigult-test")) {
        state.languages = languages;

        state.has_category_campaigns = true;
        state.has_facebook_ads_campaigns = true;
        state.has_totals = true;
      }

      if ([24, 194].includes(account.accountId)) {
        //state.has_banner_campaigns = true;
        //state.has_retail_product_campaigns = true;
        //state.has_demo_product_campaigns = true;
        //state.has_demo_category_campaigns = true;
        state.has_facebook_ads_campaigns = true;
      }

      // demo@martailer.com
      if (account.accountId === 420) {
        state.languages = demoLanguages;
        state.has_banner_campaigns = true;
        state.has_retail_product_campaigns = true;
        state.has_demo_product_campaigns = false;
        state.has_demo_category_campaigns = false;
        state.has_facebook_ads_campaigns = true;
        state.has_product_campaigns = false;
        state.has_bid = false;
        state.has_budget = false;
        state.has_totals = true;
      }

      // demo.vendor1@martailer.com
      // demo.vendor2@martailer.com
      if ([431, 432].includes(account.accountId)) {
        state.languages = demoLanguages;
        state.has_banner_campaigns = true;
        state.has_retail_product_campaigns = false;
        state.has_demo_product_campaigns = true;
        state.has_demo_category_campaigns = false;
        state.has_facebook_ads_campaigns = true;
        state.has_product_campaigns = false;
        state.has_bid = false;
        state.has_budget = false;

        state.has_item_search = true;
      }

      // 1254: notino sandbox
      // 1338: notino production
      if ([1254, 1338].includes(account.accountId) || account.subaccountId === 1338) {
        state.languages = languages.filter(l => l.value === 'en');
        state.has_banner_campaigns = false;
        state.has_retail_product_campaigns = false;
        state.has_demo_product_campaigns = false;
        state.has_demo_category_campaigns = false;
        state.has_facebook_ads_campaigns = false;
        state.has_product_campaigns = false;
        state.has_category_campaigns = false;
        state.has_bid = true;
        state.has_budget = true;

        state.has_item_search = true;

        state.has_banner_campaigns_notino = [1254, 1338].includes(account.accountId);
        state.has_facebook_ads_campaigns_notino = false;
        state.has_product_campaigns_notino = true;
        state.has_sponsored_brands_campaigns_notino = [1254, 1338].includes(account.accountId);
      }
    },
  },
});

export const { changeLanguageAction, setAccountAction } = settingsSlice.actions;

export const languageSelector = state => state.settings.language;
export const settingsSelector = state => state.settings;
export const themeSelector = state => state.settings.theme;

export default settingsSlice.reducer;
