import React from 'react';
import { Container } from '../Container';
import styles from './auth.module.scss';

import { ReactComponent as Icon } from 'assets/icons/IconLogo.svg';
import { ReactComponent as IconPiguBig } from 'assets/icons/IconLogoPoweredBig.svg';

export const AuthContainer = ({ title, subtitle, children }) => {
  return (
    <Container className={styles.auth}>
      <div className={styles.wrap}>
        {window.location.hostname === 'pigu.martailer.com' ? <IconPiguBig /> : <Icon />}
        <div className={styles.form}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          {children}
        </div>
      </div>
    </Container>
  );
};
