import React from 'react';
import { classList } from 'utils/classList';
import styles from './Spinner.module.scss';
import { Spin } from 'antd';

export const Spinner = ({ fullScreen, ...props }) => (
  <div className={classList(fullScreen && styles.fullScreen)}>
    <Spin {...props} />
  </div>
);
