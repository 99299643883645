import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Icon } from 'assets/icons/IconCheckmark.svg';
import styles from './Success.module.scss';

export const Success = ({ campaignName, isEdit, isProduct, isCategory, isBanner, isFacebook }) => (
  <div className={styles.successContainer}>
    <div className={styles.iconContainer}>
      <Icon />
    </div>
    <div className={styles.successTitle}>
      {isEdit ? (
        <FormattedMessage
          id="form_success_edited_title"
          values={{
            title: <span className={styles.campaignTitle}>{campaignName}</span>,
          }}
        />
      ) : (
        <Fragment>
          {isProduct && (
            <FormattedMessage
              id="form_success_product_title"
              values={{
                title: <span className={styles.campaignTitle}>{campaignName}</span>,
              }}
            />
          )}
          {isCategory && (
            <FormattedMessage
              id="form_success_category_title"
              values={{
                title: <span className={styles.campaignTitle}>{campaignName}</span>,
              }}
            />
          )}
          {isBanner && (
            <FormattedMessage
              id="form_success_banner_title"
              values={{
                title: <span className={styles.campaignTitle}>{campaignName}</span>,
              }}
            />
          )}
          {isFacebook && (
            <FormattedMessage
              id="form_success_facebook_title"
              values={{
                title: <span className={styles.campaignTitle}>{campaignName}</span>,
              }}
            />
          )}
        </Fragment>
      )}
    </div>
    {!isEdit && window.location.hostname !== 'app.martailer.com' && (
      <Fragment>
        {isProduct && (
          <div className={styles.successSubtitle}>
            <FormattedMessage id="form_success_product_created" />
          </div>
        )}
        {isCategory && (
          <div className={styles.successSubtitle}>
            <FormattedMessage id="form_success_category_created" />
          </div>
        )}
        {isBanner && (
          <div className={styles.successSubtitle}>
            <FormattedMessage id="form_success_banner_created" />
          </div>
        )}
        {isFacebook && (
          <div className={styles.successSubtitle}>
            <FormattedMessage id="form_success_facebook_created" values={{ br: <br /> }} />
          </div>
        )}
      </Fragment>
    )}
  </div>
);
