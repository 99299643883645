import React from 'react';
import { RetailProductAddForm } from 'forms/retail_product/retail_product_add_form';
import { RetailProductAddTextForm } from 'forms/retail_product/retail_product_add_text_form';

export const RetailProductAddView = ({
  step,
  steps,
  campaign,
  userAccount,
  shopOptions,
  invalidItems,
  searchItems,
  shopPreselected,
  loading,
  handleBack,
  handleNext,
  handleAdd,
  handleChange,
  handleSearch,
  settings
}) => (
  settings.has_item_search ? <RetailProductAddForm
    {...{
      step,
      steps,
      campaign,
      userAccount,
      shopOptions,
      searchItems,
      invalidItems,
      shopPreselected,
      loading,
      handleBack,
      handleAdd,
      handleNext,
      handleChange,
      handleSearch,
    }}
  /> : <RetailProductAddTextForm
    {...{
      step,
      steps,
      campaign,
      userAccount,
      shopOptions,
      invalidItems,
      loading,
      handleBack,
      handleNext,
      handleAdd,
      handleChange,
    }}
  />
);
