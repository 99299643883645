import { FormattedMessage } from 'react-intl';

export const pricingModelOptions = [
  {
    name: <FormattedMessage id="form_pricing_model_none" />,
    value: 'none',
  },
  {
    name: <FormattedMessage id="form_pricing_model_cpm" />,
    value: 'cpm',
  },
  {
    name: <FormattedMessage id="form_pricing_model_cpc" />,
    value: 'cpc',
  },
  {
    name: <FormattedMessage id="form_pricing_model_fixed" />,
    value: 'fixed',
  },
];

export const limitOptions = [
  {
    name: <FormattedMessage id="form_delivery_settings_daily" />,
    value: 'daily',
  },
  {
    name: <FormattedMessage id="form_delivery_settings_lifetime" />,
    value: 'lifetime',
  },
];

export const limitTypeOptions = [
  {
    name: <FormattedMessage id="form_delivery_settings_none" />,
    value: 'none',
  },
  {
    name: <FormattedMessage id="form_delivery_settings_impressions" />,
    value: 'impressions',
  },
  {
    name: <FormattedMessage id="form_delivery_settings_clicks" />,
    value: 'clicks',
  },
  {
    name: <FormattedMessage id="form_delivery_settings_cost" />,
    value: 'cost',
  },
];

export const priorityTypeOptions = [
  {
    name: <FormattedMessage id="form_campaign_priority_internal" />,
    value: 'internal',
  },
  {
    name: <FormattedMessage id="form_campaign_priority_contract" />,
    value: 'contract',
  },
  {
    name: <FormattedMessage id="form_campaign_priority_override" />,
    value: 'override',
  },
];

export const priorityLevelOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
  name: item,
  value: item,
}));
