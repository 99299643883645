import React from 'react';
import { FacebookAdsForm } from 'forms/facebook_ads';

export const FacebookAdsView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <FacebookAdsForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
    isEdit
  />
);
