import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { settingsSelector, changeLanguageAction } from 'store/settings';
import { logOut } from 'pages/login/redux';
import { accountSelector } from 'pages/dashboard/redux';
import { HeaderView } from './views';

export const Header = () => {
  const userAccount = useSelector(accountSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector(settingsSelector);

  const [isPopupsShown, showPopups] = useState({
    languages: false,
    help: false,
    menu: false,
  });

  const showLanguagesPopupHandler = () => {
    showPopups({
      languages: !isPopupsShown.languages,
      help: false,
      menu: false,
    });
  };

  const showHelpPopupHandler = () => {
    showPopups({
      languages: false,
      help: !isPopupsShown.help,
      menu: false,
    });
  };

  const showMenuPopupHandler = () => {
    showPopups({
      languages: false,
      help: false,
      menu: !isPopupsShown.menu,
    });
  };

  const selectLanguageHandler = value => {
    dispatch(changeLanguageAction(value));
    showLanguagesPopupHandler();
  };

  const logOutHandler = () => {
    dispatch(logOut());
    history.push('/martailer-login');
  };

  // Hack: only user demo@martailer.com can see analytics button.
  const isDemoAccount = userAccount?.id === 420;

  return (
    <HeaderView
      {...{
        showLanguagesPopupHandler,
        selectLanguageHandler,
        showHelpPopupHandler,
        userAccount,
        showMenuPopupHandler,
        logOutHandler,
        isPopupsShown,
        settings,
        isDemoAccount,
      }}
    />
  );
};
