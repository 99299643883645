import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
// import { ReactComponent as IconInfo } from 'assets/icons/IconInfo.svg';
import { bytesToSize } from 'utils/formatter';
import { Button } from 'components/Button';
// import { Tooltip } from 'components/Tooltip';
import { TextInput } from 'components/TextInput';
import styles from './BannerPreview.module.scss';

const getExtension = value => value.split('.').pop();

export const BannerPreviewView = ({ name, url, landingUrl, dimensions, size, onClose }) => (
  <div className={styles.container}>
    <div className={styles.infoColumn}>
      <div>
        <div className={styles.title}>{name}</div>
        <div className={styles.fileInfo}>
          <div className={styles.item}>
            <div>
              <FormattedMessage id="form_banner_image_format" />
            </div>
            <div className={styles.uppercase}>{getExtension(name)}</div>
          </div>
          <div className={styles.item}>
            <div>
              <FormattedMessage id="form_banner_image_size" />
            </div>
            <div>{`${dimensions.width}x${dimensions.height}`}</div>
          </div>
          <div className={styles.item}>
            <div>
              <FormattedMessage id="form_banner_file_size" />
            </div>
            <div>{bytesToSize(size)}</div>
          </div>
        </div>
      </div>
      <div className={styles.bannerUrl}>
        <div className={styles.title}>
          <FormattedMessage id="form_banner_url" />
          {/* <Tooltip title={tooltip}> */}
          {/* <IconInfo className={styles.icon} /> */}
          {/* </Tooltip> */}
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage id="form_banner_url_description" />
        </div>
        <TextInput
          className={styles.input}
          value={landingUrl}
          label={<FormattedMessage id="form_add_banners_placeholder" />}
          readOnly
          disabled
        />
        <div className={styles.info}>
          <FormattedMessage id="form_banner_url_legal" />
        </div>
      </div>
    </div>

    <div className={styles.previewColumn}>
      <div className={styles.corners}>
        <div className={styles.imageContainer}>
          <div className={styles.corner1} />
          <div className={styles.corner2} />
          <div className={styles.corner3} />
          <div className={styles.corner4} />
          <img src={url} alt="banner" />
        </div>
      </div>
      <Button type="ghost" iconOnly className={styles.close} icon={<IconRemove />} onClick={onClose} />
    </div>
  </div>
);
