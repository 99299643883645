import React, { useEffect, useState } from 'react';
import { isNotEmpty, isNotEmptyArray } from 'utils/validator';
import { SponsoredBrandFormView } from './views';

const mapPlacements = (all, selected = []) => {
  return all.map(brand => ({
    ...brand,
    selected: !!selected.find(item => item === brand.title),
  }));
};

export const SponsoredBrandForm = ({
  step,
  steps,
  campaign,
  shopOptions,
  brandOptions,
  loading,
  handleBack,
  handleNext,
  handleChange,
  isEdit,
}) => {
  const { name, shopId, brands = [] } = campaign;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [allBrands, setAllBrands] = useState(mapPlacements(brandOptions, brands));

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = async value => {
    handleChange({ key: 'shopId', value });
  };

  const handleSelectBrand = (id, selected) => {
    const newBrands = allBrands.map(item => {
      if (item.id === id) {
        return { ...item, selected };
      }
      return item;
    });
    setAllBrands(newBrands);
    
    handleChange({ key: 'brands', value: newBrands.filter(i => i.selected).map(i => i.title) });
  };

  const handleSelectAll = (selected) => {
    const newBrands = allBrands.map(item => ({
      ...item,
      selected: selected,
    }));
    setAllBrands(newBrands);
    handleChange({ key: 'brands', value: newBrands.filter(i => i.selected).map(i => i.title) });
  }
 
  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name)
      : !isNotEmpty(name) || !shopId || !isNotEmptyArray(brands);
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, brands]);

  return (
    <SponsoredBrandFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        allBrands,
        handleSelectBrand,
        handleSelectAll,
        isEdit,
      }}
    />
  );
};
