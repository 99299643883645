import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNotEmpty } from 'utils/validator';
import { loginSelector, setLoginAction, setLoginErrorAction, postLogin } from './redux';
import { LoginView } from './views';

export const Login = () => {
  const dispatch = useDispatch();
  const { email, password, error } = useSelector(loginSelector);

  const handleEmailChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'email', value: value }));
  };

  const handlePasswordChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'password', value: value }));
  };

  const handleValidate = e => {
    e.preventDefault();
    const emailIsValid = true; // email could also be an id
    if (!emailIsValid) {
      dispatch(setLoginErrorAction({ key: 'email', value: true }));
    }

    const passwordIsValid = isNotEmpty(password);
    if (!passwordIsValid) {
      dispatch(setLoginErrorAction({ key: 'password', value: true }));
    }

    emailIsValid && passwordIsValid && handleSubmit();
  };

  const handleSubmit = () => {
    let formData = new FormData();

    formData.append('email', email);
    formData.append('password', password);
    dispatch(postLogin(formData));
  };

  const handleEmailFocus = () => {
    dispatch(setLoginErrorAction({ key: 'email', value: false }));
    dispatch(setLoginErrorAction({ key: 'login', value: false }));
  };

  const handlePasswordFocus = () => {
    dispatch(setLoginErrorAction({ key: 'password', value: false }));
    dispatch(setLoginErrorAction({ key: 'login', value: false }));
  };

  return (
    <LoginView
      {...{
        email,
        password,
        error,
        handleEmailChange,
        handlePasswordChange,
        handleValidate,
        handleEmailFocus,
        handlePasswordFocus,
      }}
    />
  );
};
