import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BannerZoneFormView } from './views';

const sizePresetOptions = [
  { id: '1', name: 'Preset 1 (1050x150)', value: '1', dimensions: { width: 1050, height: 150 } },
  { id: '2', name: 'Preset 2 (565x972)', value: '2', dimensions: { width: 565, height: 972 } },
  { id: '3', name: 'Preset 3 (400x100)', value: '3', dimensions: { width: 400, height: 100 } },
];

const findPreset = value =>
  sizePresetOptions.find(item => item.dimensions.width === value.width && item.dimensions.height === value.height)?.id;

export const BannerZoneForm = ({ zone, handleChange, isEdit, onSubmit }) => {
  const history = useHistory();
  const { name, description, type, dimensions = {}, comments } = zone || {};
  const [nextDisabled, setNextDisabled] = useState(false);
  const potentialPreset = findPreset(dimensions);
  const [selectedSizePreset, setSelectedSizePreset] = useState(potentialPreset);
  const [sizeOverrideEnabled, setSizeOverrideEnabled] = useState(!potentialPreset);
  const [overrideSize, setOverrideSize] = useState({});

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleDescriptionChange = ({ target: { value } }) => {
    handleChange({ key: 'description', value });
  };

  const handleTypeChange = value => {
    handleChange({ key: 'type', value });
  };

  const handleSizePresetChange = value => {
    const { width, height } = sizePresetOptions.find(item => item.id === value)?.dimensions;
    setSelectedSizePreset(value);
    setOverrideSize({ width, height });
    handleChange({ key: 'dimensions', value: { width, height } });
  };

  const handleSizeOverrideChange = (key, { target: { value } }) => {
    const dimensions = {
      ...overrideSize,
      [key]: value,
    };
    setOverrideSize(dimensions);
    handleChange({ key: 'dimensions', value: dimensions });
  };

  const handleCommentsChange = ({ target: { value } }) => {
    handleChange({ key: 'comments', value });
  };

  const handleBack = () => {
    history.push('/zones');
  };

  const handleNext = () => {
    onSubmit();
  };

  const handleSizeOverrideEnable = () => {
    setSizeOverrideEnabled(!sizeOverrideEnabled);
  };

  useEffect(() => {
    setNextDisabled(!name || !description || !type || !dimensions?.width || !dimensions?.height);
  }, [name, description, type, dimensions]);

  return (
    <BannerZoneFormView
      {...{
        name,
        description,
        type,
        dimensions,
        comments,
        selectedSizePreset,
        sizeOverrideEnabled,
        sizePresetOptions,
        nextDisabled,
        isEdit,
        handleNameChange,
        handleDescriptionChange,
        handleTypeChange,
        handleSizePresetChange,
        handleSizeOverrideChange,
        handleCommentsChange,
        handleSizeOverrideEnable,
        handleNext,
        handleBack,
      }}
    />
  );
};
