import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import loginReducer from '../pages/login/redux';
import newCampaignReducer from '../pages/new_campaign/redux';
import editCampaignReducer from '../pages/edit_campaign/redux';
import passwordResetReducer from '../pages/password_reset/redux';
import dashboardReducer from '../pages/dashboard/redux';
import changePasswordReducer from '../pages/change_password/redux';
import bannerZonesReducer from '../pages/banner_zones/redux';
import settingsReducer from './settings';

const settingsPersistConfig = {
  key: 'settings',
  storage,
  whitelist: ['language'],
};

const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['pageSize', 'type', 'status'],
};

const rootReducer = combineReducers({
  login: loginReducer,
  newCampaign: newCampaignReducer,
  editCampaign: editCampaignReducer,
  passwordReset: passwordResetReducer,
  changePassword: changePasswordReducer,
  bannerZones: bannerZonesReducer,
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
});

export default configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
});
