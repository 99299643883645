import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { Toggle } from 'components/Toggle';
import { ProductPicker } from 'components/ProductPicker';
import { RetailProductItem } from 'components/RetailProductItem';
import { ProductCollectionSelector } from 'components/ProductCollectionSelector';
import styles from './product_add_form.module.scss';

export const ProductFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  itemUrlText,
  handleItemsUrlChange,
  initialItems,
  handleInitialItemsChange,
  products,
  invalidItems,
  handleSelectedItemsChange,
  handleRemoveItem,
  handleRemoveAllItems,
  handleSearch,
  handleAdd,
  handleUpdateItem,
  items,
  isEdit,
  sellerId = '',
  handleSellerChange,
  sellerOptions = [],
  sellerPreselected,
  allProductSelected,
  setAllProductSelected,
  productCount,
  enabled_all_product_selection_shops,
  piguSellerId,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          <FormattedMessage id="btn_next" />
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    {enabled_all_product_selection_shops.includes(shopId) &&
    !isEdit &&
    !sellerPreselected &&
    sellerOptions.length &&
    !piguSellerId ? (
      <FormGroup
        title={<FormattedMessage id="form_seller" />}
        subtitle={<FormattedMessage id="form_seller_description" />}
      >
        <SelectInput
          value={sellerId}
          onChange={handleSellerChange}
          label={<FormattedMessage id="form_seller_description" />}
          options={sellerOptions}
          searchable
        />
      </FormGroup>
    ) : null}
    {isEdit && !!initialItems.length && (
      <FormGroup title={<FormattedMessage id="form_products" />}>
        <div>
          {initialItems.map(item => (
            <div key={item.id} className={styles.item}>
              <Toggle checked={!item.disabled} onChange={value => handleInitialItemsChange(item.id, value)} />
              <div className={styles.itemTitle}>{item.name}</div>
            </div>
          ))}
        </div>
      </FormGroup>
    )}
    <FormGroup
      title={<FormattedMessage id="form_products" />}
      subtitle={<FormattedMessage id="form_product_urls_description" />}
      tooltip={<FormattedMessage id="form_product_urls_tooltip" />}
    ></FormGroup>
    {enabled_all_product_selection_shops.includes(shopId) ? (
      <ProductCollectionSelector
        allProductSelected={allProductSelected}
        setAllProductSelected={setAllProductSelected}
        productCount={productCount}
      />
    ) : null}
    {!allProductSelected && (
      <div className={styles.searchContainer}>
        <ProductPicker
          items={products}
          invalidItems={invalidItems}
          onQueryChange={handleSearch}
          handleSelect={handleSelectedItemsChange}
          handleRemoveAll={handleRemoveAllItems}
          itemUrlText={itemUrlText}
          handleAdd={handleAdd}
          handleItemsUrlChange={handleItemsUrlChange}
          searchPlaceholder={<FormattedMessage id="form_products_search_placeholder" />}
          searchDisabled={!shopId}
        />
      </div>
    )}
    {!allProductSelected && !!items.length && (
      <div>
        {items.map(item => (
          <RetailProductItem
            key={item.itemId}
            product={item}
            itemId={item.itemId}
            name={item.name}
            url={item.url}
            status={!item.disabled}
            onRemove={handleRemoveItem}
            onUpdate={handleUpdateItem}
          />
        ))}
      </div>
    )}
  </FormContent>
);
