import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from 'components/Header';
import { NewCampaignRouter } from './router';
import { setInitialStateAction } from './redux';

const NewCampaign = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setInitialStateAction());
  }, []);

  return (
    <Fragment>
      <Header />
      <NewCampaignRouter />
    </Fragment>
  );
};

export default NewCampaign;
