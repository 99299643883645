import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { editCategoryPageSteps } from 'components/ProgressBar';
import { itemUrlLookup, itemCategoryInfo, editCategoryCampaign } from '../async';
import {
  setStepAction,
  setStepsAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
  initialItemsSelector,
  setItemsAction,
} from '../redux';
import { DemoCategoryView } from './views';

export const DemoCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const initialItems = useSelector(initialItemsSelector);
  const [invalidItems, setInvalidItems] = useState(false);

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(editCategoryCampaign());
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(2));
    history.push(`/edit-campaign/${campaign.campaignId}/success`);
    dispatch(setLoadingAction(false));
  };

  const handleAdd = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemUrlLookup({ shopId: campaign.shopId, itemUrlText: campaign.itemUrlText }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    if (payload.some(item => !item.itemId)) {
      setInvalidItems(payload.filter(item => !item.itemId));
    } else {
      const { payload: categoryPayload } = await dispatch(
        itemCategoryInfo({ itemId: payload[0].itemId, shopId: campaign.shopId })
      );
      if (!categoryPayload) {
        dispatch(setLoadingAction(false));
        return;
      }
      const [item] = categoryPayload;
      const { categoryItems = [] } = campaign;
      const items = uniqBy([...categoryItems, { ...item, ...payload[0], maxCPC: item.minBid, status: true }], 'itemId');
      dispatch(setCampaignAction({ key: 'categoryItems', value: items }));
      setInvalidItems([]);
      dispatch(setCampaignAction({ key: 'itemUrlText', value: '' }));
    }
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  const handleItemsChange = items => {
    dispatch(setItemsAction(items));
  };

  useEffect(() => {
    dispatch(setStepsAction(editCategoryPageSteps));
    dispatch(setStepAction(1));
  }, []);

  return (
    <DemoCategoryView
      {...{
        step,
        steps,
        campaign,
        initialItems,
        invalidItems,
        loading,
        handleBack,
        handleNext,
        handleChange,
        handleItemsChange,
        handleAdd,
      }}
    />
  );
};
