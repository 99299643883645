import React, { Fragment } from 'react';
import { Header } from 'components/Header';
import { Container } from '../../components/Container'
import { Chart } from '../../components/Chart'
import { Row } from '../../components/Row'
import { Col } from '../../components/Col'
import styles from './analytics.module.scss'
import { FormattedMessage } from 'react-intl'

const fakeThumbnails = [
  { title: 'Active vendors', amount: '120' },
  { title: 'Active campaigns', amount: '308' },
  { title: 'Active products', amount: '3696' },
  { title: 'Avg. CPC', amount: '0.72 €' },
  { title: 'Vendor spend', amount: '810 740 €' },
]

const fakeGraphData = [
  { date: '2021-09-01', value: 14304 },
  { date: '2021-09-02', value: 18025 },
  { date: '2021-09-03', value: 17528 },
  { date: '2021-09-04', value: 17356 },
  { date: '2021-09-05', value: 21819 },
  { date: '2021-09-06', value: 25565 },
  { date: '2021-09-07', value: 24857 },
  { date: '2021-09-08', value: 15117 },
  { date: '2021-09-09', value: 19868 },
  { date: '2021-09-10', value: 25499 },
  { date: '2021-09-11', value: 26521 },
  { date: '2021-09-12', value: 20652 },
  { date: '2021-09-13', value: 16260 },
  { date: '2021-09-14', value: 16146 },
  { date: '2021-09-15', value: 30161 },
  { date: '2021-09-16', value: 20963 },
  { date: '2021-09-17', value: 29955 },
  { date: '2021-09-18', value: 32014 },
  { date: '2021-09-19', value: 25465 },
  { date: '2021-09-20', value: 30745 },
  { date: '2021-09-21', value: 32682 },
  { date: '2021-09-22', value: 32152 },
  { date: '2021-09-23', value: 26991 },
  { date: '2021-09-24', value: 34257 },
  { date: '2021-09-25', value: 37645 },
  { date: '2021-09-26', value: 39886 },
  { date: '2021-09-27', value: 40229 },
  { date: '2021-09-28', value: 37899 },
  { date: '2021-09-29', value: 39484 },
  { date: '2021-09-30', value: 40695 },
]

const fakeTopVendorsTable = [
  {vendor: 'Samsung', campaigns: '32', budget: '121 600 €'},
  {vendor: 'Dyson', campaigns: '6', budget: '21 000 €'},
]

const fakeTopCategoriesTable = [
  {category: 'Electronics -> TVs & video', budget: '33 200 €'},
  {category: 'Household appliances -> Vacuums & floor care', budget: '21 000 €'},
]

export const AnalyticsView = () => (
  <Fragment>
    <Header/>
    <Container>
      <Row>
        <Col>
          <h1>
            <FormattedMessage id="analytics" defaultMessage="Analytics" />
          </h1>
          <Chart data={fakeGraphData} label="Spend" valueY={'value'} yTickFormatter={(tick) => `${tick} €`}/>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row className={styles.headerSection}>
        {fakeThumbnails.map((t, i ) => (
          <Col key={i}>
            <div className={styles.metricItem}>
              <h1>{t.amount}</h1>
              <h2>{t.title}</h2>
            </div>
          </Col>)
        )}
      </Row>
    </Container>
    <Container>
      <Row className={styles.headerSection}>
        <Col>
          <h2>
            <FormattedMessage id="retailer_top_vendors_by_spend" defaultMessage="Top vendors by spend" />
          </h2>
          <table className={styles.analyticsTable}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">
                  <FormattedMessage id="retailer_vendor" defaultMessage="Vendor" />
                </th>
                <th className="ant-table-cell">
                  <FormattedMessage id="retailer_campaigns" defaultMessage="Campaigns" />
                </th>
                <th className={`ant-table-cell ${styles.lastTh}`}>
                  <FormattedMessage id="retailer_budget" defaultMessage="Budget" />
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
            {fakeTopVendorsTable.map((v, i) => (
              <tr key={i} className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">{v.vendor}</td>
                <td className="ant-table-cell">{v.campaigns}</td>
                <td className="ant-table-cell table-cell-right">{v.budget}</td>
              </tr>)
            )}
            </tbody>
          </table>
        </Col>
        <Col>
          <h2>
            <FormattedMessage id="retailer_top_categories" defaultMessage="Top categories" />
          </h2>
          <table className={styles.analyticsTable}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">
                  <FormattedMessage id="retailer_category" defaultMessage="Category" />
                </th>
                <th className={`ant-table-cell ${styles.lastTh}`}>
                  <FormattedMessage id="retailer_budget" defaultMessage="Budget" />
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {fakeTopCategoriesTable.map((v, i) => (
              <tr key={i} className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">{v.category}</td>
                <td className="ant-table-cell table-cell-right">{v.budget}</td>
              </tr>)
            )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  </Fragment>
)
