import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header } from 'components/Header';
import { Container } from 'components/Container';
import { Drawer } from 'components/Drawer';
import HomeAndProductPages from 'assets/images/HomeAndProductPages.jpg';
import CategoryPage from 'assets/images/CategoryPage.jpg';
import DatePickerImage from 'assets/images/DatePicker.png';
import CampaignTypes from 'assets/images/CampaignTypes.png';
import styles from './faq.module.scss';

const introItems = [
  {
    id: 1,
    title: <FormattedMessage id="faq_category_intro_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_intro_answer_1"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 2,
    title: <FormattedMessage id="faq_category_intro_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_intro_answer_2"
          values={{
            br: <br />,
            image: <img className={styles.image} src={HomeAndProductPages} alt="Home and product pages" />,
            image2: <img className={styles.image} src={CategoryPage} alt="Category page listings" />,
          }}
        />
      </div>
    ),
  },
  {
    id: 3,
    title: <FormattedMessage id="faq_category_intro_question_3" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_intro_answer_3"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 4,
    title: <FormattedMessage id="faq_category_intro_question_4" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_intro_answer_4"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 5,
    title: <FormattedMessage id="faq_category_intro_question_5" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_intro_answer_5"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
            link: (
              <a rel="noreferrer" target="_blank" href="https://pigu.martailer.com/martailer-login">
                https://pigu.martailer.com/martailer-login
              </a>
            ),
          }}
        />
      </div>
    ),
  },
];

const typeItems = [
  {
    id: 6,
    title: <FormattedMessage id="faq_category_types_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_types_answer_1"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
            image: <img className={styles.imageSmall} src={CampaignTypes} alt="Campaign types" />,
          }}
        />
      </div>
    ),
  },
];

const managingItems = [
  {
    id: 7,
    title: <FormattedMessage id="faq_category_managing_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_1"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 8,
    title: <FormattedMessage id="faq_category_managing_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_2"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 9,
    title: <FormattedMessage id="faq_category_managing_question_3" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_3"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 10,
    title: <FormattedMessage id="faq_category_managing_question_4" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_4"
          values={{
            b: chunks => <b>{chunks}</b>,
          }}
        />
      </div>
    ),
  },
  {
    id: 11,
    title: <FormattedMessage id="faq_category_managing_question_5" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_5"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 12,
    title: <FormattedMessage id="faq_category_managing_question_6" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_managing_answer_6"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
];

const costsItems = [
  {
    id: 13,
    title: <FormattedMessage id="faq_category_costs_question_1" />,
    content: (
      <div>
        <FormattedMessage id="faq_category_costs_answer_1" />
      </div>
    ),
  },
  {
    id: 14,
    title: <FormattedMessage id="faq_category_costs_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_costs_answer_2"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 15,
    title: <FormattedMessage id="faq_category_costs_question_3" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_costs_answer_3"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 16,
    title: <FormattedMessage id="faq_category_costs_question_4" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_costs_answer_4"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
];

const billingItems = [
  {
    id: 17,
    title: <FormattedMessage id="faq_category_billing_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_billing_answer_1"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 18,
    title: <FormattedMessage id="faq_category_billing_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_billing_answer_2"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
            image: <img className={styles.imageSmall} src={DatePickerImage} alt="Date picker" />,
          }}
        />
      </div>
    ),
  },
];

const performanceItems = [
  {
    id: 19,
    title: <FormattedMessage id="faq_category_performance_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_performance_answer_1"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 20,
    title: <FormattedMessage id="faq_category_performance_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_performance_answer_2"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
];

const troubleshootItems = [
  {
    id: 21,
    title: <FormattedMessage id="faq_category_troubleshot_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_troubleshot_answer_1"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
  {
    id: 22,
    title: <FormattedMessage id="faq_category_troubleshot_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_troubleshot_answer_2"
          values={{
            br: <br />,
          }}
        />
      </div>
    ),
  },
];

const registrationItems = [
  {
    id: 23,
    title: <FormattedMessage id="faq_category_registration_question_1" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_registration_answer_1"
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />,
            link: (
              <a rel="noreferrer" target="_blank" href="https://pigu.martailer.com/martailer-login">
                https://pigu.martailer.com/martailer-login
              </a>
            ),
          }}
        />
      </div>
    ),
  },
  {
    id: 24,
    title: <FormattedMessage id="faq_category_registration_question_2" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_registration_answer_2"
          values={{
            br: <br />,
            link: (
              <a rel="noreferrer" target="_blank" href="https://pigu.martailer.com/martailer-login">
                https://pigu.martailer.com/martailer-login
              </a>
            ),
          }}
        />
      </div>
    ),
  },
  {
    id: 25,
    title: <FormattedMessage id="faq_category_registration_question_3" />,
    content: (
      <div>
        <FormattedMessage
          id="faq_category_registration_answer_3"
          values={{
            br: <br />,
            link: (
              <a rel="noreferrer" target="_blank" href="https://martailer.com/pigu-en/">
                https://martailer.com/pigu-en/
              </a>
            ),
          }}
        />
      </div>
    ),
  },
];

const Categories = [
  {
    title: <FormattedMessage id="faq_category_intro_title" />,
    items: introItems,
  },
  {
    title: <FormattedMessage id="faq_category_types_title" />,
    items: typeItems,
  },
  {
    title: <FormattedMessage id="faq_category_managing_title" />,
    items: managingItems,
  },
  {
    title: <FormattedMessage id="faq_category_costs_title" />,
    items: costsItems,
  },
  {
    title: <FormattedMessage id="faq_category_billing_title" />,
    items: billingItems,
  },
  {
    title: <FormattedMessage id="faq_category_performance_title" />,
    items: performanceItems,
  },
  {
    title: <FormattedMessage id="faq_category_troubleshot_title" />,
    items: troubleshootItems,
  },
  {
    title: <FormattedMessage id="faq_category_registration_title" />,
    items: registrationItems,
  },
];

const Category = ({ title, items, expanded, handleExpanded }) => (
  <div className={styles.category}>
    <div className={styles.title}>{title}</div>
    {items.map(({ id, title, content }) => (
      <Drawer key={id} expanded={expanded === id} id={id} title={title} content={content} onExpand={handleExpanded} />
    ))}
  </div>
);

export const FaqView = ({ expanded, handleExpanded }) => (
  <Fragment>
    <Header />
    <Container>
      <div className={styles.faqContainer}>
        <div className={styles.title}>
          <FormattedMessage id="menu_item_faq" />
        </div>
        {Categories.map(({ title, items }, idx) => (
          <Category key={idx} title={title} items={items} expanded={expanded} handleExpanded={handleExpanded} />
        ))}
      </div>
    </Container>
  </Fragment>
);
