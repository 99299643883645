import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setInitialStateAction, setBannerZoneAction, bannerZoneSelector, setBannerZoneCreateAction } from '../redux';
import { CreateBannerZoneView } from './views';

export const CreateBannerZone = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const zone = useSelector(bannerZoneSelector);

  const handleSubmit = () => {
    dispatch(setBannerZoneCreateAction());
    history.push('/zones');
  };

  const handleChange = ({ key, value }) => {
    dispatch(setBannerZoneAction({ key, value }));
  };

  useEffect(() => {
    return () => dispatch(setInitialStateAction());
  }, []);

  return (
    <CreateBannerZoneView
      {...{
        zone,
        handleChange,
        handleSubmit,
      }}
    />
  );
};


