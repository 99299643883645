import React, { useState } from 'react';
import './styles.scss';

export const FloatLabel = ({ children, label, value, error }) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  const className = error ? labelClass + ' error' : labelClass;

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <label className={className}>{label}</label>
    </div>
  );
};
