import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
import { DatePickerSingle } from 'components/DatePicker';
import { Button } from 'components/Button';
import styles from './CampaignDuration.module.scss';

export const CampaignDurationView = ({
  intl,
  startDateMoment,
  endDateMoment,
  endDateEnabled,
  minDate,
  minEndDate,
  handleStartDateChange,
  handleEndDateChange,
  handleEndDateEnable,
  startDisabled,
  endDateVisible,
}) => (
  <div className={styles.campaignDuration}>
    <DatePickerSingle
      className={styles.start}
      placeholder={intl.formatMessage({ id: 'form_campaign_start_placeholder' })}
      value={startDateMoment}
      minDate={minDate}
      onChange={handleStartDateChange}
      disabled={startDisabled}
    />
    {(endDateEnabled || endDateVisible) && (
      <DatePickerSingle
        className={classList(styles.end, endDateVisible && styles.noMargin)}
        placeholder={intl.formatMessage({ id: 'form_campaign_end_placeholder' })}
        minDate={minEndDate || startDateMoment}
        value={endDateMoment}
        onChange={handleEndDateChange}
      />
    )}
    {!endDateVisible && (
      <Fragment>
        {endDateEnabled ? (
          <Button
            className={styles.cancelAction}
            type="primary"
            size="large"
            icon={<IconRemove />}
            onClick={() => handleEndDateEnable(false)}
          >
            <FormattedMessage id="form_campaign_placeholder_dont_specify" />
          </Button>
        ) : (
          <Button
            className={styles.action}
            type="primary"
            size="large"
            icon={<IconCreate />}
            onClick={() => handleEndDateEnable(true)}
          >
            <FormattedMessage id="form_campaign_end_placeholder_optional" />
          </Button>
        )}
      </Fragment>
    )}
  </div>
);
