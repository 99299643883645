import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { Placement } from 'components/Placement';
import { ProgressBar } from 'components/ProgressBar';

export const SponsoredBrandFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  allBrands,
  handleSelectBrand,
  handleSelectAll,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          <FormattedMessage id="btn_next" />
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    <FormGroup
      title={<FormattedMessage id="form_sponsored_brands" />}
      subtitle={<FormattedMessage id="form_sponsored_brands_descripion" />}
    />
      <Placement items={allBrands} handleSelect={handleSelectBrand} handleSelectAll={handleSelectAll} searchPlaceholder={<FormattedMessage id="form_sponsored_brands_search" />} />
  </FormContent>
);
