import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Dialog } from 'components/Dialog';
import { ReactComponent as DetailIcon } from 'assets/icons/IconDetail.svg';
import styles from './SupportedSizes.module.scss';

export const radioPopularSizesConfig = [
  {
    title: 'Homepage',
    items: [
      { title: 'Homepage desktop', size: '1400x230' },
      { title: 'Homepage mobile', size: '400x100' },
    ],
  },
  {
    title: 'Search results page',
    items: [
      { title: 'Search desktop and mobile', size: '1050x150' },
      { title: 'Search listings destop and mobile', size: '565x972' },
    ],
  },
  {
    title: 'Category page',
    items: [
      { title: 'Category desktop and mobile', size: '1050x150' },
      { title: 'Category listings desktop and mobile', size: '565x972' },
      { title: 'Category filter desktop', size: '350x250' },
    ],
  },
  {
    title: 'Product page',
    items: [
      { title: 'Product desktop', size: '1150x200' },
      { title: 'Product mobile', size: '400x100' },
    ],
  },
];

const defaultSizesConfig = [
  {
    title: 'Square & rectangle',
    items: [
      { title: 'Small square', size: '200x200' },
      { title: 'Vertical rectangle', size: '240x400' },
      { title: 'Square', size: '250x250' },
      { title: 'Triple widescreen', size: '250x360' },
      { title: 'Inline rectangle', size: '300x250' },
    ],
  },
  {
    title: 'Leaderboard',
    items: [
      { title: 'Banner', size: '468x60' },
      { title: 'Leaderboard', size: '728x90' },
      { title: 'Top banner', size: '930x180' },
      { title: 'Large leaderboard', size: '970x90' },
      { title: 'Billboard', size: '970x250' },
    ],
  },
];

export const SupportedSizesDialogView = ({ open, onClose, sizesConfig = defaultSizesConfig }) => (
  <Dialog title={<FormattedMessage id="form_supported_sizes" />} open={open} onClose={onClose}>
    <div className={styles.sizesDialogContainer}>
      <div className={styles.title}>File types</div>
      <div className={styles.type}>
        <div className={styles.itemRow}>
          <div className={styles.itemCol}>Image formats</div>
          <div className={styles.itemCol}>JPG, PNG, GIF</div>
        </div>
        <div className={styles.itemRow}>
          <div className={styles.itemCol}>Max. size</div>
          <div className={styles.itemCol}>150 KB</div>
        </div>
      </div>
      <div className={styles.title}>Ad sizes</div>
      <div className={styles.dimensions}>
        {sizesConfig.map(({ title, items }) => (
          <div  key={title}className={styles.itemCol}>
            <div className={styles.subtitle}>{title}</div>
            {items.map(item => (
              <div key={item.title} className={styles.item}>
                <div>{item.title}</div>
                <div>{item.size}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  </Dialog>
);

const SupportedSizesDialog = props =>
  createPortal(<SupportedSizesDialogView {...props} />, document.getElementById('root'));

export const SupportedSizesView = ({ dialogOpen, handleDialogOpen, sizesConfig }) => (
  <Fragment>
    <div className={styles.supportedSizes}>
      <div className={styles.title} onClick={() => handleDialogOpen(true)}>
        <DetailIcon className={styles.icon} />
        <FormattedMessage id="form_supported_sizes" />
      </div>
      <div className={styles.description}>
        <FormattedMessage id="form_supported_sizes_description" />
      </div>
    </div>
    <SupportedSizesDialog
      title={<FormattedMessage id="form_supported_sizes" />}
      open={dialogOpen}
      sizesConfig={sizesConfig}
      onClose={() => handleDialogOpen(false)}
    />
  </Fragment>
);
