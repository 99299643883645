import api from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const constructEditProductCampaignPayload = (
  piguSellerId,
  accountId,
  { id, name, shopId, sellerId, startDate, endDate, totalBudget, dailyBudget, maxCPC, items = [], campaignType },
  { adsetId, raw },
  initialItems = []
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      ...((piguSellerId || sellerId) && { seller: piguSellerId ? piguSellerId : sellerId }),
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      campaignType: campaignType,
    },
  ],
  adsets: [raw],
  aditems: [...initialItems, ...items].map(({ itemId, name, url, imageUrl, status, disabled }) => ({
    itemId,
    name,
    url,
    imageUrl,
    adsetId: adsetId,
    status,
    disabled,
  })),
});

const constructEditCategoryCampaignPayload = (
  accountId,
  { id, name, shopId, startDate, endDate, categoryItems = [], campaignType },
  { adsetId, raw },
  initialItems = []
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      campaignType: campaignType,
    },
  ],
  adsets: [raw],
  aditems: [...initialItems, ...categoryItems].map(({ itemId, name, url, maxCPC, status, disabled }) => ({
    itemId,
    name,
    url,
    adsetId: adsetId,
    maxCPC: Number(maxCPC),
    status,
    disabled,
  })),
});

const constructEditBannerCampaignPayload = (
  accountId,
  {
    id,
    name,
    shopId,
    startDate,
    endDate,
    landingUrl,
    pricingModel,
    pricingModelPrice,
    dailyBudget,
    totalBudget,
    maxCPC,
    limit,
    limitAmount,
    limitType,
    priorityType,
    campaignType,
    banners = [],
  },
  { adsetId, raw },
  initialItems = []
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      banner: true,
      landingUrl: landingUrl,
      pricingModel: pricingModel,
      pricingModelPrice: pricingModelPrice,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      limit: limit,
      limitAmount: limitAmount,
      limitType: limitType,
      priorityType: priorityType,
      campaignType: campaignType,
    },
  ],
  adsets: [raw],
  aditems: [...initialItems, ...banners].map(
    ({ name, size, url, bannerUrl, landingUrl, dimensions, categories = [], deliveryRules, keywords }) => ({
      itemId: 'b' + (url || bannerUrl).replace(/[^0-9]/g, ''),
      name,
      size,
      bannerUrl: url || bannerUrl,
      landingUrl: landingUrl,
      dimensions: dimensions,
      categories: categories.filter(item => item.id).map(item => item.id),
      deliveryRules: deliveryRules,
      keywords,
      adsetId: adsetId,
    })
  ),
});

const constructEditRetailProductCampaignPayload = (
  accountId,
  {
    id,
    name,
    shopId,
    startDate,
    endDate,
    totalBudget,
    pricingModel,
    pricingModelPrice,
    limit,
    limitAmount,
    limitType,
    priorityType,
    priorityLevel,
    placements,
    items = [],
    campaignType,
  },
  { adsetId, raw },
  initialItems = []
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      pricingModel: pricingModel,
      pricingModelPrice: pricingModelPrice,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      limit: limit,
      limitAmount: limitAmount,
      limitType: limitType,
      priorityType: priorityType,
      priorityLevel: priorityLevel,
      placements: placements,
      campaignType: campaignType,
    },
  ],
  adsets: [raw],
  aditems: [...initialItems, ...items].map(({ itemId, name, url, imageUrl, status, disabled, keywords }) => ({
    itemId,
    name,
    url,
    imageUrl,
    adsetId: adsetId,
    status,
    disabled,
    keywords,
  })),
});

const constructEditFacebookAdsCampaignPayload = (
  accountId,
  { id, name, shopId, dailyBudget, duration, campaignType },
  { raw }
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      accountId: accountId,
      name: name,
      dailyBudget: Number(dailyBudget),
      duration: duration,
      campaignType: campaignType,
    },
  ],
  adsets: [raw],
});

const constructEditSponsoredBrandsCampaignPayload = (
  accountId,
  { id, name, shopId, startDate, endDate, totalBudget, dailyBudget, maxCPC, brands = [], campaignType },
  { raw }
) => ({
  campaigns: [
    {
      id: id,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      campaignType: campaignType,
      brands: brands,
    },
  ],
  adsets: [raw],
});

export const itemUrlLookup = createAsyncThunk('edit_campaign/itemUrlLookup', async ({ shopId, itemUrlText }) => {
  const lookup_data = itemUrlText.trim().split(/\s+/);
  const { data } = await api.post('/api/item_lookup', {
    shopId,
    data: lookup_data,
  });
  return data;
});

export const itemSearch = createAsyncThunk('edit_campaign/itemSearch', async ({ shopId, sellerId, query, limit }) => {
  const { data } = await api.post('/api/item_search', {
    shopId,
    ...(sellerId && { sellerId }),
    query,
    limit,
  });
  return data;
});

export const itemCategoryInfo = createAsyncThunk('edit_campaign/itemCategoryInfo', async ({ itemId, shopId }) => {
  const itemIds = [itemId];
  const { data } = await api.post('/api/category_info', {
    shopId,
    itemIds,
  });
  return data;
});

export const editProductCampaign = createAsyncThunk(
  'edit_campaign/editProductCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset, initialItems } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const piguSellerId = getState().settings.piguSellerId;
    const payload = constructEditProductCampaignPayload(piguSellerId, accountId, campaign, adset, initialItems);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const editCategoryCampaign = createAsyncThunk(
  'edit_campaign/editCategoryCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset, initialItems } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructEditCategoryCampaignPayload(accountId, campaign, adset, initialItems);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const editBannerCampaign = createAsyncThunk(
  'edit_campaign/editBannerCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset, initialItems } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructEditBannerCampaignPayload(accountId, campaign, adset, initialItems);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const editRetailProductCampaign = createAsyncThunk(
  'edit_campaign/editRetailProductCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset, initialItems } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;

    const payload = constructEditRetailProductCampaignPayload(accountId, campaign, adset, initialItems);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const editFacebookAdsCampaign = createAsyncThunk(
  'edit_campaign/editFacebookAdsCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;

    const payload = constructEditFacebookAdsCampaignPayload(accountId, campaign, adset);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const editSponsoredBrandsCampaign = createAsyncThunk(
  'edit_campaign/editSponsoredBrandsCampaign',
  async (placeholder, { getState }) => {
    const { campaign, adset } = getState().editCampaign;
    const { accountId } = getState().dashboard.userAccount;

    const payload = constructEditSponsoredBrandsCampaignPayload(accountId, campaign, adset);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const uploadBanner = createAsyncThunk('new_campaign/uploadBanner', async file => {
  let formData = new FormData();
  formData.append('file', file);
  const { data } = await api.post('/banner/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { ...data, name: file.name, size: file.size };
});
