import React from 'react';
import ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { getTableColumns } from './columns';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import { Provider, useSelector } from 'react-redux';

import store from 'store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';

import { languageSelector } from 'store/settings';
import { shopMap } from 'pages/new_campaign/shared';
import { dateRangeSelector } from 'pages/dashboard/redux'
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { ReactComponent as IconDownload } from 'assets/icons/IconDownload.svg';

import { each } from 'lodash';

import messages_en from 'lang/en.json';
import messages_lt from 'lang/lt.json';
import messages_lv from 'lang/lv.json';
import messages_ee from 'lang/ee.json';
import messages_fr from 'lang/fr.json';
import messages_pl from 'lang/pl.json';
import messages_fi from 'lang/fi.json';

import moment from 'moment';
import 'moment/locale/et';
import 'moment/locale/lv';
import 'moment/locale/lt';
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/fi';


moment.locale('en');

const messages = {
  en: messages_en,
  lt: messages_lt,
  lv: messages_lv,
  ee: messages_ee,
  fr: messages_fr,
  pl: messages_pl,
  fi: messages_fi,
};

const renderData = (
  tableData,
  columnConfig,
  selectedLanguage
) => {
  const columns = getTableColumns(tableData, {...columnConfig, readOnly: true});
  
  if (columnConfig.type == "campaign" || columnConfig.type == "product") columns.splice(2, 0, {
    title: <FormattedMessage id="form_shop" />,
    width: 160,
    ellipsis: true,
    dataIndex: 'shopId',
    children: [
      {
        dataIndex: 'name',
        render: (text, record) => {
          const { shopId } = record;
          return shopMap[shopId] || shopId;
        },
      },
    ],
    sorter: (a, b) => a.name.localeCompare(b.name),
  });
  
  const render = el => {
    const container = document.createElement("div");
    const wrapped = (
      <Provider store={store}>
        <IntlProvider messages={messages[selectedLanguage]} locale={selectedLanguage} defaultLocale="en">
          <BrowserRouter>
            {el}
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
    ReactDOM.render(wrapped, container);
    return container;
  };
  
  const tab = render(<div>
      <ul key="header">
        { columns.map((c, i) => (<li key={i}>{ c.title }</li>)) }
      </ul>
      <ul key="total">
        { columns.map((c, i) => (<li key={i}>{ c.children[0].title ? c.children[0].title() : '' }</li>)) }
      </ul>
      { tableData.map((r, j) => (<ul key={j}>
          { columns.map((c, i) => (<li key={i}>{ c.children[0].render(r[c.dataIndex], r) }</li>)) }
        </ul>)) }
    </div>);
  
  const data = {columns: [], types: []};
  each(tab.children[0].children, (ul, j) => {
    each(ul.children, (li, i) => {
        if (j == 0) { // header
            var value = li.textContent;
            data.columns.push(value);
            data[value] = [];
            
            var tp = "numeric";
            if (columns[i].dataIndex == "name" || columns[i].dataIndex == "status" || columns[i].dataIndex == "campaignType" || columns[i].dataIndex == "shopId") tp = "str";
            else if (columns[i].dataIndex == "startDate" || columns[i].dataIndex == "endDate") tp = "str";
            data.types.push(tp);

        } else {

            if (columns[i].dataIndex == "name") { // ignore banner size text
                each(li.querySelectorAll(".typeSymbol"), s => s.remove()); // remove any typeSymbol from textContent
                
                var title = li.querySelector(".title"), dimensions = li.querySelector(".dimensions");
                if (title) {
                    var value = title.textContent.trim();
                    if (dimensions) value += " (" + dimensions.textContent.trim() + ")";
                }
                else var value = li.textContent.trim();
            }
            else {
                var value = li.textContent.trim();
                if (value[0] == "€") value = value.slice(1).trim()
                if (value[value.length-1] == "%") value = value.slice(0, -1).trim()
            }

            data[data.columns[i]].push(value);
        }
    });
  });
  
  return data;
}

export const DownloadXlsForm = ({
  columnConfig,
  tableData
}) => {
  const selectedLanguage = useSelector(languageSelector);
  const [startDate, endDate] = useSelector(dateRangeSelector);

  const handleDownloadReport = (e) => {
    const form = e.target.form;
    form["data"].value = JSON.stringify(renderData(tableData, columnConfig, selectedLanguage));
    form.submit();
  };

  return (<form name="download_data" method="POST" action={"/api/download/data "+startDate+" - "+endDate+".xlsx"}>
    <input type="hidden" name="data"/>
    <Button onClick={handleDownloadReport} icon={<IconDownload />} size={'large'}>
      <FormattedMessage id="btn_download_report" defaultMessage="Download report" />
    </Button>
  </form>);
};

