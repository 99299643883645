import { useState } from 'react';
import { Modal, Result, Typography } from 'antd';

export const NewAppNotificationModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => setIsOpen(false);

  return (
    <Modal width={650} visible={isOpen} onCancel={close} onOk={close}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Result status="warning" title="Advertising moved to the new domain" subTitle="New domain for Advertising" />
        <Typography.Text type="danger">
          This UI (user interface) is no longer in use. To make any changes to your advertising campaigns, please go to
          the new UI
        </Typography.Text>
        <Typography.Title
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <a href="https://phhgroup.martailer.com" target="_blank">
            https://phhgroup.martailer.com
          </a>
        </Typography.Title>
        <ul>
          <li>
            <Typography.Paragraph>
              The new UI (https://phhgroup.martailer.com). All campaigns must be managed (created, edited) on the new
              UI. Making changes on the old UI will do nothing.
            </Typography.Paragraph>
          </li>
          <li>
            <Typography.Paragraph>
              The old UI (https://pigu.martailer.com) will remain available to access historical data.
            </Typography.Paragraph>
          </li>
        </ul>
      </div>
    </Modal>
  );
};
