import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { FormGroup } from 'components/FormGroup';
import { TextInput, MoneyInput } from 'components/TextInput';
import { Toggle } from 'components/Toggle';
import { SelectInput } from 'components/SelectInput';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { CampaignDuration } from 'components/CampaignDuration';
import {
  pricingModelOptions,
  limitOptions,
  limitTypeOptions,
  priorityTypeOptions,
  priorityLevelOptions,
} from '../../shared/constants';
import styles from './retail_product_budget_form.module.scss';

export const RetailProductBudgetFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  priorityType,
  priorityLevel,
  minDate,
  minEndDate,
  startDate,
  endDate,
  pricingModel,
  pricingModelPrice,
  totalBudgetEnabled,
  totalBudget,
  handleTotalBudgetToggle,
  handleTotalBudgetChange,
  limit,
  limitAmount,
  limitType,
  allPlacements,
  handlePriorityTypeChange,
  handlePriorityLevelChange,
  handleStartDateChange,
  handleEndDateChange,
  handlePricingModelChange,
  handlePricingModelPriceChange,
  handleLimitChange,
  handleLimitAmountChange,
  handleLimitTypeChange,
  handleSelectPlacement,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create_campaign" />}
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_priority" />}
      subtitle={<FormattedMessage id="form_campaign_priority_description" />}
      // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <div className={styles.fieldsContainer}>
        <SelectInput
          className={styles.select}
          label={<FormattedMessage id="form_campaign_priority" />}
          value={priorityType}
          options={priorityTypeOptions}
          onChange={handlePriorityTypeChange}
        />
        {/*<SelectInput
          className={styles.select}
          label={<FormattedMessage id="form_campaign_priority_level" />}
          value={priorityLevel}
          options={priorityLevelOptions}
          onChange={handlePriorityLevelChange}
        />*/}
      </div>
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_campaign_duration" />}
      subtitle={<FormattedMessage id="form_campaign_duration_description" />}
      tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <CampaignDuration
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        minEndDate={minEndDate}
        onStartChange={handleStartDateChange}
        onEndChange={handleEndDateChange}
        startDisabled={isEdit}
        endDateVisible
      />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_pricing" />}
      subtitle={<FormattedMessage id="form_pricing_description" />}
      // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <div className={styles.fieldsContainer}>
        <SelectInput
          className={styles.select}
          label={<FormattedMessage id="form_pricing_description" />}
          value={pricingModel}
          options={pricingModelOptions}
          onChange={handlePricingModelChange}
        />
        <MoneyInput
          addonBefore={'€'}
          label={<FormattedMessage id="form_pricing_placeholder" />}
          value={pricingModelPrice}
          onChange={handlePricingModelPriceChange}
        />
      </div>
    </FormGroup>
    <FormGroup
      title={
        <span style={{ letterSpacing: '-0.9px' }}>
          <FormattedMessage id="form_total_budget_optional" />
        </span>
      }
      subtitle={<FormattedMessage id="form_total_budget_description" />}
      tooltip={<FormattedMessage id="form_total_budget_tooltip" />}
      style={{ alignItems: 'center' }}
    >
      <div className={styles.totalBudgetContainer}>
        {totalBudgetEnabled && (
          <div className={styles.totalBudgetInput}>
            <MoneyInput
              addonBefore={'€'}
              value={totalBudget}
              onChange={handleTotalBudgetChange}
              label={<FormattedMessage id="form_total_budget_placeholder" />}
            />
          </div>
        )}
        <Toggle checked={totalBudgetEnabled} onChange={handleTotalBudgetToggle} />
      </div>
    </FormGroup>
    {/*<FormGroup
      title={<FormattedMessage id="form_delivery_settings" />}
      subtitle={<FormattedMessage id="form_delivery_settings_description" />}
      // tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <div className={styles.fieldsContainer}>
        <SelectInput
          label={<FormattedMessage id="form_delivery_settings_type" />}
          options={limitTypeOptions}
          value={limitType}
          onChange={handleLimitTypeChange}
        />
        {limitType && limitType !== 'none' && (
          <Fragment>
            <SelectInput
              label={<FormattedMessage id="form_delivery_settings_limit" />}
              options={limitOptions}
              value={limit}
              onChange={handleLimitChange}
            />
            <TextInput
              type="number"
              label={<FormattedMessage id="form_delivery_settings_amount" />}
              value={limitAmount}
              onChange={handleLimitAmountChange}
            />
          </Fragment>
        )}
      </div>
    </FormGroup>*/}
  </FormContent>
);
