import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AdminDashboard } from './admin_dashboard';
import { UserDashboard } from './user_dashboard';
import { ProductDashboard } from './product_dashboard';

export const DashboardRouter = () => (
  <Switch>
    <Route exact path="/dashboard" component={AdminDashboard} />
    <Route exact path="/dashboard/:accountId" component={UserDashboard} />
    <Route exact path="/dashboard/:accountId/:campaignId" component={ProductDashboard} />
    <Redirect to="/dashboard" />
  </Switch>
);
