import React, { useState } from 'react';
import { SupportedSizesView } from './views';

export const SupportedSizes = ({ sizesConfig }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = value => {
    setDialogOpen(value);
  };

  return (
    <SupportedSizesView
      {...{
        dialogOpen,
        handleDialogOpen,
        sizesConfig,
      }}
    />
  );
};
