/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
import { ReactComponent as IconSearch } from 'assets/icons/IconSearch.svg';
import { ReactComponent as IconCheck } from 'assets/icons/IconCheckmark.svg';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { Checkbox } from 'components/Checkbox';
import styles from './Placement.module.scss';

const SelectableItem = ({ name = '', checked, disabled, onChange }) => (
  <div className={classList(styles.selectableItem, disabled && styles.disabled)}>
    <Checkbox className={styles.checkbox} checked={checked} onChange={!disabled && onChange} />
    <div className={styles.name}>{name}</div>
  </div>
);

const SelectedItem = ({ name = '', handleRemove }) => (
  <div className={styles.selectedItem}>
    <div className={styles.name}>{name}</div>
    <div className={styles.remove} onClick={handleRemove}>
      <IconRemove className={styles.icon} />
    </div>
  </div>
);

const CreateItem = ({ active, value, onChange, handleSave, handleActivate }) => (
  <div className={styles.createItem}>
    {active ? (
      <Fragment>
        <TextInput style={{ width: '100%' }} value={value} onChange={onChange} className={styles.input} small />
        <div className={styles.actions}>
          <div className={styles.save} onClick={handleSave}>
            <IconCheck className={styles.icon} />
          </div>
          <div className={styles.remove} onClick={() => handleActivate(false)}>
            <IconRemove className={styles.icon} />
          </div>
        </div>
      </Fragment>
    ) : (
      <div>
        <a onClick={() => handleActivate(true)}>
          <FormattedMessage id="form_product_keywords_add" />
        </a>
      </div>
    )}
  </div>
);

export const PlacementView = ({
  allItems,
  selectedItems,
  query,
  handleItemSelect,
  handleItemRemove,
  handleClearAll,
  handleQueryChange,
  noSearch,
  small,
  expanded,
  searchDisabled,
  handleExpand,
  searchPlaceholder,
  addNewActive,
  handleNewActivate,
  newValue,
  handleNewChange,
  handleSaveNew,
  withAdd,
  ...props
}) => (
  <div className={classList(styles.container, noSearch && styles.noSearch, small && styles.small)} {...props}>
    <div className={styles.allItems}>
      {!noSearch && (
        <div className={styles.header}>
          <TextInput
            value={query}
            onChange={handleQueryChange}
            label={searchPlaceholder || <FormattedMessage id="form_banner_placement_search" />}
            prefixIcon={<IconSearch />}
            disabled={searchDisabled}
          />
        </div>
      )}
      {expanded && (
        <div className={styles.body}>
          {allItems.map(({ id, title, selected, disabled }) => (
            <Fragment key={id}>
              {!!id && <SelectableItem name={title} checked={selected} onChange={e => handleItemSelect(id, e)} disabled={disabled} />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
    <div className={styles.selectedItems}>
      {!noSearch && (
        <div className={styles.header}>
          <div className={styles.title}>
            {!!selectedItems.length && (
              <FormattedMessage
                id="form_banner_placement_selected"
                values={{
                  number: selectedItems.length,
                }}
              />
            )}
          </div>
          <div className={styles.actions}>
            {expanded ? (
              <Button type="ghost" size="large" onClick={() => handleExpand(false)}>
                <FormattedMessage id="btn_collapse" />
              </Button>
            ) : (
              <Button type="ghost" size="large" onClick={() => handleExpand(true)}>
                <FormattedMessage id="btn_expand" />
              </Button>
            )}
            <Button type="ghost" size="large" onClick={handleClearAll}>
              <FormattedMessage id="form_banner_placement_clear_all" />
            </Button>
          </div>
        </div>
      )}
      {expanded && (
        <div className={styles.body}>
          {selectedItems.map(({ id, title }) => (
            <SelectedItem key={id} name={title} handleRemove={() => handleItemRemove(id)} />
          ))}
          {withAdd && (
            <CreateItem
              value={newValue}
              onChange={handleNewChange}
              active={addNewActive}
              handleSave={handleSaveNew}
              handleActivate={handleNewActivate}
            />
          )}
        </div>
      )}
    </div>
  </div>
);
