import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { accountSelector } from 'pages/dashboard/redux';
import { itemUrlLookup, itemCategoryInfo, createCategoryCampaign } from '../async';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../redux';
import { shopMap } from '../shared';
import { DemoCategoryView } from './views';

export const DemoCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const userAccount = useSelector(accountSelector);
  const shopOptions = userAccount ? userAccount.shopIds.map(id => ({ name: shopMap[id] || id, value: id })) : [];
  const [invalidItems, setInvalidItems] = useState(false);

  const handleBack = () => {
    history.push('/new-campaign');
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(createCategoryCampaign({ demo: true }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(3));
    history.push('/new-campaign/success');
    dispatch(setLoadingAction(false));
  };

  const handleAdd = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(itemUrlLookup({ shopId: campaign.shopId, itemUrlText: campaign.itemUrlText }));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    if (payload.some(item => !item.itemId)) {
      setInvalidItems(payload.filter(item => !item.itemId));
    } else {
      const { payload: categoryPayload } = await dispatch(
        itemCategoryInfo({ itemId: payload[0].itemId, shopId: campaign.shopId })
      );
      if (!categoryPayload) {
        dispatch(setLoadingAction(false));
        return;
      }
      const [item] = categoryPayload;
      const { categoryItems = [] } = campaign;
      const items = uniqBy([...categoryItems, { ...item, ...payload[0], maxCPC: item.minBid, status: true }], 'itemId');
      dispatch(setCampaignAction({ key: 'categoryItems', value: items }));
      setInvalidItems([]);
      dispatch(setCampaignAction({ key: 'itemUrlText', value: '' }));
    }
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(2));
  }, []);

  return (
    <DemoCategoryView
      {...{
        step,
        steps,
        campaign,
        shopOptions,
        invalidItems,
        loading,
        handleBack,
        handleNext,
        handleChange,
        handleAdd,
      }}
    />
  );
};
