import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { DatePicker as DatePickerAnt } from 'antd';
import enUS from 'antd/es/date-picker/locale/en_US';
import etEE from 'antd/es/date-picker/locale/et_EE';
import lvLV from 'antd/es/date-picker/locale/lv_LV';
import ltLT from 'antd/es/date-picker/locale/lt_LT';
import frFR from 'antd/es/date-picker/locale/fr_FR';

import { languageSelector } from 'store/settings';
import { classList } from 'utils/classList';
import { FloatLabel } from '../FloatLabel';
import './styles.scss';

const locales = {
  en: enUS,
  lt: ltLT,
  lv: lvLV,
  ee: etEE,
  fr: frFR,
};

const { RangePicker } = DatePickerAnt;

export const DatePickerRange = ({ value = [], onChange, maxDate }) => {
  const intl = useIntl();
  const selectedLanguage = useSelector(languageSelector);
  const [startDate, onChangeStartDate] = useState('');
  const [endDate, onChangeEndDate] = useState('');

  const onChangeRange = (momentObjs, dates) => {
    onChangeStartDate(dates[0]);
    onChangeEndDate(dates[1]);
    onChange && onChange([momentObjs[0], momentObjs[1]]);
  };

  function disabledDate(current) {
    if (maxDate) {
      return current > moment(maxDate).endOf('day');
    }
  }

  return (
    <div className={'range-picker-wrapper'}>
      <div className="range-label">
        <FormattedMessage id="date_range" defaultMessage="Date range: " />
      </div>
      <div className={'range-picker'}>
        <div className={'start-wrapper'}>
          <FloatLabel
            label={<FormattedMessage id="date_range_from" defaultMessage="From" />}
            name={value[0] || startDate}
            value={value[1] || startDate}
          >
            <span className={'start'} />
          </FloatLabel>
        </div>
        <RangePicker
          locale={locales[selectedLanguage]}
          format={'YYYY-MM-DD'}
          separator={''}
          onChange={onChangeRange}
          bordered={false}
          allowEmpty={[false, false]}
          placeholder={[intl.formatMessage({ id: 'date_range_from' }), intl.formatMessage({ id: 'date_range_to' })]}
          disabledDate={disabledDate}
          value={value || []}
          inputReadOnly={true}
        />
        <div className={'end-wrapper'}>
          <FloatLabel
            label={<FormattedMessage id="date_range_to" defaultMessage="To" />}
            name={value[1] || endDate}
            value={value[1] || endDate}
          >
            <span className={'end'} />
          </FloatLabel>
        </div>
      </div>
    </div>
  );
};

export const DatePickerSingle = ({ placeholder, value, minDate, className, onChange, small, disabled }) => {
  const selectedLanguage = useSelector(languageSelector);
  const onChangeDate = (momentObj, date) => {
    onChange && onChange(momentObj, date);
  };

  function disabledDate(current) {
    if (minDate) {
      return moment(minDate).isSameOrAfter(current);
    }
    return current < moment().startOf('day');
  }

  return (
    <div className={classList('single-date', small && 'small-input', className)}>
      <DatePickerAnt
        locale={locales[selectedLanguage]}
        className={'single-picker'}
        bordered={false}
        placeholder=""
        inputReadOnly={true}
        format={'YYYY-MM-DD'}
        onChange={onChangeDate}
        value={value}
        disabled={disabled}
        disabledDate={disabledDate}
      />
      {!small && (
        <div className={classList('label-wrapper', !value && 'empty')}>
          <FloatLabel label={placeholder} value={value}>
            <span className={'start'} />
          </FloatLabel>
        </div>
      )}
    </div>
  );
};
