import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { settingsSelector } from 'store/settings';
import {
  accountChartDataSelector,
  userCampaignsSelector,
  loadingSelector,
  changePageSizeAction,
  pageSizeSelector,
  statusSelector,
  changeStatusAction,
  typeSelector,
  changeTypeAction,
} from '../redux';
import { userSelector } from 'pages/login/redux';
import { updateCampaign, fetchDashboardDataCampaign } from '../async';
import { filterTables } from '../helpers';
import { UserDashboardView } from './views';

export const UserDashboard = () => {
  let { accountId } = useParams();
  const dispatch = useDispatch();

  const chartData = useSelector(state => accountChartDataSelector(state, accountId));
  const campaigns = useSelector(state => userCampaignsSelector(state, accountId));

  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const status = useSelector(statusSelector);
  const type = useSelector(typeSelector);
  const pageSize = useSelector(pageSizeSelector);
  const loading = useSelector(loadingSelector);
  const user = useSelector(userSelector);
  const settings = useSelector(settingsSelector);
  const isNotino = settings.has_product_campaigns_notino;

  const columnConfig = {
    type: 'campaign',
    readOnly: !(user.accountId == accountId || user.superuser),
    settings,
  };

  const [query, setQuery] = useState('');

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const handleStatusChange = value => {
    dispatch(changeStatusAction(value));
  };

  const handleTypeChange = value => {
    dispatch(changeTypeAction(value));
  };

  const handleUpdateCampaign = async campaign => {
    await dispatch(updateCampaign(campaign));
  };

  const handleUpdateStatus = async ({ record, status }) => {
    const campaign = {
      ...record.raw,
      disabled: !status,
    };
    if (status === 'deleted') {
      campaign.disabled = true;
      campaign.status = 'deleted';
    }
    handleUpdateCampaign(campaign);
  };

  const handleUpdateMaxCPC = async ({ record, maxCPC }) => {
    const campaign = {
      ...record.raw,
      maxCPC,
    };
    handleUpdateCampaign(campaign);
  };

  const handleUpdateDailyBudget = async ({ record, dailyBudget }) => {
    const campaign = {
      ...record.raw,
      dailyBudget,
    };
    handleUpdateCampaign(campaign);
  };

  const handleUpdateEndDate = async ({ record, endDate }) => {
    const campaign = {
      ...record.raw,
      endDate,
    };
    handleUpdateCampaign(campaign);
  };

  const handlePageSizeChange = value => {
    dispatch(changePageSizeAction(value));
  };

  useEffect(() => {
    const newCampaigns = filterTables(campaigns, query, type, status);
    setFilteredCampaigns(newCampaigns);
  }, [query, type, status, campaigns]);

  useEffect(() => {
    dispatch(fetchDashboardDataCampaign(user.accountId));
  }, []);

  return (
    <UserDashboardView
      {...{
        accountId,
        chartData,
        columnConfig,
        campaigns: filteredCampaigns,
        loading,
        query,
        status,
        type,
        pageSize,
        isNotino,
        handleQueryChange,
        handleStatusChange,
        handleTypeChange,
        handleUpdateStatus,
        handleUpdateMaxCPC,
        handleUpdateDailyBudget,
        handleUpdateEndDate,
        handlePageSizeChange,
      }}
    />
  );
};
