import React, { useState, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { MoneyInput } from 'components/TextInput';
import { Tooltip as AntTooltip } from 'components/Tooltip';
import { Toggle } from 'components/Toggle';
import { Button } from 'components/Button';
import { SelectInput } from 'components/SelectInput';
import { DatePickerSingle } from 'components/DatePicker';
import { Popup, PopupItem } from 'components/Popup';
import { BannerPreview as Preview } from 'components/BannerPreview';
import { ReactComponent as EditIcon } from 'assets/icons/IconEdit.svg';
import { ReactComponent as IconLink } from 'assets/icons/IconLink.svg';
import './styles.scss';
import styles from './Table.module.scss';

const campaignSymbols = {
  'demo-product': 'HP',
  'retail-product': 'HP',
  'vendor-product': 'HP',
  'product': 'HP',
  'sponsored-product': 'SPA',
  'demo-category': 'C',
  'cpm-category': 'C',
  'banner': 'B',
  'retail-banner': 'B',
  'vendor-banner': 'B',
  'facebook-ads': 'FB',
};

const BidOptions = [15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50].map(item => ({
  name: `€${item.toFixed(2)}`,
  value: item,
}));

const Tooltip = ({ children, ...props }) => (
  <AntTooltip {...props}>
    <span>{children}</span>
  </AntTooltip>
);

// eslint-disable-next-line react/style-prop-object
const formatMoney = value => <FormattedNumber value={value} style="currency" currency="EUR" />;

const Money = ({ value = 0, onChange, min, max, disabled }) => {
  const [error, setError] = useState(false);

  const onUpdate = value => {
    if (value) {
      if (min && value < min) {
        setError(true);
        return;
      }
      if (max && value > max) {
        setError(true);
        return;
      }
      onChange(value);
    }
    setError(!value);
  };

  return <MoneyInput small error={error} value={value} onChange={onUpdate} disabled={disabled} />;
};

const DatePicker = ({ startDate, endDate, onChange }) => {
  const [startDateMoment] = useState(startDate ? moment(startDate) : moment());
  const [endDateMoment, setEndDate] = useState(endDate ? moment(endDate) : null);

  const handleChange = (mmntObj, date) => {
    setEndDate(mmntObj);
    onChange(date);
  };

  return (
    <DatePickerSingle
      small
      placeholder={null}
      value={endDateMoment}
      minDate={startDateMoment}
      onChange={handleChange}
    />
  );
};

const ratio = (a, b) => {
  if (b === 0) return 0;
  // todo, if b==0, a>0, then return inf? or nan?
  else return a / b;
};

const percent = r => (r * 100).toFixed(2);

const statusColumn = {
  title: <FormattedMessage id="col_status" defaultMessage="Status" />,
  dataIndex: 'status',
  sorter: (a, b) => a.status.localeCompare(b.status),
  children: [
    {
      dataIndex: 'status',
      render: (text, record) => {
        if (text === 'deleted') {
          return (
            <p className="status closed">
              <span></span>
              <FormattedMessage id="status_deleted" />
            </p>
          );
        }
        if (record.disabled) {
          return (
            <p className="status pause">
              <span></span>
              <FormattedMessage id="status_paused" />
            </p>
          );
        }
        switch (text) {
          case 'under_review':
            return (
              <p className="status pause">
                <span></span>
                <FormattedMessage id="status_under_review" />
              </p>
            );
          case 'scheduled':
            return (
              <p className="status pause">
                <span></span>
                <FormattedMessage id="status_scheduled" />
              </p>
            );
          case 'duplicated':
            return (
              <p className="status pause">
                <span></span>
                <FormattedMessage id="status_duplicated" />
              </p>
            );
          case 'active':
            return (
              <p className="status active">
                <span></span>
                <FormattedMessage id="status_active" />
              </p>
            );
          case 'ended':
            return (
              <p className="status closed">
                <span></span>
                <FormattedMessage id="status_ended" />
              </p>
            );
          case 'low_bid':
            return (
              <p className="status closed">
                <span></span>
                <FormattedMessage id="status_low_bid" />
              </p>
            );
          case 'out_of_budget':
            return (
              <p className="status closed">
                <span></span>
                <FormattedMessage id="status_out_of_budget" />
              </p>
            );
          case 'inactive':
            return (
              <p className="status pause">
                <span></span>
                <FormattedMessage id="status_inactive" />
              </p>
            );
          case 'lost_buybox':
              return (
                <p className="status closed">
                  <span></span>
                  <FormattedMessage id="status_lost_buybox" />
                </p>
              );

          default:
            return text;
        }
      },
    },
  ],
};

// active, inactive, registered inactive, account created

const accountStatusColumn = {
  title: <FormattedMessage id="col_status" defaultMessage="Status" />,
  dataIndex: 'status',
  sorter: (a, b) => a.status.localeCompare(b.status),
  children: [
    {
      dataIndex: 'status',
      render: (text, record) => {
        const { status } = record;
        return status;
      },
    },
  ],
};

const ActionsPopup = props => createPortal(<Popup {...props} />, document.getElementById('root'));

const BannerPreview = props => createPortal(<Preview {...props} />, document.getElementById('root'));

export const getTableColumns = (
  tableData,
  columnConfig,
  onUpdateStatus,
  onUpdateMaxCPC,
  onUpdateDailyBudget,
  onUpdateEndDate
) => {
  const toggleColumn = {
    children: [
      {
        dataIndex: 'disabled',
        render: (bool, record) => {
          const onChange = checked => {
            onUpdateStatus({ record, status: checked });
          };

          if (record.campaignType === 'facebook-ads') return '';

          return <Toggle checked={!bool} onChange={onChange} />;
        },
      },
    ],
  };

  const accountNameColumn = {
    title: <FormattedMessage id="col_account" defaultMessage="Account" />,
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    children: [
      {
        dataIndex: 'name',
        render: (text, record) => {
          const { accountId, name } = record;

          return <Link to={`/dashboard/${accountId}`}>{name}</Link>;
        },
      },
    ],
  };

  const campaignNameColumn = {
    title: <FormattedMessage id="col_campaign" defaultMessage="Campaign" />,
    width: 160,
    ellipsis: true,
    dataIndex: 'name',
    children: [
      {
        dataIndex: 'name',
        render: (text, record) => {
          const { accountId, campaignId, name, campaignType } = record;

          return campaignType === 'facebook-ads' ? (
            <div className="name">{name}</div>
          ) : (
            <Link className="name" to={`/dashboard/${accountId}/${campaignId}`}>
              {name}
            </Link>
          );
        },
      },
    ],
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const BannerColumn = ({ bannersUrl, landingUrl, bannerUrl, name, dimensions = [], size }) => {
    const [showPreview, setShowPreview] = useState(false);

    const dimensionsObject = { width: dimensions[0], height: dimensions[1] };

    const handleShowPreview = value => {
      setShowPreview(value);
    };

    return (
      <Fragment>
        <div className="previewColumn">
          <a href={landingUrl || bannersUrl} className="link" target="_blank" rel="noreferrer">
            <IconLink />
          </a>
          <div className="previewContainer" onClick={() => handleShowPreview(true)}>
            <img className="bannerPreview" src={bannerUrl} alt="preview" />
          </div>
          <div className="titleContainer">
            <div className="title">{name}</div>
            <div className="dimensions">{`${dimensions[0]}x${dimensions[1]}`}</div>
          </div>
        </div>
        {showPreview && (
          <BannerPreview
            name={name}
            url={bannerUrl}
            bannersUrl={landingUrl || bannersUrl}
            dimensions={dimensionsObject}
            size={size}
            onClose={() => handleShowPreview(false)}
          />
        )}
      </Fragment>
    );
  };

  const productNameColumn = {
    title: <FormattedMessage id="col_item" defaultMessage="Product" />,
    width: 160,
    ellipsis: true,
    dataIndex: 'name',
    children: [
      {
        dataIndex: 'name',
        render: (text, record) => {
          const { url, name, campaignType, imageUrl, bannerUrl, bannersUrl, landingUrl, dimensions, size } = record;
          if (['banner', 'retail-banner', 'vendor-banner'].includes(campaignType)) {
            return <BannerColumn {...{ bannersUrl, landingUrl, bannerUrl, name, dimensions, size }} />;
          } else {
            return (
              <div className="previewColumn">
                <a href={url} className="link" target="_blank" rel="noreferrer">
                  <IconLink />
                </a>
                {imageUrl && (
                  <div className="previewContainer">
                    <img className="preview" src={imageUrl} alt="preview" />
                  </div>
                )}
                <div className="titleContainer">
                  <div className="title">{name}</div>
                </div>
              </div>
            );
          }
        },
      },
    ],
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const campaignTypeColumn = {
    title: columnConfig.settings.has_product_campaigns_notino ? (
      <FormattedMessage id="col_campaign_type" defaultMessage="Campaign type" />
    ) : (
      <Tooltip title={<FormattedMessage id="col_campaign_type_tooltip" defaultMessage="Campaign type tooltip" />}>
        <FormattedMessage id="col_campaign_type" defaultMessage="Campaign type" />
      </Tooltip>
    ),
    dataIndex: 'campaignType',
    children: [
      {
        dataIndex: 'campaignType',
        render: (text, record) => {
          const { campaignType, category } = record;
          const type = category ? 'cpm-category' : campaignType;
          const typeSymbol = campaignSymbols[type] || 'HP';
          return (
            <div className="typeSymbol">
              <Tooltip title={<FormattedMessage id={`campaign_type_${typeSymbol}_tooltip`} />}>
                <FormattedMessage id={`campaign_type_${typeSymbol}`} defaultMessage="Campaign type" />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const EndDate = ({ startDate, endDate, campaignType, onChange }) => {
    if (columnConfig.readOnly) return endDate || '-';
    if (campaignType === 'facebook-ads') return endDate || '-';
    return <DatePicker startDate={startDate} endDate={endDate} onChange={onChange} />;
  };

  const dateColumns = [
    {
      title: <FormattedMessage id="col_end_date" defaultMessage="End date" />,
      dataIndex: 'endDate',
      sorter: (a, b) => (a.endDate > b.endDate ? 1 : -1),
      children: [
        {
          dataIndex: 'endDate',
          render: (text, record, index) => {
            const { startDate, endDate, campaignType } = record;
            const onChange = value => {
              onUpdateEndDate({ record, endDate: value });
            };

            return <EndDate startDate={startDate} endDate={endDate} campaignType={campaignType} onChange={onChange} />;
          },
        },
      ],
    },
  ];

  const Bid = ({ category, pricingModelPrice, maxCPC, dailyBudget, campaignType, onChange }) => {
    if (columnConfig.readOnly || ['banner', 'retail-banner', 'vendor-banner'].includes(campaignType))
      return <Money value={maxCPC || pricingModelPrice} disabled />;
    if (category) return <Money disabled />;
    if (campaignType === 'facebook-ads') return <Money disabled />;
    if (campaignType === 'cpm-category') {
      if (columnConfig.type === 'product') {
        return (
          <Tooltip title={<FormattedMessage id="col_cpm" />}>
            <SelectInput value={maxCPC} onChange={onChange} options={BidOptions} small />
          </Tooltip>
        );
      } else return <Money disabled />;
    }
    let minBid = campaignType === 'vendor-product' ? 0.2 : 0.06;
    let maxBid = 2;
    if (columnConfig.settings.has_product_campaigns_notino) {
      minBid = 0.5;
      maxBid = 100;
    }
    return (
      <Tooltip title={<FormattedMessage id="col_cpc" />}>
        <Money min={minBid} max={dailyBudget < maxBid ? dailyBudget : maxBid} value={maxCPC} onChange={onChange} />
      </Tooltip>
    );
  };

  const bidColumn = {
    title: (
      <Tooltip title={<FormattedMessage id="col_bid_tooltip" defaultMessage="Bid tooltip" />}>
        <FormattedMessage id="col_bid" defaultMessage="Bid" />
      </Tooltip>
    ),
    dataIndex: 'maxCPC',
    sorter: (a, b) => (a.maxCPC > b.maxCPC ? 1 : -1),
    children: [
      {
        render: (text, record) => {
          const { maxCPC, dailyBudget, category, pricingModelPrice, campaignType } = record;
          const onChange = value => {
            onUpdateMaxCPC({ record, maxCPC: value });
          };

          return (
            <Bid
              category={category}
              pricingModelPrice={pricingModelPrice}
              dailyBudget={dailyBudget}
              maxCPC={maxCPC}
              campaignType={campaignType}
              onChange={onChange}
            />
          );
        },
      },
    ],
  };

  const DailyBudget = ({ maxCPC, dailyBudget, totalBudget, onChange }) => {
    return (
      <Tooltip title={<FormattedMessage id="col_daily" defaultMessage="Daily" />}>
        <Money min={maxCPC > 1 ? maxCPC : 1} max={totalBudget} value={dailyBudget} onChange={onChange} />
      </Tooltip>
    );
  };

  const budgetColumn = {
    title: (
      <Tooltip title={<FormattedMessage id="col_budget_tooltip" defaultMessage="Budget tooltip" />}>
        <FormattedMessage id="col_budget" defaultMessage="Budget" />
      </Tooltip>
    ),
    dataIndex: 'dailyBudget',
    sorter: (a, b) => (a.dailyBudget > b.dailyBudget ? 1 : -1),
    children: [
      {
        render: (text, record) => {
          const { dailyBudget, totalBudget, maxCPC, category, campaignType } = record;
          const onChange = value => {
            onUpdateDailyBudget({ record, dailyBudget: value });
          };

          if (campaignType === 'cpm-category') return <Money disabled />;
          if (columnConfig.readOnly || category || ['banner', 'retail-banner', 'vendor-banner'].includes(campaignType))
            return <Money value={dailyBudget} disabled />;
          if (campaignType === 'facebook-ads') {
            return (
              <Tooltip title={<FormattedMessage id="col_total" defaultMessage="Total" />}>
                <Money value={totalBudget} disabled />
              </Tooltip>
            );
          }
          return (
            <DailyBudget totalBudget={totalBudget} dailyBudget={dailyBudget} maxCPC={maxCPC} onChange={onChange} />
          );
        },
      },
    ],
  };

  const performanceColumns = [
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_impressions_tooltip" defaultMessage="Impressions tooltip" />}>
          <FormattedMessage id="col_impressions" defaultMessage="Impressions" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'impressions',
      sorter: (a, b) => (a.impressions > b.impressions ? 1 : -1),
      children: [
        {
          dataIndex: 'impressions',
          sorter: (a, b) => (a.impressions > b.impressions ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { impressions }) => accumulator + impressions;
            const result = tableData && tableData.reduce(reducer, 0);

            return (
              <div className="table-cell-right">
                <FormattedNumber value={result || 0} />
              </div>
            );
          },
          render: (text, record) => {
            const { impressions } = record;
            return (
              <div className="table-cell-right">
                <FormattedNumber value={impressions || 0} />
              </div>
            );
          },
        },
      ],
    },
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_clicks_tooltip" defaultMessage="Clicks tooltip" />}>
          <FormattedMessage id="col_clicks" defaultMessage="Clicks" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'clicks',
      sorter: (a, b) => (a.clicks > b.clicks ? 1 : -1),
      defaultSortOrder: 'descend',
      children: [
        {
          dataIndex: 'clicks',
          sorter: (a, b) => (a.clicks > b.clicks ? 1 : -1),
          defaultSortOrder: 'descend',
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { clicks }) => accumulator + clicks;
            const result = tableData && tableData.reduce(reducer, 0);

            return (
              <div className="table-cell-right">
                <FormattedNumber value={result || 0} />
              </div>
            );
          },
          render: (text, record) => {
            const { clicks } = record;
            return (
              <div className="table-cell-right">
                <FormattedNumber value={clicks || 0} />
              </div>
            );
          },
        },
      ],
    },
    {
      // CTR = clicks/impressions * 100 , %
      title: (
        <Tooltip title={<FormattedMessage id="col_ctr_tooltip" defaultMessage="CTR tooltip" />}>
          <FormattedMessage id="col_ctr" defaultMessage="CTR" />
        </Tooltip>
      ),
      dataIndex: 'ctr',
      align: 'right',
      sorter: (a, b) => Math.sign(ratio(a.clicks, a.impressions) - ratio(b.clicks, b.impressions)),
      children: [
        {
          dataIndex: 'ctr',
          sorter: (a, b) => Math.sign(ratio(a.clicks, a.impressions) - ratio(b.clicks, b.impressions)),
          className: 'hideSorter',
          title: () => {
            const clicks = (tableData && tableData.reduce((acc, r) => acc + r.clicks, 0)) || 0;
            const impressions = (tableData && tableData.reduce((acc, r) => acc + r.impressions, 0)) || 0;

            return <div className="table-cell-right">{percent(ratio(clicks, impressions))}%</div>;
          },
          render: (text, record, index) => {
            const { clicks, impressions } = record;
            return <div className="table-cell-right">{percent(ratio(clicks, impressions))}%</div>;
          },
        },
      ],
    },
    {
      // CPC = cost/clicks
      title: (
        <Tooltip title={<FormattedMessage id="col_cpc_tooltip" defaultMessage="CPC tooltip" />}>
          <FormattedMessage id="col_cpc" defaultMessage="CPC" />
        </Tooltip>
      ),
      dataIndex: 'cpc',
      align: 'right',
      sorter: (a, b) => Math.sign(ratio(a.cost, a.clicks) - ratio(b.cost, b.clicks)),
      children: [
        {
          dataIndex: 'cpc',
          sorter: (a, b) => Math.sign(ratio(a.cost, a.clicks) - ratio(b.cost, b.clicks)),
          className: 'hideSorter',
          title: () => {
            const cost = (tableData && tableData.reduce((acc, r) => acc + r.cost, 0)) || 0;
            const clicks = (tableData && tableData.reduce((acc, r) => acc + r.clicks, 0)) || 0;

            return <div className="table-cell-right">{formatMoney(ratio(cost, clicks))}</div>;
          },
          render: (text, record) => {
            const { cost, clicks } = record;
            return <div className="table-cell-right">{formatMoney(ratio(cost, clicks))}</div>;
          },
        },
      ],
    },
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_cost_tooltip" defaultMessage="Cost tooltip" />}>
          <FormattedMessage id="col_cost" defaultMessage="Cost" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'cost',
      sorter: (a, b) => (a.cost > b.cost ? 1 : -1),
      children: [
        {
          dataIndex: 'cost',
          sorter: (a, b) => (a.cost > b.cost ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { cost }) => accumulator + cost;
            const result = tableData && tableData.reduce(reducer, 0);

            return <div className="table-cell-right">{formatMoney(result)}</div>;
          },
          render: (text, record) => {
            const { cost } = record;
            return <div className="table-cell-right">{formatMoney(cost)}</div>;
          },
        },
      ],
    },
  ];

  const saleColumns = [
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_items_sold_tooltip" defaultMessage="Products sold tooltip" />}>
          <FormattedMessage id="col_items_sold" defaultMessage="Products sold" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'quantity',
      sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1),
      children: [
        {
          dataIndex: 'quantity',
          sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { quantity }) => accumulator + quantity;
            const result = tableData && tableData.reduce(reducer, 0);

            return <div className="table-cell-right"><FormattedNumber value={result || 0} /></div>;
          },
          render: (text, record, index) => {
            const { quantity } = record;
            //if (campaignType === 'facebook-ads') return <Tooltip title={<FormattedMessage id="facebook_no_sales_tooltip" defaultMessage="Products sold and sales not provided"/>}>-</Tooltip>;
            return <div className="table-cell-right"><FormattedNumber value={quantity || 0} /></div>;
          },
        },
      ],
    },
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_sales_tooltip" defaultMessage="Sales tooltip" />}>
          <FormattedMessage id="col_sales" defaultMessage="Sales" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'revenue',
      sorter: (a, b) => (a.revenue > b.revenue ? 1 : -1),
      children: [
        {
          dataIndex: 'revenue',
          sorter: (a, b) => (a.revenue > b.revenue ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { revenue }) => accumulator + revenue;
            const result = tableData && tableData.reduce(reducer, 0);

            return <div className="table-cell-right">{formatMoney(result)}</div>;
          },
          render: (text, record) => {
            const { revenue } = record;
            //if (campaignType === 'facebook-ads') return <Tooltip title={<FormattedMessage id="facebook_no_sales_tooltip" defaultMessage="Products sold and sales not provided"/>}>-</Tooltip>;
            return <div className="table-cell-right">{formatMoney(revenue)}</div>;
          },
        },
      ],
    },
  ];

  const totalSalesColumns = [
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_total_items_sold_tooltip" defaultMessage="Products sold tooltip" />}>
          <FormattedMessage id="col_total_items_sold" defaultMessage="Products sold" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'totalQuantity',
      sorter: (a, b) => (a.totalQuantity > b.totalQuantity ? 1 : -1),
      children: [
        {
          dataIndex: 'totalQuantity',
          sorter: (a, b) => (a.totalQuantity > b.totalQuantity ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { totalQuantity }) => accumulator + totalQuantity;
            const result = tableData && tableData.reduce(reducer, 0);

            return <div className="table-cell-right"><FormattedNumber value={result || 0} /></div>;
          },
          render: (text, record, index) => {
            const { totalQuantity } = record;
            return <div className="table-cell-right"><FormattedNumber value={totalQuantity || 0} /></div>;
          },
        },
      ],
    },
    {
      title: (
        <Tooltip title={<FormattedMessage id="col_total_sales_tooltip" defaultMessage="Sales tooltip" />}>
          <FormattedMessage id="col_total_sales" defaultMessage="Sales" />
        </Tooltip>
      ),
      align: 'right',
      dataIndex: 'totalRevenue',
      sorter: (a, b) => (a.totalRevenue > b.totalRevenue ? 1 : -1),
      children: [
        {
          dataIndex: 'totalRevenue',
          sorter: (a, b) => (a.totalRevenue > b.totalRevenue ? 1 : -1),
          className: 'hideSorter',
          title: () => {
            const reducer = (accumulator, { totalRevenue }) => accumulator + totalRevenue;
            const result = tableData && tableData.reduce(reducer, 0);

            return <div className="table-cell-right">{formatMoney(result)}</div>;
          },
          render: (text, record) => {
            const { totalRevenue } = record;
            return <div className="table-cell-right">{formatMoney(totalRevenue)}</div>;
          },
        },
      ],
    },
  ];

  const crColumn = {
    title: (
      <Tooltip title={<FormattedMessage id="col_cr_tooltip" />}>
        <FormattedMessage id="col_cr" />
      </Tooltip>
    ),
    dataIndex: 'cr',
    align: 'right',
    sorter: (a, b) => Math.sign(ratio(a.totalQuantity, a.clicks) - ratio(b.totalQuantity, b.clicks)),
    children: [
      {
        dataIndex: 'cr',
        className: 'hideSorter',
        title: () => {
          const totalQuantity = (tableData && tableData.reduce((acc, r) => acc + r.totalQuantity, 0)) || 0;
          const clicks = (tableData && tableData.reduce((acc, r) => acc + r.clicks, 0)) || 0;

          return <div className="table-cell-right">{percent(ratio(totalQuantity, clicks))}%</div>;
        },
        render: (text, record, index) => {
          const { totalQuantity, clicks } = record;
          return <div className="table-cell-right">{percent(ratio(totalQuantity, clicks))}%</div>;
        },
      },
    ],
  };

  const roasColumn = {
    title: (
      <Tooltip title={<FormattedMessage id="col_roas_tooltip" />}>
        <FormattedMessage id="col_roas" />
      </Tooltip>
    ),
    dataIndex: 'roas',
    align: 'right',
    sorter: (a, b) => Math.sign(ratio(a.totalRevenue, a.cost) - ratio(b.totalRevenue, b.cost)),
    children: [
      {
        dataIndex: 'roas',
        className: 'hideSorter',
        title: () => {
          const totalRevenue = (tableData && tableData.reduce((acc, r) => acc + r.totalRevenue, 0)) || 0;
          const cost = (tableData && tableData.reduce((acc, r) => acc + r.cost, 0)) || 0;

          return <div className="table-cell-right">{ratio(totalRevenue, cost).toFixed(2)}</div>;
        },
        render: (text, record, index) => {
          const { totalRevenue, cost } = record;
          return <div className="table-cell-right">{ratio(totalRevenue, cost).toFixed(2)}</div>;
        },
      },
    ],
  };

  const Edit = ({ record }) => {
    const { campaignId, category } = record;

    const btnRef = useRef(null);

    const [isPopupShown, setShowPopup] = useState(false);

    const [coords, setCoords] = useState({}); // takes current button coordinates

    const updateTooltipCoords = button => {
      const rect = btnRef.current.getBoundingClientRect();
      setCoords({
        right: rect.width * 2, // add half the width of the button for centering
        top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      });
    };

    const handleOpen = ({ target }) => {
      updateTooltipCoords(target);
      setShowPopup(true);
    };

    const handleClose = () => {
      setShowPopup(false);
    };

    const handleDelete = () => {
      setShowPopup(false);
      onUpdateStatus({ record, status: 'deleted' });
    };

    if (record.campaignType === 'facebook-ads') return '';

    return (
      <div className={styles.popupContainer} ref={btnRef}>
        <Button iconOnly type="primary" icon={<EditIcon />} onClick={handleOpen} />
        <ActionsPopup isVisible={isPopupShown} onClose={handleClose} coords={coords}>
          {!category && (
            <Link to={`/edit-campaign/${campaignId}`}>
              <PopupItem>
                <FormattedMessage id="btn_edit" />
              </PopupItem>
            </Link>
          )}
          {record.status !== 'deleted' && (
            <PopupItem red onClick={handleDelete}>
              <FormattedMessage id="btn_delete" />
            </PopupItem>
          )}
        </ActionsPopup>
      </div>
    );
  };

  const editColumn = {
    children: [
      {
        key: 'button',
        render: (text, record) => {
          return <Edit record={record} />;
        },
      },
    ],
  };

  if (columnConfig.type === 'account') {
    return [
      accountNameColumn,
      accountStatusColumn,
      ...performanceColumns,
      ...saleColumns,
      ...((columnConfig.settings.has_totals && totalSalesColumns) || []),
      columnConfig.settings.has_product_campaigns_notino && crColumn,
      columnConfig.settings.has_product_campaigns_notino && roasColumn,
    ].filter(c => c); // drop nulls
  }

  if (columnConfig.type === 'campaign') {
    const columns = [
      campaignNameColumn,
      campaignTypeColumn,
      statusColumn,
      ...dateColumns,
      columnConfig.settings.has_bid && bidColumn,
      columnConfig.settings.has_budget && budgetColumn,
      ...performanceColumns,
      ...saleColumns,
      ...((columnConfig.settings.has_totals && totalSalesColumns) || []),
      columnConfig.settings.has_product_campaigns_notino && crColumn,
      columnConfig.settings.has_product_campaigns_notino && roasColumn,
    ].filter(c => c); // drop nulls
    if (columnConfig.readOnly) return columns;
    else return [toggleColumn, ...columns, editColumn];
  }

  if (columnConfig.type === 'product') {
    const columns = [
      productNameColumn,
      campaignTypeColumn,
      statusColumn,
      columnConfig.settings.has_bid && bidColumn,
      ...performanceColumns,
      ...saleColumns,
    ].filter(c => c); // drop nulls
    if (columnConfig.readOnly) return columns;
    else return [toggleColumn, ...columns, editColumn];
  }
};
