import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBannerCampaign } from '../../async';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../../redux';
import { VendorBannerBudgetView } from './views';

export const VendorBannerBudget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);

  const handleBack = () => {
    history.push('/new-campaign/vendor-banner');
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(createBannerCampaign('vendor-banner'));
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(4));
    history.push('/new-campaign/success');
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(3));
  }, []);

  return (
    <VendorBannerBudgetView
      {...{
        step,
        steps,
        campaign,
        loading,
        handleBack,
        handleNext,
        handleChange,
      }}
    />
  );
};
