import React, { useEffect, useState } from 'react';
import { isNotEmpty, isNotEmptyArray } from 'utils/validator';
import { RetailProductFormView } from './views';

const PLACEMENTS = [
  { id: 'home', title: 'Home page', selected: false },
  { id: 'search', title: 'Search results', selected: false },
  { id: 'product', title: 'Product pages', selected: false },
  { id: 'category', title: 'Category page listings', selected: false },
];

const EUROVAISTINE_PLACEMENTS = [
  { id: 'search', title: 'Search results', selected: false },
  { id: 'barbora', title: 'Category recommendations', selected: false }
];

const mapPlacements = (all, selected = []) => {
  return all.map(category => ({
    ...category,
    selected: !!selected.find(item => item === category.id),
  }));
};

export const RetailProductAddTextForm = ({
  step,
  steps,
  campaign,
  userAccount,
  shopOptions,
  initialItems = [],
  invalidItems,
  loading,
  handleBack,
  handleNext,
  handleAdd,
  handleChange,
  handleItemsChange,
  isEdit,
}) => {
  const { name, shopId, itemUrlText, placements = [], items = [] } = campaign;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [isSearch, setIsSearch] = useState(!!placements.find(placement => placement === 'search'));
  
  let basePlacements = PLACEMENTS;
  if (userAccount.shopIds.includes('eurovaistinelt')) basePlacements = EUROVAISTINE_PLACEMENTS;
  
  const [allPlacements, setAllPlacements] = useState(mapPlacements(basePlacements, placements));
  
  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = value => {
    handleChange({ key: 'shopId', value });
  };

  const handleItemsUrlChange = ({ target: { value } }) => {
    handleChange({ key: 'itemUrlText', value });
  };

  const handleInitialItemsChange = value => {
    const newItems = initialItems.map(item => {
      if (item.id === value.id) {
        return value;
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  const handleSelectPlacement = (id, selected) => {
    const newPlacements = allPlacements.map(item => {
      if (item.id === id) {
        return { ...item, selected };
      }
      return item;
    });
    setAllPlacements(newPlacements);
    
    handleChange({ key: 'placements', value: newPlacements.filter(i => i.selected).map(i => i.id) });
  };

  const handleRemoveItem = itemId => {
    const newItems = items.filter(item => item.itemId !== itemId);
    handleChange({ key: 'items', value: newItems });
  };

  const handleUpdateItem = value => {
    handleChange({
      key: 'items',
      value: items.map(item => {
        if (item.itemId === value.itemId) {
          return value;
        }
        return item;
      }),
    });
  };

  useEffect(() => {
    const isSearch = !!placements.find(placement => placement === 'search');
    setIsSearch(isSearch);
  }, [placements]);

  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name)
      : !isNotEmpty(name) || !shopId || !isNotEmptyArray(placements) || !isNotEmptyArray(items);
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, placements, items]);

  return (
    <RetailProductFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        handleAdd,
        loading,
        nextDisabled,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        itemUrlText,
        handleItemsUrlChange,
        initialItems,
        handleInitialItemsChange,
        invalidItems,
        allPlacements,
        items,
        handleUpdateItem,
        handleRemoveItem,
        handleSelectPlacement,
        isSearch,
        isEdit,
      }}
    />
  );
};
