import React, { Fragment } from 'react';
import { Button } from 'components/Button';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
import styles from './Dialog.module.scss';

export const DialogView = ({ open, title, onClose, children }) =>
  open ? (
    <Fragment>
      <div className={styles.overlay} />
      <div className={styles.dialog}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <Button type="ghost" iconOnly className={styles.remove} icon={<IconRemove />} onClick={onClose} />
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </Fragment>
  ) : null;
