import React from 'react';
import { useSelector } from 'react-redux';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { stepSelector, stepsSelector } from '../redux';

export const Content = ({ title, footerControls, children }) => {
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);

  return (
    <FormContent title={title} footerControls={footerControls}>
      {steps && <ProgressBar steps={steps} step={step} />}
      {children}
    </FormContent>
  );
};
