import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { accountSelector } from 'pages/dashboard/redux';
import { createFacebookAdsCampaign } from '../async';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../redux';
import { shopMap } from '../shared';
import { FacebookAdsView } from './views';

export const FacebookAds = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const userAccount = useSelector(accountSelector);
  const shopOptions = userAccount ? userAccount.shopIds.map(id => ({ name: shopMap[id] || id, value: id })) : [];

  const handleBack = () => {
    history.push('/new-campaign');
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(createFacebookAdsCampaign());
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(3));
    history.push('/new-campaign/success');
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(2));
  }, []);

  return (
    <FacebookAdsView
      {...{
        step,
        steps,
        campaign,
        shopOptions,
        loading,
        handleBack,
        handleNext,
        handleChange,
      }}
    />
  );
};
