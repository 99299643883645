import React from 'react';
import { VendorProductAddForm } from 'forms/vendor_product/vendor_product_add_form';

export const VendorProductAddView = ({
  step,
  steps,
  campaign,
  userAccount,
  initialItems,
  invalidItems,
  searchItems,
  loading,
  handleBack,
  handleNext,
  handleAdd,
  handleChange,
  handleItemsChange,
  handleSearch,
}) => (
  <VendorProductAddForm
    {...{
      step,
      steps,
      campaign,
      userAccount,
      initialItems,
      invalidItems,
      searchItems,
      loading,
      handleBack,
      handleNext,
      handleAdd,
      handleChange,
      handleItemsChange,
      handleSearch,
    }}
    isEdit
  />
);
