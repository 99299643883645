import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardRouter } from './router';
import { Header } from 'components/Header';
import { userSelector } from 'pages/login/redux';
import { loadingSelector } from './redux';
import { fetchDashboardDataFast } from './async';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const loading = useSelector(loadingSelector);

  useEffect(() => {
    if (!loading) {
      dispatch(fetchDashboardDataFast(user.accountId));
    }
  }, []);

  return (
    <Fragment>
      <Header />
      <DashboardRouter />
    </Fragment>
  );
};

export default Dashboard;
