import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { editFacebookAdsSteps } from 'components/ProgressBar';
import { editFacebookAdsCampaign } from '../async';
import {
  setStepsAction,
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  setCampaignCreatedAction,
  setLoadingAction,
  stepSelector,
  stepsSelector,
} from '../redux';
import { FacebookAdsView } from './views';

export const FacebookAds = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleNext = async () => {
    dispatch(setLoadingAction(true));
    const { payload } = await dispatch(editFacebookAdsCampaign());
    if (!payload) {
      dispatch(setLoadingAction(false));
      return;
    }
    dispatch(setCampaignCreatedAction(payload));
    dispatch(setStepAction(2));
    history.push(`/edit-campaign/${campaign.campaignId}/success`);
    dispatch(setLoadingAction(false));
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepsAction(editFacebookAdsSteps));
    dispatch(setStepAction(1));
  }, []);

  return (
    <FacebookAdsView
      {...{
        step,
        steps,
        campaign,
        loading,
        handleBack,
        handleNext,
        handleChange,
      }}
    />
  );
};
