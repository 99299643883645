import React from 'react';
import { VendorBannerBudgetForm } from 'forms/banner/banner_vendor_budget_form';

export const VendorBannerBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <VendorBannerBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
    isEdit
  />
);
