import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
import { ReactComponent as IconTrash } from 'assets/icons/IconTrash.svg';
import { ReactComponent as IconLink } from 'assets/icons/IconLink.svg';
import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';
import { Toggle } from 'components/Toggle';
import { FormGroup } from 'components/FormGroup';
import { Placement } from 'components/Placement';
import styles from './RetailProductItem.module.scss';

export const RetailProductItemView = ({
  itemId,
  name,
  url,
  status,
  handleStatusChange,
  onRemove,
  showKeywords,
  handleShowKeywords,
  tooltipText,
  handleCopy,
  allKeywords,
  handleSelectKeyword,
  handleSelectAllKeywords,
  handleAddNewKeyword,
  isSearch,
  isEdit,
}) => (
  <div className={classList(styles.retailProductItem, isEdit && styles.edit)}>
    <FormGroup title={<FormattedMessage id="form_product_added" />} className={styles.productLinkForm}>
      <div className={styles.linkContainer}>
        <Toggle checked={status} onChange={handleStatusChange} className={styles.toggle} />
        <div className={styles.link}>
          <Tooltip title={tooltipText}>
            <IconLink onClick={() => handleCopy(url)} />
          </Tooltip>
          <span>{name}</span>
        </div>
        {!isEdit && (
          <Button
            type="ghost"
            iconOnly
            className={styles.remove}
            icon={<IconTrash />}
            onClick={() => onRemove(itemId)}
          />
        )}
      </div>
    </FormGroup>
    <div className={styles.addContainer}>
      {isSearch && (
        <Fragment>
          {showKeywords ? (
            <Button type="ghost" size="large" icon={<IconRemove />} disabled={!isSearch} onClick={handleShowKeywords}>
              <FormattedMessage id="btn_close_keywords" />
            </Button>
          ) : (
            <Button type="primary" size="large" icon={<IconCreate />} disabled={!isSearch} onClick={handleShowKeywords}>
              <FormattedMessage id="btn_add_keywords" />
            </Button>
          )}
        </Fragment>
      )}
    </div>
    {showKeywords && isSearch && (
      <div>
        <FormGroup
          title={<FormattedMessage id="form_product_keywords" />}
          subtitle={<FormattedMessage id="form_product_keywords_description" />}
        />
        <Placement
          style={{ marginBottom: '0px' }}
          items={allKeywords}
          handleSelect={handleSelectKeyword}
          handleSelectAll={handleSelectAllKeywords}
          handleAdd={handleAddNewKeyword}
          isEdit={isEdit}
          searchPlaceholder={<FormattedMessage id="form_product_keywords_search" />}
          withAdd
          small
        />
      </div>
    )}
  </div>
);
