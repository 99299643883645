import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'components/Container';
import { Row } from 'components/Row';
import { Col } from 'components/Col';
import { Table } from 'components/Table';
import { DownloadXlsForm } from 'components/Table/download';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { Charts } from '../charts';
import { fetchDashboardDataProduct } from '../async';
import { OverviewHeader } from '../overview_header';
import { StatusSelect } from '../status_select';
import { TypeSelect } from '../type_select';
import styles from '../user_dashboard/user_dashboard.module.scss';

import { ReactComponent as IconSearch } from 'assets/icons/IconSearch.svg';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';

export const ProductDashboardView = ({
  accountId,
  chartData,
  columnConfig,
  products,
  loading,
  query,
  status,
  type,
  pageSize,
  handleQueryChange,
  handleStatusChange,
  handleTypeChange,
  handleUpdateStatus,
  handleUpdateMaxCPC,
  handlePageSizeChange,
}) => (
  <Fragment>
    <OverviewHeader fetchAction={fetchDashboardDataProduct} isProduct/>
    <Charts chartData={chartData} />
    <Container>
      <Row className={styles.headerSection}>
        <Col className={styles.tabsCol}>
          <div className={styles.tabs}>
            <Link className={styles.tab} to={`/dashboard/${accountId}`}>
              <FormattedMessage id="tab_campaigns" defaultMessage="Campaigns" />
            </Link>
            <div className={`${styles.tab} ${styles.active}`}>
              <FormattedMessage id="tab_items" defaultMessage="Products" />
            </div>
          </div>
        </Col>
        <Col className={styles.filterCol}>
          <div className={styles.typeSelect}>
            <TypeSelect value={type} onChange={handleTypeChange} />
          </div>
          <div className={styles.statusSelect}>
            <StatusSelect value={status} onChange={handleStatusChange} />
          </div>
          <div className={styles.search}>
            <TextInput
              value={query}
              onChange={handleQueryChange}
              label={<FormattedMessage id="input_search_products_placeholder" />}
              prefixIcon={<IconSearch />}
            />
          </div>
          <div className={styles.download}>
            <DownloadXlsForm columnConfig={columnConfig} tableData={products} />
          </div>
        </Col>
      </Row>
    </Container>
    <Container>
      <Table
        columnConfig={columnConfig}
        tableData={products}
        onUpdateStatus={handleUpdateStatus}
        onUpdateMaxCPC={handleUpdateMaxCPC}
        pageSize={pageSize}
        onUpdatePageSize={handlePageSizeChange}
        loading={loading}
      />
    </Container>
    <Container>
      <Row className={styles.padding24}>
        <Col>
          <Link to="/new-campaign">
            <Button type="primary" icon={<IconCreate />} className={styles.addNewLink}>
              <FormattedMessage id="btn_new_campaign" defaultMessage="Create new campaign" />
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </Fragment>
);
