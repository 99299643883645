import React from 'react';
import { useSelector } from 'react-redux';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { stepSelector, stepsSelector } from '../redux';

export const Content = ({ title, footerControls, children }) => {
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);

  return (
    <FormContent title={title} footerControls={footerControls}>
      {steps && <ProgressBar steps={steps} step={step} />}
      {children}
    </FormContent>
  );
};

export const shopMap = {
  'pigult': 'pigu.lt',
  '220lv': '220.lv',
  'kaup24ee': 'kaup24.ee',
  'pigult-test': 'pigu.lt',
  '220lv-test': '220.lv',
  'kaup24ee-test': 'kaup24.ee',
  'senukailt': 'senukai.lt',
  'senukailv': 'ksenukai.lv',
  'senukaiee': 'k-rauta.ee',
  '1alt': '1a.lt',
  '1alv': '1a.lv',
  '1aee': '1a.ee',
  'eurovaistinelt': 'eurovaistine.lt',
  'azetalv': 'azeta.lv',
  'azetaee': 'azeta.ee',
  'hobbyhallfi': 'hobbyhall.fi',
};
