import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TextInput, PasswordInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { AuthContainer } from 'components/AuthContainer';
import styles from './login.module.scss';

export const LoginView = ({
  email,
  password,
  error,
  handleEmailChange,
  handlePasswordChange,
  handleValidate,
  handleEmailFocus,
  handlePasswordFocus,
}) => (
  <AuthContainer
    title={<FormattedMessage id="login_title" defaultMessage="Welcome back," />}
    subtitle={<FormattedMessage id="login_subtitle" defaultMessage="sign in to your account and start advertising!" />}
  >
    <form className={styles.login} onSubmit={handleValidate} autoComplete="off">
      <div className={styles.inputs}>
        <TextInput
          value={email}
          onChange={handleEmailChange}
          label={<FormattedMessage id="login_form_user_email_or_id" defaultMessage="User email / ID" />}
          error={error.email || error.login}
          errorText={error.email && <FormattedMessage id="login_form_user_email_or_id_error" />}
          onFocus={handleEmailFocus}
        />
        <PasswordInput
          value={password}
          onChange={handlePasswordChange}
          label={<FormattedMessage id="login_form_password" defaultMessage="Password" />}
          error={error.password || error.login}
          errorText={
            error.login ? (
              <FormattedMessage id="login_form_error" />
            ) : (
              <FormattedMessage id="login_form_password_error" />
            )
          }
          onFocus={handlePasswordFocus}
        />
      </div>
      <div className={styles.buttons}>
        <Button type="ghost" className={styles.button}>
          <Link to="/password-reset">
            <FormattedMessage id="forgot_password" defaultMessage="Forgot password?" />
          </Link>
        </Button>

        <Button className={styles.button} htmlType="submit">
          <FormattedMessage id="bnt_login" defaultMessage="Sign in" />
        </Button>
      </div>
    </form>
  </AuthContainer>
);
