import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as AddIcon } from 'assets/icons/IconCreate.svg';

import { Button } from 'components/Button';
import styles from './FileUpload.module.scss';

export const FileUploadView = ({ isDragActive, getRootProps, getInputProps, placeholder }) => (
  <Fragment>
    <div {...getRootProps()} className={classList(styles.container, isDragActive && styles.active)}>
      <input {...getInputProps()} />
      <div className={styles.placeholder}>{placeholder}</div>
      <div className={styles.separator}>
        <FormattedMessage id="file_upload_or" />
      </div>
      <Button type="ghost" icon={<AddIcon />}>
        <FormattedMessage id="file_upload_choose_files" />
      </Button>
    </div>
  </Fragment>
);
