import React from 'react';
import { SponsoredBrandForm } from 'forms/sponsored_brands/sponsored_brands_add_form';

export const SponsoredBrandsAddView = ({
  step,
  steps,
  campaign,
  brandOptions,
  loading,
  handleBack,
  handleNext,
  handleChange,
}) => (
  <SponsoredBrandForm
    {...{
      step,
      steps,
      campaign,
      brandOptions,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
    isEdit
  />
);
