import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { MoneyInput } from 'components/TextInput';
import { Toggle } from 'components/Toggle';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { CampaignDuration } from 'components/CampaignDuration';
import { ReactComponent as IconInfo } from 'assets/icons/IconInfo.svg';
import { Tooltip } from 'components/Tooltip';
import styles from './product_budget_form.module.scss';

export const ProductBudgetFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  dailyBudget,
  handleDailyBudgetChange,
  totalBudgetEnabled,
  totalBudget,
  handleTotalBudgetToggle,
  handleTotalBudgetChange,
  maxCPC,
  handleCPCChange,
  startDate,
  endDate,
  minDate,
  minEndDate,
  handleStartDateChange,
  handleEndDateChange,
  dailyError,
  totalError,
  maxCPCError,
  maxCPCWarning,
  isEdit,
  settings,
}) => (
  <Fragment>
    <FormContent
      title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
      footerControls={
        <Fragment>
          <Button type="ghost" size="large" onClick={handleBack}>
            <FormattedMessage id="btn_back" />
          </Button>
          <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
            {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create_campaign" />}
          </Button>
        </Fragment>
      }
    >
      {steps && <ProgressBar steps={steps} step={step} />}
      <FormGroup
        title={<FormattedMessage id="form_daily_budget" />}
        subtitle={<FormattedMessage id="form_daily_budget_description" />}
        tooltip={<FormattedMessage id="form_daily_budget_tooltip" />}
      >
        <MoneyInput
          addonBefore={'€'}
          value={dailyBudget}
          onChange={handleDailyBudgetChange}
          label={<FormattedMessage id="form_daily_budget_placeholder" />}
        />
        {dailyError && (
          <div className={styles.error} style={{  marginTop: '8px'}}>
            <FormattedMessage id={dailyError} />
          </div>
        )}
      </FormGroup>
      <FormGroup
        title={
          <span style={{ letterSpacing: '-0.9px' }}>
            <FormattedMessage id="form_total_budget_optional" />
          </span>
        }
        subtitle={<FormattedMessage id="form_total_budget_description" />}
        tooltip={<FormattedMessage id="form_total_budget_tooltip" />}
        style={{ alignItems: 'center' }}
      >
        <div className={styles.totalBudgetContainer}>
          {totalBudgetEnabled && (
            <div className={styles.totalBudgetInput}>
              <MoneyInput
                addonBefore={'€'}
                value={totalBudget}
                onChange={handleTotalBudgetChange}
                label={<FormattedMessage id="form_total_budget_placeholder" />}
              />
            </div>
          )}
          <Toggle checked={totalBudgetEnabled} onChange={handleTotalBudgetToggle} />
        </div>
        {totalError && (
          <div className={styles.error} style={{  marginTop: '8px'}}>
            <FormattedMessage id={totalError} />
          </div>
        )}
      </FormGroup>
      <FormGroup
        title={<FormattedMessage id="form_max_cpc" />}
        subtitle={<FormattedMessage id="form_max_cpc_description" />}
        tooltip={<FormattedMessage id="form_max_cpc_tooltip" />}
      >
        <MoneyInput
          addonBefore={'€'}
          value={maxCPC}
          onChange={handleCPCChange}
          label={<FormattedMessage id="form_max_cpc_placeholder" />}
        />
        {settings.has_product_campaigns_notino ? (
        <div className={styles.info}>
          <FormattedMessage
            id="form_max_cpc_min_bid"
            values={{
              minBid: '0.50',
            }}
          />
        </div>
        ) : (
          <div className={styles.info}>
          <FormattedMessage
            id="form_max_cpc_bids"
            values={{
              br: <br />,
              tooltip: <Tooltip title={<FormattedMessage id="form_cpc_bid_range_description" />}>
                        <IconInfo className={styles.icon} />
                      </Tooltip>,
            }}
          />
        </div>  
        )}
        {maxCPCError && (
          <div className={styles.error}>
            <FormattedMessage id={maxCPCError} values={settings.has_product_campaigns_notino ? { minBid: '0.50', maxBid: '5.00' } : { minBid: '0.06', maxBid: '2.00' }} />
          </div>
        )}
        {maxCPCWarning && (
          <div className={styles.error}>
            <FormattedMessage id={maxCPCWarning} />
          </div>
        )}
      </FormGroup>
      {/*<FormGroup title={<FormattedMessage id="form_cpc_bid_range" />}>
        <div className={styles.info} style={{  marginTop: '0px'}}>
          <FormattedMessage id="form_cpc_bid_range_description" />
        </div>
      </FormGroup>*/}
      <FormGroup
        title={<FormattedMessage id="form_campaign_duration" />}
        subtitle={<FormattedMessage id="form_campaign_duration_description" />}
        tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
      >
        <CampaignDuration
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          minEndDate={minEndDate}
          onStartChange={handleStartDateChange}
          onEndChange={handleEndDateChange}
          startDisabled={isEdit}
        />
      </FormGroup>
    </FormContent>
  </Fragment>
);
