import React, { useEffect }  from 'react';
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom';
import Login from 'pages/login';
import PasswordReset from 'pages/password_reset';
import NewCampaign from 'pages/new_campaign';
import EditCampaign from 'pages/edit_campaign';
import Dashboard from 'pages/dashboard';
import ChangePassword from 'pages/change_password';
import Faq from 'pages/faq';
import Analytics from 'pages/analytics';
import BannerZones from 'pages/banner_zones';
import { ConditionalRoute } from 'components/ConditionalRoute';

// https://v5.reactrouter.com/web/guides/scroll-restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Router = ({ isAuthorized }) => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <ConditionalRoute
        exact
        path="/martailer-login"
        component={Login}
        condition={!isAuthorized}
        redirectTo="/dashboard"
      />
      <ConditionalRoute
        exact
        path="/password-reset"
        component={PasswordReset}
        condition={!isAuthorized}
        redirectTo="/"
      />
      <ConditionalRoute
        path="/new-campaign"
        component={NewCampaign}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/edit-campaign/:campaignId"
        component={EditCampaign}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/dashboard"
        component={Dashboard}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/change-password"
        component={ChangePassword}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/faq"
        component={Faq}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/analytics"
        component={Analytics}
        condition={isAuthorized}
        redirectTo="/martailer-login"
      />
      <ConditionalRoute
        path="/zones"
        component={BannerZones}
        condition={true}
        redirectTo="/martailer-login"
      />
      <Redirect to="/dashboard" />
    </Switch>
  </BrowserRouter>
);

export default Router;
