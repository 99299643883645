import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconInfo } from 'assets/icons/IconInfo.svg';
import { isNotEmpty } from 'utils/validator';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { FileUpload } from 'components/FileUpload';
import { BannerItem } from 'components/BannerItem';
import { Dialog } from 'components/Dialog';
import { SupportedSizes } from 'components/SupportedSizes';
import styles from './banner_add_form.module.scss';

export const ConfirmLandingUrl = ({ open, onClose, onConfirm }) => (
  <Dialog title={<FormattedMessage id="form_landing_page_url" />} open={open} onClose={onClose}>
    <div className={styles.dialogContainer}>
      <div className={styles.dialogContent}>
        <IconInfo className={styles.icon} />
        <div className={styles.title}>
          <FormattedMessage id="form_remove_confirm_title" />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage id="form_banner_url_confirm_description" />
        </div>
      </div>
      <div className={styles.actions}>
        <Button type="ghost" size="large" onClick={onClose}>
          <FormattedMessage id="form_banner_url_confirm_enter" />
        </Button>
        <Button size="large" onClick={onConfirm}>
          <FormattedMessage id="btn_continue" />
        </Button>
      </div>
    </div>
  </Dialog>
);

const ConfirmLandingUrlDialog = props => createPortal(<ConfirmLandingUrl {...props} />, document.getElementById('root'));

export const BannerAddFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  loading,
  nextDisabled,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  landingUrl,
  handleLandingUrlChange,
  banners,
  handleBannersChange,
  handleUpdateBanner,
  handleRemoveBanner,
  initialItems,
  handleInitialItemsChange,
  handleInitialItemsRemove,
  landingUrlError,
  showLandingUrlConfirm,
  handleShowLandingUrlConfirm,
  handleConfirm,
  userAccount,
  supportedSizes,
  shopPreselected,
  initialItemsExpanded,
  handleItemsExpand,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          <FormattedMessage id="btn_next" />
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && !shopPreselected && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    <FormGroup
      title={<FormattedMessage id="form_landing_page_url" />}
      subtitle={<FormattedMessage id="form_add_banners_placeholder" />}
      // tooltip={<FormattedMessage id="form_shop_tooltip" />}
    >
      <TextInput
        value={landingUrl}
        onChange={handleLandingUrlChange}
        label={<FormattedMessage id="form_add_banners_placeholder" />}
        error={landingUrlError}
      />
      {isNotEmpty(landingUrl) && landingUrlError && (
        <div className={styles.error}>
          <FormattedMessage id="form_banner_url_error" />
        </div>
      )}
    </FormGroup>
    {isEdit && !!initialItems.length && (
      <Fragment>
        <FormGroup style={{ marginBottom: '0px' }} title={<span><FormattedMessage id="form_added_banners" /> ({initialItems.length})</span>}>
          <div className={styles.expandContainer}>
            {initialItemsExpanded ? (
              <Button type="ghost" size="small" onClick={() => handleItemsExpand(false)}>
                <FormattedMessage id="btn_collapse_items" />
              </Button>
            ) : (
              <Button type="ghost" size="small" onClick={() => handleItemsExpand(true)}>
                <FormattedMessage id="btn_expand_items" />
              </Button>
            )}
            </div>
        </FormGroup>
        {initialItemsExpanded && (
          <div>
            {initialItems.map(file => (
              <BannerItem
                key={file.bannerUrl}
                name={file.name}
                url={file.bannerUrl}
                size={file.size}
                banner={file}
                landingUrl={landingUrl}
                onUpdate={handleInitialItemsChange}
                onRemove={handleInitialItemsRemove}
                userAccount={userAccount}
                isEdit={isEdit}
                isInitial
              />
            ))}
          </div>
        )}
      </Fragment>
    )}
    <div className={styles.fileUploadContainer}>
      <FileUpload
        onChange={handleBannersChange}
        placeholder={<FormattedMessage id="form_drag_drop_banners_placeholder" />}
      />
    </div>
    {supportedSizes && <SupportedSizes sizesConfig={supportedSizes} />}
    <div>
      {banners.map(file => (
        <BannerItem
          key={file.url}
          name={file.name}
          url={file.url}
          size={file.size}
          banner={file}
          landingUrl={landingUrl}
          onUpdate={handleUpdateBanner}
          onRemove={handleRemoveBanner}
          userAccount={userAccount}
        />
      ))}
    </div>
    <ConfirmLandingUrlDialog
      open={showLandingUrlConfirm}
      onClose={() => handleShowLandingUrlConfirm(false)}
      onConfirm={handleConfirm}
    />
  </FormContent>
);
