export const languages = [
  {
    value: 'en',
    title: 'English',
  },
  {
    value: 'lt',
    title: 'Lietuvių',
  },
  {
    value: 'lv',
    title: 'Latviešu',
  },
  {
    value: 'ee',
    title: 'Eesti',
  },
  {
    value: 'pl',
    title: 'Polski',
  },
  {
    value: 'fi',
    title: 'Suomi',
  },
];

export const demoLanguages = [
  ...languages,
  {
    value: 'fr',
    title: 'Français',
  },
];
