import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextInput, PasswordInput } from 'components/TextInput';
import { Checkbox } from 'components/Checkbox';
import { Button } from 'components/Button';
import { OverlayForm } from 'components/OverlayForm';
import styles from './user_register.module.scss';

export const UserRegisterView = ({
  newEmail,
  newPassword,
  newPasswordRepeat,
  phone,
  termsAndConditions,
  handleEmailChange,
  handlePasswordChange,
  handlePasswordRepeatChange,
  handlePhoneChange,
  handleAgree,
  submitDisabled,
  handleSubmit,
  loading,
  error,
}) => (
  <OverlayForm
    title={<FormattedMessage id="register_form_title" />}
    subtitle={<FormattedMessage id="register_form_subtitle" />}
  >
    <TextInput
      className={styles.email}
      value={newEmail}
      onChange={handleEmailChange}
      label={<FormattedMessage id="register_form_email" />}
      error={error.newEmail}
      errorText={<FormattedMessage id="register_form_valid_email" />}
    />
    <div className={styles.emailDescription}>
      <FormattedMessage id="register_form_email_description" />
    </div>
    <PasswordInput
      value={newPassword}
      onChange={handlePasswordChange}
      label={<FormattedMessage id="register_form_password" />}
      className={styles.password}
      error={error.newPassword}
      errorText={<FormattedMessage id="register_form_password_requirements" />}
    />
    <PasswordInput
      value={newPasswordRepeat}
      onChange={handlePasswordRepeatChange}
      label={<FormattedMessage id="register_form_repeat" />}
      className={styles.passwordRepeat}
      error={error.newPasswordRepeat}
      errorText={<FormattedMessage id="register_form_missmatch" />}
    />
    <TextInput
      type="tel"
      value={phone}
      onChange={handlePhoneChange}
      label={<FormattedMessage id="register_form_phone" />}
      className={styles.phone}
    />
    <div className={styles.terms}>
      <Checkbox className={styles.checkbox} checked={termsAndConditions} onChange={handleAgree} />
      <div>
        <FormattedMessage
          id="register_form_agree"
          values={{
            terms: (
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href="https://martailer.com/wp-content/uploads/2020/08/Terms-of-Services-and-Privacy-Policy_Martailer-Advertising-Platform-Rules_20200820.pdf"
              >
                <FormattedMessage id="register_form_terms" />
              </a>
            ),
          }}
        />
      </div>
    </div>
    <Button className={styles.submit} disabled={submitDisabled} onClick={handleSubmit} loading={loading}>
      <FormattedMessage id="btn_register" />
    </Button>
  </OverlayForm>
);
