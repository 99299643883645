import api from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchDashboardDataFast = createAsyncThunk('dashboad/fetchDashboardDataFast', async (accountId, { getState }) => {
  const start_date = getState().dashboard.startDate;
  const end_date = getState().dashboard.endDate;
  const { data } = await api.get('/api/dump', { params: { skip_data: 'true', start_date, end_date } });
  return { data, accountId };
});

export const fetchDashboardDataCampaign = createAsyncThunk('dashboad/fetchDashboardDataCampaign', async (accountId, { getState }) => {
  const start_date = getState().dashboard.startDate;
  const end_date = getState().dashboard.endDate;
  const { data } = await api.get('/api/dump/campaigns', { params: { start_date, end_date } });
  return { data, accountId };
});

export const fetchDashboardDataProduct = createAsyncThunk('dashboad/fetchDashboardDataProduct', async (accountId, { getState }) => {
  const campaign_id = getState().dashboard.campaignId;
  const start_date = getState().dashboard.startDate;
  const end_date = getState().dashboard.endDate;
  const { data } = await api.get('/api/dump', { params: { start_date, end_date, campaign_id } });
  return { data, accountId };
});

export const updateCampaign = createAsyncThunk('dashboad/updateCampaign', async campaign => {
  const { data } = await api.post('/api/put', { campaigns: [campaign] });
  return data;
});

export const updateProduct = createAsyncThunk('dashboad/updateProduct', async product => {
  const { data } = await api.post('/api/put', { aditems: [product] });
  return data;
});
