import React from 'react';
import { CategoryForm } from 'forms/category';

export const CategoryView = ({
  step,
  steps,
  campaign,
  shopOptions,
  invalidItems,
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleAdd,
}) => (
  <CategoryForm
    {...{
      step,
      steps,
      campaign,
      shopOptions,
      invalidItems,
      loading,
      handleBack,
      handleNext,
      handleChange,
      handleAdd,
    }}
  />
);
