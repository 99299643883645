import React from 'react';
import { Select } from 'antd';
import { classList } from 'utils/classList';
import { FloatLabel } from '../FloatLabel';
import { ReactComponent as IconDown } from '../../assets/icons/IconDown.svg';
import { ReactComponent as IconRemove } from '../../assets/icons/IconRemove.svg';
import './styles.scss';

const { Option } = Select;

export const SelectInput = ({ value, onChange, label, options, error, small, className, searchable,...props }) => (
  <div className={classList('selectContainer', className)}>
    <FloatLabel label={label} name={value} value={value}>
      <Select
        showSearch={searchable}
        dropdownStyle={error && styles.error}
        suffixIcon={<IconDown className={'arrow'} />}
        clearIcon={<IconRemove className={'clear'} />}
        className={classList(error && 'select-error', small && 'small')}
        value={value}
        onChange={onChange}
        {...props}
      >
        {options.map((item, index) => (
          <Option key={index} {...item}>
            {item.name}
          </Option>
        ))}
      </Select>
    </FloatLabel>
  </div>
);

const styles = {
  error: {
    borderColor: 'red',
  },
};
