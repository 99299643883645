import { createSlice, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

const sizePresetOptions = [
  { id: '1', name: 'Preset 1 (1050x150)', value: '1', dimensions: { width: 1050, height: 150 } },
  { id: '2', name: 'Preset 2 (565x972)', value: '2', dimensions: { width: 565, height: 972 } },
  { id: '3', name: 'Preset 3 (400x100)', value: '3', dimensions: { width: 400, height: 100 } },
];

const BANNER_ZONES = [
  {
    id: '1',
    name: 'banner zone 1',
    dimensions: { width: 1050, height: 150 },
    description: 'Description',
    updatedAt: '2023-03-24',
    type: 'banner',
  },
  {
    id: '2',
    name: 'banner zone 2',
    dimensions: { width: 565, height: 972 },
    description: 'Description',
    updatedAt: '2023-03-24',
    type: 'text',
  },
  {
    id: '3',
    name: 'banner zone 3',
    dimensions: { width: 400, height: 120 },
    description: 'Description',
    updatedAt: '2023-03-24',
    type: 'video',
  },
];

const initialState = {
  bannerZone: undefined,
  bannerZones: BANNER_ZONES,
};

export const bannerZonesSlice = createSlice({
  name: 'banner_zones',
  initialState: initialState,
  reducers: {
    setBannerZoneAction: (state, action) => {
      state.bannerZone[action.payload.key] = action.payload.value;
    },
    setInitialBannerZoneAction: (state, action) => {
      state.bannerZone = action.payload;
    },
    setBannerZoneCreateAction: (state, action) => {
      const payload = { id: `§${Date.now()}`, updatedAt: moment().format('YYYY-MM-DD'), ...state.bannerZone}
      state.bannerZones = [...state.bannerZones, payload];
    },
    setBannerZoneEditAction: (state, action) => {
      state.bannerZones = state.bannerZones.map(item => {
        if (item.id === state.bannerZone.id) {
          return state.bannerZone;
        }
        return item;
      });
    },
    setInitialStateAction: (state, action) => {
      state.bannerZone = undefined;
    },
  },
});

export const { setBannerZoneAction, setInitialBannerZoneAction, setInitialStateAction, setBannerZoneCreateAction, setBannerZoneEditAction } = bannerZonesSlice.actions;

export const bannerZonesSelector = state => state.bannerZones.bannerZones;
export const bannerZoneSelector = state => state.bannerZones.bannerZone;

const bannerZoneIdSelector = (state, zoneId) => zoneId;

export const bannerZoneByIdSelector = createSelector([bannerZonesSelector, bannerZoneIdSelector], (items, zoneId) => {
  return items.find(item => item.id === zoneId);
});

export default bannerZonesSlice.reducer;
