import React, { useState } from 'react';
import { Table as AntTable } from 'antd';
import './styles.scss';
import { getTableColumns } from './columns';

export const Table = ({
  columnConfig,
  tableData,
  onUpdateStatus,
  onUpdateMaxCPC,
  onUpdateDailyBudget,
  onUpdateEndDate,
  onUpdatePageSize,
  pageSize,
  loading,
}) => {
  const [tablePageSize, setTablePageSize] = useState(pageSize);

  const columns = getTableColumns(
    tableData,
    columnConfig,
    onUpdateStatus,
    onUpdateMaxCPC,
    onUpdateDailyBudget,
    onUpdateEndDate
  );

  const handleChange = e => {
    if (tablePageSize !== e.pageSize) {
      setTablePageSize(e.pageSize);
      onUpdatePageSize(e.pageSize);
    }
  };

  return (
    <div>
      <AntTable
        columns={columns}
        dataSource={tableData}
        loading={loading}
        showSorterTooltip={false}
        scroll={{ x: true }}
        rowKey="id"
        onChange={handleChange}
        className={`${columnConfig.type}-table`}
        pagination={{
          pageSize: tablePageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '100'],
        }}
      />
    </div>
  );
};
