import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail, validatePassword } from 'utils/validator';
import { loginSelector, setLoginAction, setLoginErrorAction, userRegister } from 'pages/login/redux';
import { UserRegisterView } from './views';

export const UserRegister = () => {
  const dispatch = useDispatch();
  const { newEmail, newPassword, newPasswordRepeat, phone, termsAndConditions, loading, error } = useSelector(
    loginSelector
  );

  const handleAgree = ({ target: { checked } }) => {
    dispatch(setLoginAction({ key: 'termsAndConditions', value: checked }));
  };

  const handleEmailChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'newEmail', value: value }));
  };

  const handlePasswordChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'newPassword', value: value }));
  };

  const handlePasswordRepeatChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'newPasswordRepeat', value: value }));
  };

  const handlePhoneChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'phone', value: value }));
  };

  const submitDisabled = !termsAndConditions || !newEmail || !newPassword || !newPasswordRepeat;

  const handleSubmit = () => {
    const emailIsValid = validateEmail(newEmail);
    const passwordIsValid = validatePassword(newPassword);
    const passwordRepeatIsValid = newPassword === newPasswordRepeat;

    dispatch(setLoginErrorAction({ key: 'newEmail', value: !emailIsValid }));
    dispatch(setLoginErrorAction({ key: 'newPassword', value: !passwordIsValid }));
    dispatch(
      setLoginErrorAction({
        key: 'newPasswordRepeat',
        value: !passwordRepeatIsValid,
      })
    );

    if (emailIsValid && passwordIsValid && passwordRepeatIsValid) {
      dispatch(userRegister());
    }
  };

  return (
    <UserRegisterView
      {...{
        newEmail,
        newPassword,
        newPasswordRepeat,
        phone,
        termsAndConditions,
        handleEmailChange,
        handlePasswordChange,
        handlePasswordRepeatChange,
        handlePhoneChange,
        handleAgree,
        submitDisabled,
        handleSubmit,
        loading,
        error,
      }}
    />
  );
};
