import React, { useState } from 'react';
import { Input } from 'antd';
import { classList } from 'utils/classList';
import { FloatLabel } from '../FloatLabel';
import './styles.scss';

export const MoneyInput = ({ value = '', onChange, ...props }) => {
  const formatMoney = value => {
    const val = (value || 0).toString().replace(/[^0-9.,]+/g, '');
    return Number(val).toFixed(2);
  };
  const [innerValue, setInnerValue] = useState(formatMoney(value));

  const setValue = ({ target: { value } }) => {
    setInnerValue(value);
  };
  const handleBlur = ({ target: { value } }) => {
    onChange(Number(formatMoney(value)));
    setInnerValue(formatMoney(value));
  };

  return (
    <TextInput type="number" addonBefore={'€'} value={innerValue} onChange={setValue} onBlur={handleBlur} {...props} />
  );
};

export const PasswordInput = ({ value, onChange, label, error, errorText, className, ...props }) => (
  <div className={classList('inputContainer', className)}>
    <FloatLabel label={label} name={value} value={value} error={error}>
      <Input.Password style={error ? styles.error : {}} value={value} onChange={onChange} {...props} />
    </FloatLabel>
    {!!error && !!errorText && <div className="error-text">{errorText}</div>}
  </div>
);

export const TextInput = ({
  value,
  onChange,
  label,
  small,
  error,
  errorText,
  addonBefore,
  addonAfter,
  prefixIcon,
  className,
  ...props
}) => (
  <div
    className={classList(
      'inputContainer',
      addonBefore && `addon-before`,
      prefixIcon && 'prefix-icon-input',
      small && `small-input`,
      className
    )}
  >
    {label && !small ? (
      <FloatLabel label={label} name={value} value={value} error={error}>
        <Input
          value={value}
          onChange={onChange}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          prefix={prefixIcon}
          {...(error && { style: styles.error })}
          {...props}
        />
      </FloatLabel>
    ) : (
      <div className={`no-label-input-wrapper`}>
        <Input
          className={classList(error && 'error', small && `small-input`)}
          value={value}
          onChange={onChange}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          type={props.type}
          {...(error && { style: styles.error })}
          {...props}
        />
      </div>
    )}
    {!!error && !!errorText && <div className="error-text">{errorText}</div>}
  </div>
);

const styles = {
  error: {
    borderColor: '#FF5660',
  },
};
