import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Icon } from 'assets/icons/IconHome.svg';
import { userSelector } from 'pages/login/redux';
import { fetchDashboardDataFast } from 'pages/dashboard/async';
import { Button } from 'components/Button';
import { Success } from 'components/Success';
import { Content } from '../shared';
import { createdCampaignSelector } from '../redux';
import styles from './success.module.scss';

const ProductSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const campaign = useSelector(createdCampaignSelector);

  const handleEdit = () => {
    dispatch(fetchDashboardDataFast(user.accountId));
    history.push(`/edit-campaign/${campaign.id}`);
  };

  return (
    <Content
      title={<FormattedMessage id="form_edit_campaign" />}
      footerControls={
        <Fragment>
          <Button type="ghost" size="large" onClick={handleEdit}>
            <FormattedMessage id="btn_edit" />
          </Button>
          <Link className={styles.dashboardLink} to="/dashboard">
            <Button size="large" icon={<Icon />}>
              <FormattedMessage id="btn_dashboard" />
            </Button>
          </Link>
        </Fragment>
      }
    >
      <Success campaignName={campaign.name} isEdit />
    </Content>
  );
};

export default ProductSuccess;
