import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isNotEmpty, isNotEmptyArray } from 'utils/validator';
import { CategoryFormView } from './views';

export const CategoryForm = ({
  step,
  steps,
  campaign,
  shopOptions,
  initialItems = [],
  invalidItems,
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleItemsChange,
  handleAdd,
  isEdit,
}) => {
  const { name, shopId, itemUrlText, categoryItems = [], startDate, endDate } = campaign;
  const [minDate] = useState(moment().add(1, 'days').startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [nextDisabled, setNextDisabled] = useState(false);

  const [dailyCosts, setDailyCosts] = useState(0);
  const [totalCosts, setTotalCosts] = useState(0);

  const handleRemoveItem = itemId => {
    const items = categoryItems.filter(item => item.itemId !== itemId);
    handleChange({ key: 'categoryItems', value: items });
  };

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = value => {
    handleChange({ key: 'shopId', value });
  };

  const handleProductUrl = ({ target: { value } }) => {
    handleChange({ key: 'itemUrlText', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handleItemBidChange = (value, itemId) => {
    const items = categoryItems.map(item => {
      if (item.itemId === itemId) {
        return { ...item, maxCPC: value };
      }
      return item;
    });
    handleChange({ key: 'categoryItems', value: items });
  };

  const handleStatusChange = (value, itemId) => {
    const items = categoryItems.map(item => {
      if (item.itemId === itemId) {
        return { ...item, status: value };
      }
      return item;
    });
    handleChange({ key: 'categoryItems', value: items });
  };

  const handleInitialItemsStatusChange = (value, itemId) => {
    const newItems = initialItems.map(item => {
      if (item.itemId === itemId) {
        return {
          ...item,
          disabled: !value,
        };
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  const handleInitialItemsBidChange = (value, itemId) => {
    const newItems = initialItems.map(item => {
      if (item.itemId === itemId) {
        return {
          ...item,
          maxCPC: value,
        };
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  useEffect(() => {
    const dailyCosts = [...categoryItems, ...initialItems].reduce((acc, item) => {
      acc += item.maxCPC || 0;
      return acc;
    }, 0);
    const totalCosts = dailyCosts * (moment(endDate).diff(startDate, 'days') || 1);

    setDailyCosts(dailyCosts);
    setTotalCosts(totalCosts);
  }, [initialItems, categoryItems, startDate, endDate]);

  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name)
      : !isNotEmpty(name) || !shopId || !isNotEmptyArray(categoryItems) || !startDate;
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, categoryItems, startDate]);

  return (
    <CategoryFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        itemUrlText,
        handleProductUrl,
        invalidItems,
        loading,
        nextDisabled,
        handleAdd,
        categoryItems,
        handleStatusChange,
        handleItemBidChange,
        handleRemoveItem,
        dailyCosts,
        totalCosts,
        minDate,
        minEndDate,
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
        initialItems,
        handleInitialItemsStatusChange,
        handleInitialItemsBidChange,
        isEdit,
      }}
    />
  );
};
