import React from 'react';
import { Button as AntButton } from 'antd';
import { classList } from 'utils/classList';
import './styles.scss';

// type - primary, ghost

export const Button = ({ iconOnly, className, children, ...props }) => (
  <AntButton className={classList(iconOnly && 'icon-button', className)} {...props}>
    {children}
  </AntButton>
);
