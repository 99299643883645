import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { settingsSelector } from 'store/settings';
import {
  campaignChartDataSelector,
  userProductsSelector,
  loadingSelector,
  accountSelector,
  changePageSizeAction,
  pageSizeSelector,
  statusSelector,
  changeStatusAction,
  typeSelector,
  changeTypeAction,
  setCampaignId,
} from '../redux';
import { userSelector } from 'pages/login/redux';
import { updateProduct, fetchDashboardDataProduct } from '../async';
import { ProductDashboardView } from './views';
import { filterTables } from '../helpers';

export const ProductDashboard = () => {
  let { accountId, campaignId } = useParams();
  const dispatch = useDispatch();

  const chartData = useSelector(state => campaignChartDataSelector(state, accountId, campaignId));

  const products = useSelector(state => userProductsSelector(state, accountId, campaignId));
  const [filteredProducts, setFilteredProducts] = useState([]);
  const status = useSelector(statusSelector);
  const type = useSelector(typeSelector);
  const pageSize = useSelector(pageSizeSelector);
  const loading = useSelector(loadingSelector);
  const user = useSelector(userSelector);
  const account = useSelector(accountSelector);
  const settings = useSelector(settingsSelector);

  useEffect(() => {
    dispatch(setCampaignId(campaignId));
  }, [campaignId]);

  const columnConfig = {
    type: 'product',
    readOnly: !(user.accountId == accountId || user.superuser),
    settings,
  };

  const [query, setQuery] = useState('');

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const handleStatusChange = value => {
    dispatch(changeStatusAction(value));
  };

  const handleTypeChange = value => {
    dispatch(changeTypeAction(value));
  };

  const handleUpdateCampaign = async product => {
    await dispatch(updateProduct(product));
  };

  const handleUpdateStatus = async ({ record, status }) => {
    const product = {
      ...record.raw,
      disabled: !status,
    };
    if (status === 'deleted') {
      product.disabled = true;
      product.status = 'deleted';
    }
    handleUpdateCampaign(product);
  };

  const handleUpdateMaxCPC = async ({ record, maxCPC }) => {
    const product = {
      ...record.raw,
      maxCPC,
    };
    handleUpdateCampaign(product);
  };

  const handlePageSizeChange = value => {
    dispatch(changePageSizeAction(value));
  };

  useEffect(() => {
    const newProducts = filterTables(products, query, type, status);
    setFilteredProducts(newProducts);
  }, [query, type, status, products]);

  useEffect(() => {
    dispatch(fetchDashboardDataProduct(user.accountId));
  }, []);

  return (
    <ProductDashboardView
      {...{
        accountId,
        chartData,
        columnConfig,
        products: filteredProducts,
        loading,
        query,
        status,
        type,
        pageSize,
        handleQueryChange,
        handleStatusChange,
        handleTypeChange,
        handleUpdateStatus,
        handleUpdateMaxCPC,
        handlePageSizeChange,
      }}
    />
  );
};
