import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { editBannerSteps } from 'components/ProgressBar';
import { accountSelector } from 'pages/dashboard/redux';
import {
  setStepAction,
  setStepsAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  stepSelector,
  stepsSelector,
  initialItemsSelector,
  setItemsAction,
} from '../../redux';
import { uploadBanner } from '../../async';
import { RetailBannerAddView } from './views';

export const RetailBannerAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const initialItems = useSelector(initialItemsSelector);
  const userAccount = useSelector(accountSelector);

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleNext = async () => {
    history.push(`/edit-campaign/${campaign.campaignId}/retail-banner-budget`);
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  const handleItemsChange = items => {
    dispatch(setItemsAction(items));
  };

  useEffect(() => {
    dispatch(setStepsAction(editBannerSteps));
    dispatch(setStepAction(1));
  }, []);

  return (
    <RetailBannerAddView
      {...{
        step,
        steps,
        campaign,
        initialItems,
        loading,
        handleBack,
        handleNext,
        handleChange,
        handleItemsChange,
        uploadBanner,
        userAccount,
      }}
    />
  );
};
