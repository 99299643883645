import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { CampaignDuration } from 'components/CampaignDuration';
import { CategoryItem } from 'components/CategoryItem';
import styles from './demo_category_form.module.scss';

export const DemoCategoryFormView = ({
  step,
  steps,
  handleBack,
  handleNext,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  itemUrlText,
  handleProductUrl,
  invalidItems,
  loading,
  nextDisabled,
  handleAdd,
  categoryItems,
  handleStatusChange,
  handleItemBidChange,
  handleRemoveItem,
  dailyCosts,
  totalCosts,
  minDate,
  minEndDate,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  initialItems,
  handleInitialItemsChange,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create_campaign" />}
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    {!!initialItems.length && (
      <FormGroup title={<FormattedMessage id="form_products" />}>
        <div>
          {initialItems.map(({ itemId, name, url, maxCPC, disabled }) => (
            <CategoryItem
              key={itemId}
              fixedPrice
              isEdit
              {...{
                itemId,
                name,
                url,
                maxCPC,
                status: !disabled,
                handleStatusChange: handleInitialItemsChange,
              }}
            />
          ))}
        </div>
      </FormGroup>
    )}
    <FormGroup
      title={<FormattedMessage id="form_add_product_url" />}
      subtitle={<FormattedMessage id="form_add_product_url_description" />}
      tooltip={<FormattedMessage id="form_add_product_url_tooltip" />}
    >
      <div className={styles.itemForm}>
        <div className={styles.itemInput}>
          <TextInput
            value={itemUrlText}
            onChange={handleProductUrl}
            label={<FormattedMessage id="form_add_product_url_placeholder" />}
            error={invalidItems.length}
          />
        </div>
        <Button
          type="primary"
          size="large"
          icon={<IconCreate />}
          disabled={!itemUrlText || !shopId}
          loading={loading}
          onClick={handleAdd}
        >
          <FormattedMessage id="btn_add" />
        </Button>
      </div>
      {!!invalidItems.length && (
        <div className={styles.invalidItems}>
          <div>
            <FormattedMessage id="form_product_urls_bad_url" />
          </div>
          {invalidItems && invalidItems.map(({ url }, idx) => <div key={`${idx}${url}`}>{url}</div>)}
        </div>
      )}
    </FormGroup>
    <div>
      {!!categoryItems.length &&
        categoryItems.map(({ itemId, name, url, maxCPC, status }) => (
          <CategoryItem
            key={itemId}
            fixedPrice
            {...{
              itemId,
              name,
              url,
              maxCPC,
              status,
              handleStatusChange,
              handleItemBidChange,
              handleRemoveItem,
            }}
          />
        ))}
    </div>
    {(!!categoryItems.length || !!initialItems.length) && (
      <FormGroup
        className={styles.costs}
        title={<FormattedMessage id="form_daily_costs" />}
        subtitle={<FormattedMessage id="form_daily_costs_description" />}
      >
        <div className={styles.costsSum}>€ {dailyCosts}</div>
      </FormGroup>
    )}
    {(!!categoryItems.length || !!initialItems.length) && !!endDate && (
      <FormGroup
        className={styles.costs}
        title={<FormattedMessage id="form_total_costs" />}
        subtitle={<FormattedMessage id="form_total_costs_description" />}
      >
        <div className={styles.costsSum}>€ {totalCosts}</div>
      </FormGroup>
    )}
    <FormGroup
      title={<FormattedMessage id="form_campaign_duration" />}
      subtitle={<FormattedMessage id="form_campaign_duration_description" />}
      tooltip={<FormattedMessage id="form_campaign_duration_tooltip" />}
    >
      <CampaignDuration
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        minEndDate={minEndDate}
        onStartChange={handleStartDateChange}
        onEndChange={handleEndDateChange}
      />
    </FormGroup>
  </FormContent>
);
