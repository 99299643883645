import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { CategoryItemView } from './views';

const BidOptions = [15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50].map(item => ({
  name: `€${item.toFixed(2)}`,
  value: item,
}));

export const CategoryItem = ({
  itemId,
  name,
  url,
  maxCPC,
  status,
  isCpm,
  isEdit,
  handleStatusChange,
  handleItemBidChange,
  handleRemoveItem,
  fixedPrice,
}) => {
  const [tooltipText, setTooltipText] = useState('Copy');

  const handleCopy = value => {
    copy(value);
    setTooltipText('Copied!');
    setTimeout(() => setTooltipText('Copy'), 1000);
  };

  return (
    <CategoryItemView
      {...{
        itemId,
        name,
        url,
        maxCPC,
        status,
        handleStatusChange,
        handleItemBidChange,
        handleRemoveItem,
        tooltipText,
        handleCopy,
        BidOptions,
        isCpm,
        isEdit,
        fixedPrice,
      }}
    />
  );
};
