import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { settingsSelector } from 'store/settings';
import { SponsoredBrandsBudgetFormView } from './views';

export const SponsoredBrandsBudgetForm = ({
  step,
  steps,
  campaign,
  loading,
  handleBack,
  handleNext,
  handleChange,
  isEdit,
}) => {
  const { dailyBudget, totalBudget, maxCPC, startDate, endDate } = campaign;
  const [minDate] = useState(moment().startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [totalBudgetEnabled, setTotalBudgetEnabled] = useState(!!totalBudget);
  const [totalError, setTotalError] = useState(null);
  const [nextDisabled, setNextDisabled] = useState(false);
  const settings = useSelector(settingsSelector);

  const handleDailyBudgetChange = value => {
    handleChange({ key: 'dailyBudget', value });
  };

  const handleTotalBudgetChange = value => {
    if (totalBudgetEnabled && dailyBudget > value) {
      setTotalError('form_total_budget_too_small_error');
    } else {
      setTotalError();
    }
    handleChange({ key: 'totalBudget', value });
  };

  const handleCPCChange = value => {
    handleChange({ key: 'maxCPC', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handleTotalBudgetToggle = value => {
    setTotalBudgetEnabled(value);
    if (!value) {
      handleTotalBudgetChange(0);
    }
  };

  useEffect(() => {
    setNextDisabled(
      (totalBudgetEnabled && !totalBudget) ||
        !dailyBudget ||
        !maxCPC ||
        !startDate
    );
  }, [totalBudgetEnabled, totalBudget, dailyBudget, maxCPC, startDate]);

  return (
    <SponsoredBrandsBudgetFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        dailyBudget,
        handleDailyBudgetChange,
        totalBudgetEnabled,
        totalBudget,
        handleTotalBudgetToggle,
        handleTotalBudgetChange,
        totalError,
        maxCPC,
        handleCPCChange,
        startDate,
        endDate,
        minDate,
        minEndDate,
        handleStartDateChange,
        handleEndDateChange,
        isEdit,
        settings,
      }}
    />
  );
};
