import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as HomeIcon } from 'assets/icons/IconHomePage.svg';
import { ReactComponent as HomeIconPigu } from 'assets/icons/IconHomePagePigu.svg';
import { ReactComponent as CategoryIcon } from 'assets/icons/IconCategoryPage.svg';
import { ReactComponent as NotinoCategoryIcon } from 'assets/icons/IconNotinoCategoryPage.svg';
import { ReactComponent as BannerIcon } from 'assets/icons/IconBannerPage.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/IconFacebookPage.svg';
import { ReactComponent as SponsoredBrands } from 'assets/icons/IconSponsoredBrands.svg';
import { Content } from '../shared';
import { Button } from 'components/Button';
import { Row } from 'components/Row';
import { Col } from 'components/Col';
import { CategoryCard } from 'components/CategoryCard';
import { Spinner } from 'components/Spinner';
import styles from './choose_campaign.module.scss';

export const ChooseCampaignView = ({ loading, settings, handleNext, selectedCategory, handleCategorySelect }) => (
  <Content
    title={<FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Link to="/">
          <Button type="ghost" size="large">
            <FormattedMessage id="btn_cancel" />
          </Button>
        </Link>
        <Button onClick={handleNext} size="large" disabled={!selectedCategory}>
          <FormattedMessage id="btn_continue" />
        </Button>
      </Fragment>
    }
  >
    {loading ? (
      <div className={styles.spinnerContainer}>
        <Spinner size="large" />
      </div>
    ) : (
      <Row className={styles.categoriesContainer}>
        {settings.has_product_campaigns && (
            <Col>
              <CategoryCard
                tag={<FormattedMessage id="type_choice_tag" />}
                title={<FormattedMessage id="type_choice_product_title" />}
                subtitle={<FormattedMessage id="type_choice_product_description" />}
                selected={selectedCategory === 'product'}
                onClick={() => handleCategorySelect('product')}
                icon={window.location.hostname === 'pigu.martailer.com' ? <HomeIconPigu /> : <HomeIcon />}
                isRecommended
              />
            </Col>
        )}
        {settings.has_category_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_tag" />}
              title={<FormattedMessage id="type_choice_cpm_category_title" />}
              subtitle={<FormattedMessage id="type_choice_cpm_category_description" />}
              selected={selectedCategory === 'cpm-category'}
              onClick={() => handleCategorySelect('cpm-category')}
              icon={<CategoryIcon />}
            />
          </Col>
        )}
        {settings.has_demo_product_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_tag" />}
              title={<FormattedMessage id="type_choice_product_title" />}
              subtitle={<FormattedMessage id="type_choice_product_description" />}
              selected={selectedCategory === 'demo-product'}
              onClick={() => handleCategorySelect('demo-product')}
              icon={<HomeIcon />}
            />
          </Col>
        )}
        {settings.has_demo_category_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_tag" />}
              title={<FormattedMessage id="type_choice_category_title" />}
              subtitle={<FormattedMessage id="type_choice_category_description" />}
              selected={selectedCategory === 'demo-category'}
              onClick={() => handleCategorySelect('demo-category')}
              icon={<CategoryIcon />}
            />
          </Col>
        )}
        {settings.has_vendor_product_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_tag" />}
              title={<FormattedMessage id="type_choice_vendor_product_title" />}
              subtitle={<FormattedMessage id="type_choice_vendor_product_description" />}
              selected={selectedCategory === 'vendor-product'}
              onClick={() => handleCategorySelect('vendor-product')}
              icon={<HomeIcon />}
            />
          </Col>
        )}
        {settings.has_retail_product_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_tag" />}
              title={<FormattedMessage id="type_choice_vendor_product_title" />}
              subtitle={<FormattedMessage id="type_choice_vendor_product_description" />}
              selected={selectedCategory === 'retail-product'}
              onClick={() => handleCategorySelect('retail-product')}
              icon={<HomeIcon />}
            />
          </Col>
        )}
        {settings.has_banner_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_banner_tag" />}
              title={<FormattedMessage id="type_choice_retail_product_title" />}
              selected={selectedCategory === 'banner'}
              onClick={() => handleCategorySelect('banner')}
              icon={<BannerIcon />}
            />
          </Col>
        )}
        {settings.has_retail_banner_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_vendor_banner_tag" />}
              title={<FormattedMessage id="type_choice_vendor_banner_title" />}
              subtitle={<FormattedMessage id="type_choice_vendor_banner_description" />}
              selected={selectedCategory === 'retail-banner'}
              onClick={() => handleCategorySelect('retail-banner')}
              icon={<BannerIcon />}
            />
          </Col>
        )}
        {settings.has_vendor_banner_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_vendor_banner_tag" />}
              title={<FormattedMessage id="type_choice_vendor_banner_title" />}
              subtitle={<FormattedMessage id="type_choice_vendor_banner_description" />}
              selected={selectedCategory === 'vendor-banner'}
              onClick={() => handleCategorySelect('vendor-banner')}
              icon={<BannerIcon />}
            />
          </Col>
        )}
        {settings.has_facebook_ads_campaigns && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_facebook_tag" />}
              title={<FormattedMessage id="type_choice_facebook_title" />}
              subtitle={<FormattedMessage id="type_choice_facebook_description" />}
              selected={selectedCategory === 'facebook-ads'}
              onClick={() => handleCategorySelect('facebook-ads')}
              icon={<FacebookIcon />}
            />
          </Col>
        )}
        {settings.has_product_campaigns_notino && (
            <Col>
              <CategoryCard
                tag={<FormattedMessage id="type_choice_tag_notino" />}
                title={<FormattedMessage id="type_choice_product_title_notino" />}
                subtitle={<FormattedMessage id="type_choice_product_description_notino" />}
                selected={selectedCategory === 'sponsored-product'}
                onClick={() => handleCategorySelect('sponsored-product')}
                icon={<NotinoCategoryIcon />}
                isRecommended
              />
            </Col>
        )}
        {settings.has_sponsored_brands_campaigns_notino && (
            <Col>
              <CategoryCard
                tag={<FormattedMessage id="type_choice_sponsored_brands_tag" />}
                title={<FormattedMessage id="type_choice_sponsored_brands_title" />}
                subtitle={<FormattedMessage id="type_choice_sponsored_brands_description" />}
                selected={selectedCategory === 'sponsored-brands'}
                onClick={() => handleCategorySelect('sponsored-brands')}
                icon={<SponsoredBrands />}
                isRecommended
              />
            </Col>
        )}
        {settings.has_banner_campaigns_notino && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_banner_tag_notino" />}
              title={<FormattedMessage id="type_choice_retail_product_title_notino" />}
              subtitle={<FormattedMessage id="type_choice_banner_description_notino" />}
              selected={selectedCategory === 'banner'}
              disabled // temp disable
              // onClick={() => handleCategorySelect('banner')}
              icon={<BannerIcon />}
            />
          </Col>
        )}
        {settings.has_facebook_ads_campaigns_notino && (
          <Col>
            <CategoryCard
              tag={<FormattedMessage id="type_choice_facebook_tag_notino" />}
              title={<FormattedMessage id="type_choice_facebook_title_notino" />}
              subtitle={<FormattedMessage id="type_choice_facebook_description_notino" />}
              selected={selectedCategory === 'facebook-ads'}
              disabled // temp disable
              // onClick={() => handleCategorySelect('facebook-ads')}
              icon={<FacebookIcon />}
            />
          </Col>
        )}
      </Row>
    )}
  </Content>
);
