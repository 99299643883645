import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validatePassword } from 'utils/validator';
import { userSelector } from 'pages/login/redux';
import {
  setChangePasswordAction,
  setChangePasswordErrorAction,
  changePasswordSelector,
  changePassword,
} from '../redux';
import { ChangePasswordFormView } from './views';

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(userSelector);
  const { password, newPassword, newPasswordRepeat, error, loading } = useSelector(changePasswordSelector);

  const handlePasswordChange = ({ target: { value } }) => {
    dispatch(setChangePasswordAction({ key: 'password', value }));
  };

  const handleNewPasswordChange = ({ target: { value } }) => {
    dispatch(setChangePasswordAction({ key: 'newPassword', value }));
  };

  const handlePasswordRepeatChange = ({ target: { value } }) => {
    dispatch(setChangePasswordAction({ key: 'newPasswordRepeat', value }));
  };

  const submitDisabled = !password || !newPassword || !newPasswordRepeat;

  const handleSubmit = async () => {
    const passwordIsValid = validatePassword(newPassword);
    const passwordRepeatIsValid = newPassword === newPasswordRepeat;

    dispatch(
      setChangePasswordErrorAction({
        key: 'newPassword',
        value: !passwordIsValid,
      })
    );
    dispatch(
      setChangePasswordErrorAction({
        key: 'newPasswordRepeat',
        value: !passwordRepeatIsValid,
      })
    );

    if (passwordIsValid && passwordRepeatIsValid) {
      const { payload } = await dispatch(changePassword({ user, password, newPassword }));
      if (payload) {
        history.push('/dashboard');
      }
    }
  };

  return (
    <ChangePasswordFormView
      {...{
        password,
        newPassword,
        newPasswordRepeat,
        handlePasswordChange,
        handleNewPasswordChange,
        handlePasswordRepeatChange,
        error,
        submitDisabled,
        handleSubmit,
        loading,
      }}
    />
  );
};
