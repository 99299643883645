import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'components/Spinner';
import {
  setInitialStateAction,
  setBannerZoneAction,
  bannerZoneSelector,
  bannerZoneByIdSelector,
  setInitialBannerZoneAction,
  setBannerZoneEditAction,
} from '../redux';
import { EditBannerZoneView } from './views';

export const EditBannerZone = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { zoneId } = useParams();
  const [loading, setLoading] = useState(true);
  const zone = useSelector(bannerZoneSelector);

  const initialZone = useSelector(state => bannerZoneByIdSelector(state, zoneId));

  const handleSubmit = () => {
    history.push('/zones');
    dispatch(setBannerZoneEditAction());
  };

  const handleChange = ({ key, value }) => {
    dispatch(setBannerZoneAction({ key, value }));
  };

  useEffect(() => {
    return () => dispatch(setInitialStateAction());
  }, []);

  useEffect(() => {
    if (initialZone) dispatch(setInitialBannerZoneAction(initialZone));
  }, [initialZone]);

  return zone ? (
    <EditBannerZoneView
      {...{
        zone,
        handleChange,
        handleSubmit,
      }}
    />
  ) : (
    <Spinner fullScreen size="large" />
  );
};
