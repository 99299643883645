import { createSlice, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { clone } from 'lodash';
import { logOut } from 'pages/login/redux';
import { fetchDashboardDataFast, fetchDashboardDataProduct, fetchDashboardDataCampaign, updateCampaign, updateProduct } from './async';

const makeLookup = arr =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

const sumChartData = data => {
  const chartData = [];
  const chartDataByDate = {};

  data.forEach(itemData => {
    let d = chartDataByDate[itemData.date];
    if (!d) {
      d = {
        date: itemData.date,
        clicks: 0,
        impressions: 0,
      };
      chartDataByDate[itemData.date] = d;
      chartData.push(d);
    }

    d.clicks += itemData.clicks;
    d.impressions += itemData.impressions;
  });
  chartData.sort((a, b) => a.date.localeCompare(b.date));
  return chartData;
};

const constructItemData = adsets_lookup => ([
  day,
  adsetId,
  itemId,
  impressions,
  clicks,
  sp_quantity,
  total_quantity,
  sp_revenue,
  total_revenue,
  cost,
]) => {
  const adset = adsets_lookup[adsetId] || {};
  return {
    'date': day,
    'accountId': adset.accountId,
    'campaignId': adset.campaignId,
    'adsetId': adsetId,
    'itemId': itemId,
    'clicks': Number(clicks) || 0,
    'impressions': Number(impressions) || 0,
    'quantity': Number(sp_quantity) || 0,
    'revenue': Number(sp_revenue) || 0,
    'totalQuantity': Number(total_quantity) || 0,
    'totalRevenue': Number(total_revenue) || 0,
    'cost': Number(cost) || 0,
  };
};

const filterRichData = (data = [], startDate, endDate) => {
  return data.filter(item => startDate <= item.date && item.date <= endDate);
};

const sumData = (richData=[], accounts=[], campaigns=[], aditems=[]) => {
  // group data into table rows
  const sumKeys = ['clicks', 'impressions', 'cost', 'quantity', 'revenue', 'totalQuantity', 'totalRevenue'];

  accounts = accounts.map(clone);
  const accounts_lookup = makeLookup(accounts);

  campaigns = campaigns.map(clone);
  const campaigns_lookup = makeLookup(campaigns);

  //const adsets = adsets.map(clone);
  //const adsets_lookup = makeLookup(adsets); // not used

  aditems = aditems.map(clone);
  const aditems_lookup = makeLookup(aditems);

  sumKeys.forEach(sumKey => {
    accounts.forEach(x => (x[sumKey] = 0));
    campaigns.forEach(x => (x[sumKey] = 0));
    aditems.forEach(x => (x[sumKey] = 0));
  });

  richData.forEach(itemData => {
    let a = accounts_lookup[itemData.accountId];
    let c = campaigns_lookup[itemData.campaignId];
    let i = aditems_lookup[itemData.itemId + '-' + itemData.adsetId];

    sumKeys.forEach(sumKey => {
      if (a) a[sumKey] += itemData[sumKey];
      if (c) c[sumKey] += itemData[sumKey];
      if (i) i[sumKey] += itemData[sumKey];
    });
  });

  // fake costs for pricingModel:fixed
  campaigns.forEach(c => {
    if (c.pricingModel == "fixed") {
      c.cost = c.pricingModelPrice || 0;
      let a = accounts_lookup[c.accountId];
      a.cost += c.pricingModelPrice || 0;
    }
  })

  return { sumAccounts: accounts, sumCampaigns: campaigns, sumAditems: aditems };
};

const linkData = ({
  data: { accounts: accountsProp, adsets: adsetsProp, aditems: aditemsProp, campaigns: campaignsProp, data: dataProp },
  accountId,
}) => {
  const accounts = accountsProp.map(item => ({
    raw: clone(item),
    ...item,
    accountId: item.id,
  }));
  const accounts_lookup = makeLookup(accounts);

  const campaigns = campaignsProp.map(item => ({
    raw: clone(item),
    ...item,
    campaignId: item.id,
  }));
  const campaigns_lookup = makeLookup(campaigns);

  const adsets = adsetsProp.map(item => {
    const campaign = campaigns_lookup[item.campaignId];
    return {
      raw: clone(item),
      ...item,
      adsetId: item.id,
      accountId: campaign.accountId,
    };
  });
  const adsets_lookup = makeLookup(adsets);

  const aditems = aditemsProp.map(item => {
    const adset = adsets_lookup[item.adsetId];
    const campaign = campaigns_lookup[adset.campaignId];
    return {
      raw: clone(item),
      ...item,
      id: item.itemId + '-' + item.adsetId,
      campaignId: adset.campaignId,
      accountId: adset.accountId,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      category: campaign.category,
      banner: campaign.banner,
      dailyBudget: campaign.dailyBudget,
      maxCPC: item.maxCPC || campaign.maxCPC,
      campaignType: campaign.campaignType,
      ...(['banner', 'retail-banner', 'vendor-banner'].includes(campaign.campaignType) && {
        bannersUrl: campaign.bannersUrl,
        pricingModel: campaign.pricingModel,
        pricingModelPrice: campaign.pricingModelPrice,
      }),
      shopId: campaign.shopId,
    };
  });

  const userAccount = accounts_lookup[accountId];

  const richData = dataProp.map(constructItemData(adsets_lookup));

  return {
    accounts: accounts,
    userAccount: userAccount,
    richData: richData,
    campaigns: campaigns,
    adsets: adsets,
    aditems: aditems,
  };
};

const constructData = (linkedDump, startDate, endDate) => {
  const { richData, campaigns, aditems, adsets, userAccount, accounts } = linkedDump ?? {};
  const chartData = filterRichData(richData, startDate, endDate);
  const { sumCampaigns, sumAditems, sumAccounts } = sumData(chartData, accounts, campaigns, aditems);

  return {
    sumAccounts,
    sumCampaigns,
    sumAditems,
    chartData,
    adsets,
    userAccount,
  };
};

const initialState = {
  userAccount: null,
  chartData: [],
  campaignChartData: [],
  loading: true,
  dashboard: null,
  accounts: [],
  aditems: [],
  adsets: [],
  campaigns: [],
  products: [],
  data: [],
  richData: [],
  startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  pageSize: 10,
  status: 'enabled_paused',
  type: null,
  campaignId: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    selectDateRangeAction: (state, action) => {
      const startDate = moment(action.payload[0]).format('YYYY-MM-DD');
      const endDate = moment(action.payload[1]).format('YYYY-MM-DD');
      state.startDate = startDate;
      state.endDate = endDate;

      // settings dates for future use and avoiding calling contructData when not needed
      if (action.payload[2]) {
        return;
      }

      const { sumAccounts, sumCampaigns, sumAditems, chartData, userAccount } = constructData(
        window.linkedDump,
        startDate,
        endDate
      );

      state.accounts = sumAccounts;
      state.campaigns = sumCampaigns;
      state.products = sumAditems;
      state.userAccount = userAccount;
      state.chartData = chartData;
      state.campaignChartData = chartData;
    },
    changePageSizeAction: (state, action) => {
      state.pageSize = action.payload;
    },
    changeStatusAction: (state, action) => {
      state.status = action.payload;
    },
    changeTypeAction: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: {
    [fetchDashboardDataFast.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDashboardDataFast.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchDashboardDataFast.fulfilled]: (state, action) => {
      const dump0 = window.dump,
        linkedDump0 = window.linkedDump;

      window.dump = action.payload;
      window.linkedDump = linkData(action.payload);

      if (dump0) window.dump.data = dump0.data;
      if (linkedDump0) window.linkedDump.richData = linkedDump0.richData;

      const { sumAccounts, sumCampaigns, sumAditems, chartData, userAccount, adsets } = constructData(
        window.linkedDump,
        state.startDate,
        state.endDate
      );
      state.accounts = sumAccounts;
      state.campaigns = sumCampaigns;
      state.products = sumAditems;
      state.userAccount = userAccount;
      state.chartData = chartData;

      state.adsets = adsets;
      state.loading = false;
    },

    [fetchDashboardDataProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDashboardDataProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchDashboardDataProduct.fulfilled]: (state, action) => {
      const { sumAditems, chartData, userAccount, adsets } = constructData(
        linkData(action.payload),
        state.startDate,
        state.endDate
      );

      state.products = sumAditems;
      state.userAccount = userAccount;
      state.adsets = adsets;
      state.campaignChartData = chartData;
      state.loading = false;
    },


    [fetchDashboardDataCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDashboardDataCampaign.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchDashboardDataCampaign.fulfilled]: (state, action) => {
      window.dump = action.payload;
      window.linkedDump = linkData(action.payload);

      const { sumAccounts, sumCampaigns, chartData, userAccount, adsets } = constructData(
        window.linkedDump,
        state.startDate,
        state.endDate
      );
      state.accounts = sumAccounts;
      state.campaigns = sumCampaigns;
      state.userAccount = userAccount;
      state.chartData = chartData;

      state.adsets = adsets;
      state.loading = false;
    },

    [updateCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCampaign.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateCampaign.fulfilled]: (state, action) => {
      const newCampaign = action.payload.campaigns[0];

      state.campaigns = state.campaigns.map(campaign => {
        if (campaign.id === newCampaign.id) {
          return {
            ...campaign,
            ...newCampaign,
          };
        }
        return campaign;
      });
      state.loading = false;
    },
    [updateProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [updateProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateProduct.fulfilled]: (state, action) => {
      const newProduct = action.payload.aditems[0];

      state.products = state.products.map(product => {
        if (product.id === newProduct.itemId + '-' + newProduct.adsetId) {
          return {
            ...product,
            ...newProduct,
          };
        }
        return product;
      });
      state.loading = false;
    },
    [logOut.fulfilled]: (state, action) => initialState,
  },
});

export const { setCampaignId, selectDateRangeAction, changePageSizeAction, changeStatusAction, changeTypeAction } = dashboardSlice.actions;

export const loadingSelector = state => state.dashboard.loading;
export const dateRangeSelector = state => [state.dashboard.startDate, state.dashboard.endDate];
export const pageSizeSelector = state => state.dashboard.pageSize;
export const statusSelector = state => state.dashboard.status;
export const typeSelector = state => state.dashboard.type;

export const accountsSelector = state => state.dashboard.accounts;
export const accountSelector = state => state.dashboard.userAccount;
const adsetsSelector = state => state.dashboard.adsets;
const campaignsSelector = state => state.dashboard.campaigns;
const productsSelector = state => state.dashboard.products;
const accountIdSelector = (state, accountId) => accountId;
const campaignIdSelector = (state, accountId, campaignId) => campaignId;
const adsetIdSelector = (state, adsetId) => adsetId;

export const campaignSelector = createSelector([campaignsSelector, campaignIdSelector], (items, campaignId) => {
  return items.find(item => item.campaignId === Number(campaignId));
});

export const adsetSelector = createSelector([adsetsSelector, campaignIdSelector], (items, campaignId) => {
  return items.find(item => item.campaignId === Number(campaignId));
});

export const adsetItemsSelector = createSelector([productsSelector, adsetIdSelector], (items, adsetId) => {
  return items.filter(item => item.adsetId === Number(adsetId));
});

export const userCampaignsSelector = createSelector([campaignsSelector, accountIdSelector], (items, accountId) => {
  return items.filter(campaign => campaign.accountId === Number(accountId));
});

export const userProductsSelector = createSelector(
  [productsSelector, accountIdSelector, campaignIdSelector],
  (items, accountId, campaignId) => {
    const userProducts = items.filter(product => product.accountId === Number(accountId));

    if (campaignId && campaignId !== 'all-products') {
      return userProducts.filter(product => product.campaignId === Number(campaignId));
    }

    return userProducts;
  }
);

const chartDataSelector = state => state.dashboard.chartData;
const campaignChartDataBaseSelector = state => state.dashboard.campaignChartData;

export const adminChartDataSelector = createSelector([chartDataSelector], items => {
  const sumData = sumChartData(items);
  return sumData;
});

export const accountChartDataSelector = createSelector([chartDataSelector, accountIdSelector], (items, accountId) => {
  const data = items.filter(item => item.accountId === Number(accountId));
  const sumData = sumChartData(data);
  return sumData;
});

export const campaignChartDataSelector = createSelector(
  [campaignChartDataBaseSelector, accountIdSelector, campaignIdSelector],
  (items, accountId, campaignId) => {
    let data = items.filter(product => product.accountId === Number(accountId));

    if (campaignId && campaignId !== 'all-products') {
      data = data.filter(item => item.campaignId === Number(campaignId));
    }

    const sumData = sumChartData(data);
    return sumData;
  }
);

export const accountByIdSelector = createSelector([accountsSelector, accountIdSelector], (items, accountId) => {
  let account = items.find(item => item.accountId === Number(accountId));
  return account;
});

export default dashboardSlice.reducer;
