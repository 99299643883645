import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSelector } from 'pages/login/redux';
import {
  adminChartDataSelector,
  accountsSelector,
  accountSelector,
  loadingSelector,
  changePageSizeAction,
  pageSizeSelector,
} from '../redux';
import { fetchDashboardDataCampaign } from '../async';
import { AdminDashboardView } from './views';
import { settingsSelector } from 'store/settings';

export const AdminDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const chartData = useSelector(adminChartDataSelector);
  const accounts = useSelector(accountsSelector);
  const account = useSelector(accountSelector);
  const user = useSelector(userSelector);
  const pageSize = useSelector(pageSizeSelector);
  const loading = useSelector(loadingSelector);
  const settings = useSelector(settingsSelector);

  const columnConfig = {
    type: 'account',
    settings,
  };

  const [filteredAccounts, setFilteredAccounts] = useState('');
  const [query, setQuery] = useState('');

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const filterTables = arr => {
    if (arr && arr.length) {
      return arr.filter(o => {
        return o.name && o.name.toLowerCase().includes(query.toLowerCase());
      });
    }
  };

  const handlePageSizeChange = value => {
    dispatch(changePageSizeAction(value));
  };

  useEffect(() => {
    if (account && !account.retailer) {
      history.replace(`/dashboard/${account.accountId}`);
    }
  }, [account]);

  useEffect(() => {
    dispatch(fetchDashboardDataCampaign(user.accountId));
  }, []);

  useEffect(() => {
    const newCampaigns = filterTables(accounts);
    setFilteredAccounts(newCampaigns);
  }, [query, accounts]);

  return (
    <AdminDashboardView
      {...{
        chartData,
        columnConfig,
        accounts: filteredAccounts,
        handleQueryChange,
        pageSize,
        handlePageSizeChange,
        loading,
      }}
    />
  );
};
