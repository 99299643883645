import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProductAdd } from './product/add';
import { ProductBudget } from './product/budget';
import { Category } from './category';
import { DemoProductAdd } from './demo_product/add';
import { DemoProductBudget } from './demo_product/budget';
import { DemoCategory } from './demo_category';
import { VendorProductAdd } from './vendor_product/add';
import { VendorProductBudget } from './vendor_product/budget';
import { RetailProductAdd } from './retail_product/add';
import { RetailProductBudget } from './retail_product/budget';
import { BannerAdd } from './banner/add';
import { BannerBudget } from './banner/budget';
import { RetailBannerAdd } from './retail-banner/add';
import { RetailBannerBudget } from './retail-banner/budget';
import { VendorBannerAdd } from './vendor-banner/add';
import { VendorBannerBudget } from './vendor-banner/budget';
import { FacebookAds } from './facebook_ads';
import { SponsoredBrandsAdd } from './sponsored_brands/add';
import { SponsoredBrandsBudget } from './sponsored_brands/budget';
import Success from './success';

export const EditCampaignRouter = () => (
  <Switch>
    <Route exact path="/edit-campaign/:campaignId/product" component={ProductAdd} />
    <Route exact path="/edit-campaign/:campaignId/product-budget" component={ProductBudget} />
    <Route exact path="/edit-campaign/:campaignId/sponsored-product" component={ProductAdd} />
    <Route exact path="/edit-campaign/:campaignId/sponsored-product-budget" component={ProductBudget} />
    <Route exact path="/edit-campaign/:campaignId/cpm-category" component={Category} />
    <Route exact path="/edit-campaign/:campaignId/demo-product" component={DemoProductAdd} />
    <Route exact path="/edit-campaign/:campaignId/demo-product-budget" component={DemoProductBudget} />
    <Route exact path="/edit-campaign/:campaignId/demo-category" component={DemoCategory} />
    <Route exact path="/edit-campaign/:campaignId/vendor-product" component={VendorProductAdd} />
    <Route exact path="/edit-campaign/:campaignId/vendor-product-budget" component={VendorProductBudget} />
    <Route exact path="/edit-campaign/:campaignId/retail-product" component={RetailProductAdd} />
    <Route exact path="/edit-campaign/:campaignId/retail-product-budget" component={RetailProductBudget} />
    <Route exact path="/edit-campaign/:campaignId/banner" component={BannerAdd} />
    <Route exact path="/edit-campaign/:campaignId/banner-budget" component={BannerBudget} />
    <Route exact path="/edit-campaign/:campaignId/retail-banner" component={RetailBannerAdd} />
    <Route exact path="/edit-campaign/:campaignId/retail-banner-budget" component={RetailBannerBudget} />
    <Route exact path="/edit-campaign/:campaignId/vendor-banner" component={VendorBannerAdd} />
    <Route exact path="/edit-campaign/:campaignId/vendor-banner-budget" component={VendorBannerBudget} />
    <Route exact path="/edit-campaign/:campaignId/sponsored-brands" component={SponsoredBrandsAdd} />
    <Route exact path="/edit-campaign/:campaignId/sponsored-brands-budget" component={SponsoredBrandsBudget} />
    <Route exact path="/edit-campaign/:campaignId/facebook-ads" component={FacebookAds} />
    <Route exact path="/edit-campaign/:campaignId/success" component={Success} />
  </Switch>
);
