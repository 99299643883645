/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import styles from './ProductCollectionSelector.module.scss';

export const ProductCollectionSelectorView = ({
    productCount,
    allProductSelected,
    handleAllSelection,
    handleManualSelection,
  }) => (
  <div>
    <div className={styles.container}>
      <div className={classList(styles.itemContainer, allProductSelected ? styles.selectedContainer : styles.unselectedContainer)} onClick={handleAllSelection}>
        <div className={styles.header}>
          <FormattedMessage id="form_products_advertise_all" />
        </div>
        <div className={styles.body}>
          <FormattedMessage id="form_products_advertise_all_description" />
        </div>
      </div>

      <div className={classList(styles.itemContainer, !allProductSelected ? styles.selectedContainer : styles.unselectedContainer)} onClick={handleManualSelection}>
        <div className={styles.header}>
          <FormattedMessage id="form_products_advertise_manually" />
        </div>
        <div className={styles.body}>
          <FormattedMessage id="form_products_advertise_manually_description" />
        </div>
      </div>
    </div>
    {
      allProductSelected ?
      (<div className={styles.productCount}>
        <div className={styles.productCountHeader}><FormattedMessage id="form_products" />:</div>
        <div className={styles.productCountNumber}>{productCount}</div>
      </div>) : null
    }
  </div>
);
