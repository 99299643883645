/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconTrash } from 'assets/icons/IconTrash.svg';
import { ReactComponent as IconEnlarge } from 'assets/icons/IconEnlarge.svg';
import { Button } from 'components/Button';
import { FormGroup } from 'components/FormGroup';
import { Dialog } from 'components/Dialog';
import { Placement } from 'components/Placement';
import { DeliveryRules } from 'components/DeliveryRules';
import { BannerPreview as Preview } from 'components/BannerPreview';
import { TextInput } from 'components/TextInput';
import { TextArea } from 'components/TextArea';
import styles from './BannerItem.module.scss';

export const RemoveBanner = ({ open, onClose, onConfirm }) => (
  <Dialog title={<FormattedMessage id="form_remove_banner" />} open={open} onClose={onClose}>
    <div className={styles.dialogContainer}>
      <div className={styles.dialogContent}>
        <IconTrash className={styles.icon} />
        <div className={styles.title}>
          <FormattedMessage id="form_remove_confirm_title" />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage id="form_remove_confirm_description" />
        </div>
      </div>
      <div className={styles.actions}>
        <Button type="ghost" size="large" onClick={onClose}>
          <FormattedMessage id="btn_cancel" />
        </Button>
        <Button size="large" onClick={onConfirm}>
          <FormattedMessage id="btn_remove" />
        </Button>
      </div>
    </div>
  </Dialog>
);

const RemoveBannerDialog = props => createPortal(<RemoveBanner {...props} />, document.getElementById('root'));

const BannerPreview = props => createPortal(<Preview {...props} />, document.getElementById('root'));

export const BannerItemView = ({
  name,
  url,
  parentLandingUrl,
  dimensions,
  size,
  showPreview,
  removeDialogOpen,
  handleLoad,
  handleShowPreview,
  handleRemoveDialogOpen,
  handleConfirm,
  isEdit,
  allCategories,
  handleSelectCategory,
  handleSelectAllCategories,
  deliveryRules,
  handleDeliveryRulesChange,
  handleAddDeliveryRule,
  handleRemoveDeliveryRule,
  landingUrl,
  enableUrlOverride,
  handleEnableUrlOverride,
  handleUrlOverride,
  urlOverrideError,
  keywords,
  handleKeywordsChange,
  hasSearch,
  hasDeliveryRules,
  hasTargetCategories,
}) => (
  <Fragment>
    <div className={classList(styles.bannerItem, isEdit && styles.edit)}>
      <div className={styles.innerBannerContainer}>
        <div className={styles.labelContainer}>
          <FormGroup title={<FormattedMessage id="form_banner_name" />} className={styles.productLinkForm}>
            <div className={styles.label}>{name}</div>
          </FormGroup>
          <FormGroup title={<FormattedMessage id="form_banner_size" />} className={styles.productLinkForm}>
            <div className={styles.label}>{`${dimensions.width}x${dimensions.height}`}</div>
          </FormGroup>
        </div>
        <div className={styles.previewContainer} onClick={() => handleShowPreview(true)}>
          <img className={styles.preview} src={url} onLoad={handleLoad} alt="preview" />
          <div className={styles.previewEnlarge}>
            <IconEnlarge className={styles.icon} />
          </div>
        </div>
        <Button
          type="ghost"
          iconOnly
          className={styles.remove}
          icon={<IconTrash />}
          onClick={() => handleRemoveDialogOpen(true)}
        />
      </div>
      <div>
        <FormGroup
          style={{ marginTop: '16px' }}
          title={<FormattedMessage id="form_banner_placement" />}
          subtitle={<FormattedMessage id="form_banner_placement_description" />}
        />
        <Placement
          items={allCategories}
          handleSelect={handleSelectCategory}
          handleSelectAll={handleSelectAllCategories}
          isEdit={isEdit}
          small
        />
      </div>
      {hasDeliveryRules && (
        <FormGroup
          title={<FormattedMessage id="form_delivery_rules" />}
          subtitle={<FormattedMessage id="form_delivery_rules_description" />}
        >
          <DeliveryRules
            rules={deliveryRules}
            onChange={handleDeliveryRulesChange}
            onAdd={handleAddDeliveryRule}
            onRemove={handleRemoveDeliveryRule}
          />
        </FormGroup>
      )}

      {hasTargetCategories && !hasDeliveryRules && (
        <FormGroup
          title={<FormattedMessage id="form_target_categories" />}
          subtitle={<FormattedMessage id="form_target_categories_description" />}
          tooltip={<FormattedMessage id="form_target_categories_tooltip" />}
        >
          <DeliveryRules
            rules={deliveryRules}
            onChange={handleDeliveryRulesChange}
            onAdd={handleAddDeliveryRule}
            onRemove={handleRemoveDeliveryRule}
          />
        </FormGroup>
      )}

      {hasSearch && (
        <FormGroup
          title={<FormattedMessage id="form_target_keywords" />}
          subtitle={<FormattedMessage id="form_target_keywords_description" />}
          tooltip={<FormattedMessage id="form_target_keywords_tooltip" />}
        >
          <TextArea
            rows={6}
            value={keywords}
            onChange={handleKeywordsChange}
            label={<FormattedMessage id="form_target_keywords_placeholder" />}
          />
        </FormGroup>
      )}

      {enableUrlOverride || (isEdit && landingUrl) ? (
        <FormGroup
          style={{ marginBottom: '0px' }}
          title={<FormattedMessage id="form_landing_page_url_edit" />}
          subtitle={<FormattedMessage id="form_add_banners_placeholder" />}
        >
          <div className={styles.urlOverride}>
            <TextInput
              value={landingUrl}
              onChange={handleUrlOverride}
              label={<FormattedMessage id="form_add_banners_placeholder" />}
              error={urlOverrideError}
            />
            <Button
              type="ghost"
              iconOnly
              className={styles.urlOverrideToggle}
              icon={<IconTrash />}
              onClick={() => handleEnableUrlOverride(false)}
            />
          </div>
        </FormGroup>
      ) : (
        <a onClick={() => handleEnableUrlOverride(true)}>
          <FormattedMessage id="form_landing_page_url_edit" />
        </a>
      )}
    </div>
    {showPreview && (
      <BannerPreview
        name={name}
        url={url}
        landingUrl={enableUrlOverride ? landingUrl : parentLandingUrl}
        dimensions={dimensions}
        size={size}
        onClose={() => handleShowPreview(false)}
      />
    )}
    <RemoveBannerDialog
      open={removeDialogOpen}
      onClose={() => handleRemoveDialogOpen(false)}
      onConfirm={handleConfirm}
    />
  </Fragment>
);
