import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { accountSelector } from 'pages/dashboard/redux';
import {
  setStepAction,
  campaignSelector,
  setCampaignAction,
  loadingSelector,
  stepSelector,
  stepsSelector,
} from '../../redux';
import { shopMap } from '../../shared';
import { uploadBanner } from '../../async';
import { VendorBannerAddView } from './views';

export const VendorBannerAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(loadingSelector);
  const step = useSelector(stepSelector);
  const steps = useSelector(stepsSelector);
  const campaign = useSelector(campaignSelector);
  const userAccount = useSelector(accountSelector);
  const shopOptions = userAccount ? userAccount.shopIds.map(id => ({ name: shopMap[id] || id, value: id })) : [];
  const [shopPreselected, setShopPreselected] = useState(false);


  const handleBack = () => {
    history.push('/new-campaign');
  };

  const handleNext = () => {
    history.push('/new-campaign/vendor-banner-budget');
  };

  const handleChange = ({ key, value }) => {
    dispatch(setCampaignAction({ key, value }));
  };

  useEffect(() => {
    dispatch(setStepAction(2));
    const isRadiopopular = userAccount.shopIds.includes('radiopopularpt');
    if (isRadiopopular) {
      dispatch(setCampaignAction({key: 'shopId', value: 'radiopopularpt'}));
      setShopPreselected(true);
    }
  }, []);

  return (
    <VendorBannerAddView
      {...{
        step,
        steps,
        campaign,
        shopOptions,
        loading,
        handleBack,
        handleNext,
        handleChange,
        uploadBanner,
        userAccount,
        shopPreselected,
      }}
    />
  );
};
