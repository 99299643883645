import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConditionalRoute } from 'components/ConditionalRoute';
import { ChooseCampaign } from './choose_campaign';
import { ProductAdd } from './product/add';
import { ProductBudget } from './product/budget';
import { Category } from './category';
import { BannerAdd } from './banner/add';
import { BannerBudget } from './banner/budget';
import { RetailBannerAdd } from './retail_banner/add';
import { RetailBannerBudget } from './retail_banner/budget';
import { VendorBannerAdd } from './vendor_banner/add';
import { VendorBannerBudget } from './vendor_banner/budget';
import { RetailProductAdd } from './retail_product/add';
import { RetailProductBudget } from './retail_product/budget';
import { VendorProductAdd } from './vendor_product/add';
import { VendorProductBudget } from './vendor_product/budget';
import { DemoProductAdd } from './demo_product/add';
import { DemoProductBudget } from './demo_product/budget';
import { DemoCategory } from './demo_category';
import { FacebookAds } from './facebook_ads';
import { SponsoredBrandsAdd } from './sponsored_brands/add';
import { SponsoredBrandsBudget } from './sponsored_brands/budget';
import Success from './success';
import { selectedCategorySelector, campaignSelector, createdCampaignSelector } from './redux';

export const NewCampaignRouter = () => {
  const selectedCategory = useSelector(selectedCategorySelector);
  const createdCampaign = useSelector(createdCampaignSelector);
  const { name, shopId, items } = useSelector(campaignSelector);

  return (
    <Switch>
      <Route exact path="/new-campaign" component={ChooseCampaign} />
      <ConditionalRoute
        exact
        path="/new-campaign/cpm-category"
        component={Category}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/product"
        component={ProductAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/product-budget"
        component={ProductBudget}
        condition={selectedCategory && name && shopId}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/sponsored-product"
        component={ProductAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/sponsored-product-budget"
        component={ProductBudget}
        condition={selectedCategory && name && shopId}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/sponsored-brands"
        component={SponsoredBrandsAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/sponsored-brands-budget"
        component={SponsoredBrandsBudget}
        condition={selectedCategory && name && shopId}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/banner"
        component={BannerAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/banner-budget"
        component={BannerBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/retail-banner"
        component={RetailBannerAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/retail-banner-budget"
        component={RetailBannerBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/vendor-banner"
        component={VendorBannerAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/vendor-banner-budget"
        component={VendorBannerBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/retail-product"
        component={RetailProductAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/retail-product-budget"
        component={RetailProductBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/vendor-product"
        component={VendorProductAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/vendor-product-budget"
        component={VendorProductBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/demo-product"
        component={DemoProductAdd}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/demo-product-budget"
        component={DemoProductBudget}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/demo-category"
        component={DemoCategory}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/facebook-ads"
        component={FacebookAds}
        condition={selectedCategory}
        redirectTo="/new-campaign"
      />
      <ConditionalRoute
        exact
        path="/new-campaign/success"
        component={Success}
        condition={createdCampaign.name}
        redirectTo="/new-campaign"
      />
      <Redirect to="/new-campaign" />
    </Switch>
  );
};
