import React, { useEffect } from 'react';
import { DialogView } from './views';

const setBodyScroll = shouldScroll => {
  document.querySelector('body').style.overflow = shouldScroll ? 'auto' : 'hidden';
};

export const Dialog = ({ open, ...props }) => {
  useEffect(() => {
    setBodyScroll(!open);
    return () => {
      setBodyScroll(true);
    };
  }, [open]);

  return <DialogView open={open} {...props} />;
};
