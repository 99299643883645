import React, { useEffect, useState } from 'react';
import { ProductPickerView } from './views';

export const ProductPicker = ({
  items = [],
  invalidItems = [],
  handleSelect,
  handleRemoveAll,
  handleAdd,
  noSearch,
  isEdit,
  searchPlaceholder,
  searchDisabled,
  onQueryChange,
  itemUrlText,
  handleItemsUrlChange,
  ...props
}) => {
  const [allItems, setAllItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState('');
  const [expanded, setExpanded] = useState(!isEdit);
  const [method, setMethod] = useState('search');

  const handleItemSelect = id => {
    handleSelect(id, true);
  };

  const handleItemRemove = id => {
    handleSelect(id, false);
  };

  const handleSelectAll = () => {
    handleSelect(null, null, true);
  };

  const handleClearAll = () => {
    handleRemoveAll();
  };

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
    onQueryChange(value);
  };

  const handleShowMore = () => {
    onQueryChange(query, 200);
  }

  const handleExpand = value => {
    setExpanded(value);
  };

  const handleMethodChange = value => {
    handleRemoveAll();
    setMethod(value);
  };

  useEffect(() => {
    setAllItems(items);
    setSelectedItems(items.filter(item => item.selected));
  }, [items]);

  return (
    <ProductPickerView
      {...{
        allItems: allItems,
        selectedItems,
        invalidItems,
        query,
        handleItemSelect,
        handleItemRemove,
        handleSelectAll,
        handleClearAll,
        handleQueryChange,
        itemUrlText,
        handleItemsUrlChange,
        noSearch,
        expanded,
        searchDisabled,
        handleExpand,
        searchPlaceholder,
        method,
        handleMethodChange,
        handleAdd,
        handleShowMore,
      }}
      {...props}
    />
  );
};
