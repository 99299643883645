import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from 'utils/validator';
import { loginSelector, setLoginAction, setLoginErrorAction, resetPassword } from 'pages/login/redux';
import { ResetPasswordView } from './views';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const { newPassword, newPasswordRepeat, loading, error } = useSelector(loginSelector);

  const handlePasswordChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'newPassword', value: value }));
  };
  const handlePasswordRepeatChange = ({ target: { value } }) => {
    dispatch(setLoginAction({ key: 'newPasswordRepeat', value: value }));
  };

  const submitDisabled = !newPassword || !newPasswordRepeat;

  const handleSubmit = () => {
    const passwordIsValid = validatePassword(newPassword);
    const passwordRepeatIsValid = newPassword === newPasswordRepeat;

    dispatch(setLoginErrorAction({ key: 'newPassword', value: !passwordIsValid }));
    dispatch(setLoginErrorAction({ key: 'newPasswordRepeat', value: !passwordRepeatIsValid }));

    if (passwordIsValid && passwordRepeatIsValid) {
      dispatch(resetPassword(newPassword));
    }
  };

  return (
    <ResetPasswordView
      {...{
        newPassword,
        newPasswordRepeat,
        handlePasswordChange,
        handlePasswordRepeatChange,
        submitDisabled,
        handleSubmit,
        loading,
        error,
      }}
    />
  );
};
