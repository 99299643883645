import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const categories = [
  { id: '1', title: 'Home page main', selected: false },
  { id: '2', title: 'Home page zone A', selected: false },
  { id: '3', title: 'Home page zone B', selected: false },
  { id: '4', title: 'Home page zone C', selected: false },
  { id: '5', title: 'Home carousel 1', selected: false },
  { id: '6', title: 'Home carousel 2', selected: false },
  { id: '7', title: 'Home carousel 3', selected: false },
  { id: '8', title: 'Home carousel 4', selected: false },
  { id: '9', title: 'Home carousel 5', selected: false },
  { id: '10', title: 'Category page level 1', selected: false },
  { id: '11', title: 'Category page level 2', selected: false },
  { id: '12', title: 'Category page level 3', selected: false },
  { id: '13', title: 'Category page level 4', selected: false },
  { id: '14', title: 'Category page level 5', selected: false },
  { id: '15', title: 'Category grid level 1', selected: false },
  { id: '16', title: 'Category grid level 2', selected: false },
  { id: '17', title: 'Category grid level 3', selected: false },
  { id: '18', title: 'Product page zone A', selected: false },
  { id: '19', title: 'Product page zone B', selected: false },
  { id: '20', title: 'Product page zone C', selected: false },
];

const initialState = {
  selectedCategory: '',
  campaign: {
    startDate: moment().format('YYYY-MM-DD'),
  },
  createdCampaign: {},
  step: 0,
  steps: null,
  loading: false,
  categories: categories,
};

export const newCampaignSlice = createSlice({
  name: 'new_campaign',
  initialState: initialState,
  reducers: {
    selectCategoryAction: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setStepAction: (state, action) => {
      state.step = action.payload;
    },
    setStepsAction: (state, action) => {
      state.steps = action.payload;
    },
    setLoadingAction: (state, action) => {
      state.loading = action.payload;
    },
    setCampaignAction: (state, action) => {
      state.campaign[action.payload.key] = action.payload.value;
    },
    setInitialCampaignAction: (state, action) => {
      state.campaign = action.payload;
      state.selectedCategory = '';
    },
    setCampaignCreatedAction: (state, action) => {
      state.campaign = {};
      state.createdCampaign = action.payload;
    },
    setCategoriesAction: (state, action) => {
      state.categories = action.payload;
    },
    setInitialStateAction: (state, action) => initialState,
  },
});

export const {
  selectCategoryAction,
  setStepAction,
  setStepsAction,
  setLoadingAction,
  setInitialCampaignAction,
  setCampaignAction,
  setCampaignCreatedAction,
  setCategoriesAction,
  setInitialStateAction,
} = newCampaignSlice.actions;

export const selectedCategorySelector = state => state.newCampaign.selectedCategory;
export const stepSelector = state => state.newCampaign.step;
export const stepsSelector = state => state.newCampaign.steps;
export const loadingSelector = state => state.newCampaign.loading;
export const campaignSelector = state => state.newCampaign.campaign;
export const createdCampaignSelector = state => {
  const { campaigns } = state.newCampaign.createdCampaign;
  return campaigns ? campaigns[0] : {};
};
export const categoriesSelector = state => state.newCampaign.categories;

export default newCampaignSlice.reducer;
