/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconRemove } from 'assets/icons/IconRemove.svg';
import { ReactComponent as IconSearch } from 'assets/icons/IconSearch.svg';
import { ReactComponent as IconAdd } from 'assets/icons/IconNextLast.svg';
import { ReactComponent as IconCreate } from 'assets/icons/IconCreate.svg';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { TextArea } from 'components/TextArea';
import styles from './ProductPicker.module.scss';

const SelectableLinkItem = ({ name = '', url, imageUrl, onChange }) => (
  <div className={styles.selectableItem}>
    <div className={styles.thumbnailContainer}>
      <img className={styles.thumbnail} src={imageUrl} alt="thumbnail" />
    </div>
    <a href={url} title={name} rel="noreferrer" target="_blank" className={styles.link}>
      {name}
    </a>
    <div className={styles.add} onClick={onChange}>
      <IconAdd className={styles.icon} />
    </div>
  </div>
);

const SelectedLinkItem = ({ name = '', url, imageUrl, handleRemove }) => (
  <div className={styles.selectedItem}>
    <div className={styles.thumbnailContainer}>
      <img className={styles.thumbnail} src={imageUrl} alt="thumbnail" />
    </div>
    <a href={url} title={name} rel="noreferrer" target="_blank" className={styles.link}>
      {name}
    </a>
    <div className={styles.remove} onClick={handleRemove}>
      <IconRemove className={styles.icon} />
    </div>
  </div>
);

export const ProductPickerView = ({
  allItems,
  selectedItems,
  invalidItems,
  query,
  handleItemSelect,
  handleItemRemove,
  handleSelectAll,
  handleClearAll,
  itemUrlText,
  handleQueryChange,
  handleItemsUrlChange,
  noSearch,
  expanded,
  searchDisabled,
  handleExpand,
  searchPlaceholder,
  method,
  handleMethodChange,
  handleAdd,
  handleShowMore,
  ...props
}) => (
  <div className={classList(styles.container, noSearch && styles.noSearch)} {...props}>
    <div className={classList(styles.allItems, method === 'enter' && styles.withEnter)}>
      {!noSearch && (
        <div className={classList(styles.header, styles.column)}>
          <div className={styles.tabContainer}>
            <div style={{ display: 'flex' }}>
              <Button
                type={method === 'search' ? 'secondary' : 'primary'}
                size="small"
                onClick={() => handleMethodChange('search')}
              >
                <FormattedMessage id="form_product_search" />
              </Button>
              <Button
                type={method === 'enter' ? 'secondary' : 'primary'}
                size="small"
                onClick={() => handleMethodChange('enter')}
              >
                <FormattedMessage id="form_product_enter_list" />
              </Button>
            </div>
            {method === 'search' && allItems.length > 0 && (
              <a onClick={handleSelectAll}>
                <FormattedMessage id="form_banner_placement_select_all" />
              </a>
            )}
          </div>
          {method === 'search' && (
            <TextInput
              value={query}
              onChange={handleQueryChange}
              label={searchPlaceholder || <FormattedMessage id="form_banner_placement_search" />}
              prefixIcon={<IconSearch />}
              disabled={searchDisabled}
            />
          )}
          {method === 'enter' && (
            <Fragment>
              <TextArea
                value={itemUrlText}
                onChange={handleItemsUrlChange}
                label={<FormattedMessage id="form_product_urls_placeholder" />}
                disabled={searchDisabled}
                error={!!invalidItems.length}
              />
              {!!invalidItems.length && (
                <div className={styles.invalidItems}>
                  <div>
                    <FormattedMessage id="form_product_urls_bad_url" />
                  </div>
                  {invalidItems && invalidItems.map(({ url }, idx) => <div key={`${idx}${url}`}>{url}</div>)}
                </div>
              )}
              <div className={styles.addContainer}>
                <Button
                  type="primary"
                  icon={<IconCreate />}
                  disabled={searchDisabled || !itemUrlText}
                  onClick={handleAdd}
                >
                  <FormattedMessage id="btn_add" />
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      )}
      {expanded && method !== 'enter' && (
        <div className={styles.body}>
          {allItems.map(({ id, title, url, imageUrl, selected }) => (
            <Fragment key={id}>
              {!!id && !selected && (
                <SelectableLinkItem name={title} url={url} imageUrl={imageUrl} onChange={e => handleItemSelect(id)} />
              )}
            </Fragment>
          ))}
          {allItems.length === 50 && (
            <div className={styles.loadMoreContainer}>
              <a onClick={handleShowMore}>
                <FormattedMessage id="form_banner_placement_show_more" />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
    {method !== 'enter' && (
      <div className={styles.selectedItems}>
        {!noSearch && (
          <div
            className={classList(
              styles.header,
              method === 'search' && styles.withSearch,
              method === 'enter' && styles.withEnter
            )}
          >
            <div className={styles.title}>
              {!!selectedItems.length && (
                <FormattedMessage
                  id="form_banner_placement_selected"
                  values={{
                    number: selectedItems.length,
                  }}
                />
              )}
            </div>
            <div className={styles.actions}>
              <Button type="ghost" onClick={handleClearAll}>
                <FormattedMessage id="form_banner_placement_clear_all" />
              </Button>
            </div>
          </div>
        )}
        {expanded && (
          <div className={styles.body}>
            {selectedItems.map(({ id, title, url, imageUrl }) => (
              <SelectedLinkItem
                key={id}
                name={title}
                url={url}
                imageUrl={imageUrl}
                handleRemove={() => handleItemRemove(id)}
              />
            ))}
          </div>
        )}
      </div>
    )}
  </div>
);
