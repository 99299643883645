import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as IconTrash } from 'assets/icons/IconTrash.svg';
import { ReactComponent as IconLink } from 'assets/icons/IconLink.svg';
import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';
import { SelectInput } from 'components/SelectInput';
import { Toggle } from 'components/Toggle';
import { FormGroup } from 'components/FormGroup';
import styles from './CategoryItem.module.scss';

export const CategoryItemView = ({
  itemId,
  name,
  url,
  maxCPC,
  status,
  handleStatusChange,
  handleItemBidChange,
  handleRemoveItem,
  tooltipText,
  handleCopy,
  BidOptions,
  isCpm,
  isEdit,
  fixedPrice,
}) => (
  <div className={classList(styles.categoryItem, isEdit && styles.edit)}>
    <FormGroup title={<FormattedMessage id="form_product_added" />} className={styles.productLinkForm}>
      <div className={styles.linkContainer}>
        <Toggle checked={status} onChange={value => handleStatusChange(value, itemId)} className={styles.toggle} />
        <div className={styles.link}>
          <Tooltip title={tooltipText}>
            <IconLink onClick={() => handleCopy(url)} />
          </Tooltip>
          <span>{name}</span>
        </div>
        {!isEdit && (
          <Button
            type="ghost"
            iconOnly
            className={styles.remove}
            icon={<IconTrash />}
            onClick={() => handleRemoveItem(itemId)}
          />
        )}
      </div>
    </FormGroup>
    {isCpm ? (
      <FormGroup
        title={<FormattedMessage id="form_cpm_bid" />}
        {...(!isEdit && { subtitle: <FormattedMessage id="form_cpm_bid_description" /> })}
        tooltip={<FormattedMessage id="form_cpm_bid_tooltip" />}
        className={styles.productForm}
      >
        {fixedPrice ? (
          <div className={styles.itemCosts}>{`€ ${maxCPC}`}</div>
        ) : (
          <SelectInput
            value={maxCPC}
            onChange={value => handleItemBidChange(value, itemId)}
            label={<FormattedMessage id="form_cpm_bid_placeholder" />}
            options={BidOptions}
          />
        )}
      </FormGroup>
    ) : (
      <FormGroup
        title={<FormattedMessage id="form_category_bid" />}
        {...(!isEdit && { subtitle: <FormattedMessage id="form_category_bid_description" /> })}
        tooltip={<FormattedMessage id="form_category_bid_tooltip" />}
        className={styles.productForm}
      >
        {fixedPrice ? (
          <div className={styles.itemCosts}>{`€ ${maxCPC}`}</div>
        ) : (
          <SelectInput
            value={maxCPC}
            onChange={value => handleItemBidChange(value, itemId)}
            label={<FormattedMessage id="form_category_placeholder" />}
            options={BidOptions}
          />
        )}
      </FormGroup>
    )}
  </div>
);
