import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ConditionalRoute = ({ condition, redirectTo, component: Component, ...props }) => (
  <Route
    {...props}
    render={p => {
      // if condition is met render component
      if (condition) {
        return <Component {...props} />;
      }
      return <Redirect to={redirectTo} />;
    }}
  />
);
