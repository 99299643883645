import api from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const constructCreateProductCampaignPayload = (
  piguSellerId,
  accountId,
  { name, shopId, sellerId, startDate, endDate, totalBudget, dailyBudget, maxCPC, items = [], all_product },
  campaignType
) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      ...((piguSellerId || sellerId) && { seller: piguSellerId ? piguSellerId : sellerId }),
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      campaignType: campaignType,
      all_product: all_product,
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
  ...(!all_product && {
    aditems: items.map(({ itemId, name, url, imageUrl }) => ({
      itemId,
      name,
      url,
      imageUrl,
      adsetId: -1,
    })),
  }),
});

const constructCreateCategoryCampaignPayload = (
  accountId,
  { name, shopId, startDate, endDate, categoryItems = [] },
  demo
) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      campaignType: demo ? 'demo-category' : 'cpm-category',
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
  aditems: categoryItems.map(({ itemId, name, url, maxCPC, status }) => ({
    itemId,
    name,
    url,
    adsetId: -1,
    maxCPC: Number(maxCPC),
    disabled: !status,
  })),
});

const constructCreateBannerCampaignPayload = (
  accountId,
  {
    name,
    shopId,
    startDate,
    endDate,
    landingUrl,
    pricingModel,
    pricingModelPrice,
    dailyBudget,
    totalBudget,
    maxCPC,
    limit,
    limitAmount,
    limitType,
    priorityType,
    banners = [],
  },
  campaignType
) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      banner: true,
      landingUrl: landingUrl,
      pricingModel: pricingModel,
      pricingModelPrice: pricingModelPrice,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      limit: limit,
      limitAmount: limitAmount,
      limitType: limitType,
      priorityType: priorityType,
      campaignType: campaignType,
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
  aditems: banners.map(({ name, size, url, landingUrl, dimensions, categories = [], deliveryRules, keywords }) => ({
    itemId: 'b' + url.replace(/[^0-9]/g, ''),
    name,
    size,
    bannerUrl: url,
    landingUrl: landingUrl,
    dimensions,
    categories: categories.filter(item => item.id).map(item => item.id),
    deliveryRules: deliveryRules,
    keywords,
    adsetId: -1,
  })),
});

const constructCreateRetailProductCampaignPayload = (
  accountId,
  {
    name,
    shopId,
    startDate,
    endDate,
    pricingModel,
    pricingModelPrice,
    totalBudget,
    limit,
    limitAmount,
    limitType,
    priorityType,
    priorityLevel,
    items = [],
    placements = [],
    all_product,
  }
) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      pricingModel: pricingModel,
      pricingModelPrice: pricingModelPrice,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      limit: limit,
      limitAmount: limitAmount,
      limitType: limitType,
      priorityType: priorityType,
      priorityLevel: priorityLevel,
      placements: placements,
      campaignType: 'retail-product',
      all_product: all_product,
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
  ...(!all_product && {
    aditems: items.map(({ itemId, name, url, imageUrl, status, disabled, keywords }) => ({
      itemId,
      name,
      url,
      imageUrl,
      status,
      disabled,
      keywords,
      adsetId: -1,
    })),
  }),
});

const constructCreateFacebookAdsCampaignPayload = (accountId, { name, shopId, dailyBudget, duration }) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      accountId: accountId,
      name: name,
      dailyBudget: Number(dailyBudget),
      duration: duration,
      campaignType: 'facebook-ads',
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
});

const constructCreateSponsoredBrandsCampaignPayload = (
  accountId,
  { name, shopId, startDate, endDate, totalBudget, dailyBudget, maxCPC, brands = [] },
  campaignType
) => ({
  campaigns: [
    {
      id: -1,
      shopId: shopId,
      accountId: accountId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      totalBudget: !!totalBudget ? Number(totalBudget) : null,
      dailyBudget: Number(dailyBudget),
      maxCPC: Number(maxCPC),
      campaignType: campaignType,
      brands: brands,
    },
  ],
  adsets: [
    {
      id: -1,
      campaignId: -1,
      name: name,
    },
  ],
});

export const itemUrlLookup = createAsyncThunk('new_campaign/itemUrlLookup', async ({ shopId, itemUrlText }) => {
  const lookup_data = itemUrlText.trim().split(/\s+/);
  const { data } = await api.post('/api/item_lookup', {
    shopId,
    data: lookup_data,
  });
  return data;
});

export const itemSearch = createAsyncThunk('new_campaign/itemSearch', async ({ shopId, sellerId, query, limit }) => {
  const { data } = await api.post('/api/item_search', {
    shopId,
    ...(sellerId && { sellerId }),
    query,
    limit,
  });
  return data;
});

export const itemCategoryInfo = createAsyncThunk('new_campaign/itemCategoryInfo', async ({ itemId, shopId }) => {
  const itemIds = [itemId];
  const { data } = await api.post('/api/category_info', {
    shopId,
    itemIds,
  });
  return data;
});

export const createProductCampaign = createAsyncThunk(
  'new_campaign/createProductCampaign',
  async (campaignType, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const piguSellerId = getState().settings.piguSellerId;
    const payload = constructCreateProductCampaignPayload(piguSellerId, accountId, campaign, campaignType);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const createCategoryCampaign = createAsyncThunk(
  'new_campaign/createCategoryCampaign',
  async (params, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructCreateCategoryCampaignPayload(accountId, campaign, (params && params.demo) || false);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const createBannerCampaign = createAsyncThunk(
  'new_campaign/createBannerCampaign',
  async (campaignType, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructCreateBannerCampaignPayload(accountId, campaign, campaignType);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const createRetailProductCampaign = createAsyncThunk(
  'new_campaign/createRetailProductCampaign',
  async (placeholder, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructCreateRetailProductCampaignPayload(accountId, campaign);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const createFacebookAdsCampaign = createAsyncThunk(
  'new_campaign/createFacebookAdsCampaign',
  async (placeholder, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructCreateFacebookAdsCampaignPayload(accountId, campaign);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const createSponsoredBrandsCampaign = createAsyncThunk(
  'new_campaign/createSponsoredBrandsCampaign',
  async (campaignType, { getState }) => {
    const { campaign } = getState().newCampaign;
    const { accountId } = getState().dashboard.userAccount;
    const payload = constructCreateSponsoredBrandsCampaignPayload(accountId, campaign, campaignType);
    const { data } = await api.post('/api/put', payload);
    return data;
  }
);

export const uploadBanner = createAsyncThunk('new_campaign/uploadBanner', async file => {
  let formData = new FormData();
  formData.append('file', file);
  const { data } = await api.post('/banner/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { ...data, name: file.name, size: file.size };
});

export const getSellerList = createAsyncThunk('new_campaign/getSellerListByShopId', async shopId => {
  const { data } = await api.get(`/api/seller?shop_id=${shopId}`);
  return data;
});

export const getProductCount = createAsyncThunk('new_campaign/getProductCount', async (shopId, { getState }) => {
  const { campaign } = getState().newCampaign;
  const sellerId = campaign.sellerId;
  const piguSellerId = getState().settings.piguSellerId;
  const { data } = await api.get(
    `/api/product_count?shop_id=${shopId}&seller=${piguSellerId ? piguSellerId : sellerId}`
  );
  return data;
});
