import React from 'react';
import { BannerAddForm } from 'forms/banner/banner_add_form';

export const RetailBannerAddView = ({
  step,
  steps,
  campaign,
  shopOptions,
  loading,
  handleBack,
  handleNext,
  handleChange,
  uploadBanner,
  userAccount,
  shopPreselected,
}) => (
  <BannerAddForm
    {...{
      step,
      steps,
      campaign,
      shopOptions,
      loading,
      handleBack,
      handleNext,
      handleChange,
      uploadBanner,
      userAccount,
      shopPreselected,
    }}
  />
);
