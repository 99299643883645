import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const changePassword = createAsyncThunk(
  'change_password/changePassword',
  async ({ user, password, newPassword }) => {
    const payload = {
      ...user,
      change_password: {
        old_password: password,
        new_password: newPassword,
      },
    };

    const { data } = await api.post('/api/put', { users: [payload] });
    return data;
  }
);

const initialState = {
  password: null,
  newPassword: null,
  newPasswordRepeat: null,
  loading: false,
  error: {
    password: null,
    newPassword: null,
    newPasswordRepeat: null,
  },
};

export const changePasswordSlice = createSlice({
  name: 'change_password',
  initialState: initialState,
  reducers: {
    setChangePasswordAction: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setChangePasswordErrorAction: (state, action) => {
      state.error[action.payload.key] = action.payload.value;
    },
    setInitialStateAction: (state, action) => initialState,
  },
  extraReducers: {
    [changePassword.pending]: (state, action) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error.password = true;
    },
  },
});

export const {
  setChangePasswordAction,
  setChangePasswordErrorAction,
  setInitialStateAction,
} = changePasswordSlice.actions;

export const changePasswordSelector = state => state.changePassword;

export default changePasswordSlice.reducer;
