import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import styles from './CategoryCard.module.scss';

export const CategoryCard = ({ tag, title, subtitle, icon, selected, disabled, isRecommended, ...props }) => (
  <div className={classList(styles.base, selected && styles.selected, disabled && styles.disabled)} {...props}>
    {isRecommended && (
      <div className={styles.ribbon}>
        <FormattedMessage id="type_choice_recommended" />
      </div>
    )}
    <div className={styles.wrap}>
      <div className={styles.tag}>{tag}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
    <div className={styles.iconContainer}>{icon}</div>
  </div>
);
