import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const postPasswordReset = createAsyncThunk('passwordReset/postPasswordReset', async formData => {
  const response = await api.post('/forgot_password', formData);
  if (response && response.status) {
    return response.status;
  }
});

export const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: {
    email: null,
    error: null,
    loading: false,
  },
  reducers: {
    setPasswordResetAction: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: {
    [postPasswordReset.pending]: (state, action) => {
      state.loading = true;
    },
    [postPasswordReset.fulfilled]: (state, action) => {
      if (action.payload === 200) {
        state.status = 'success';
      }
      state.loading = false;
    },
    [postPasswordReset.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { setPasswordResetAction } = passwordResetSlice.actions;

export const passwordResetSelector = state => state.passwordReset;

export default passwordResetSlice.reducer;
