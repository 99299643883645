import React from 'react';
import { VendorProductAddForm } from 'forms/vendor_product/vendor_product_add_form';

export const VendorProductAddView = ({
  step,
  steps,
  campaign,
  userAccount,
  shopOptions,
  invalidItems,
  searchItems,
  shopPreselected,
  loading,
  handleBack,
  handleNext,
  handleAdd,
  handleChange,
  handleSearch,
}) => (
  <VendorProductAddForm
    {...{
      step,
      steps,
      campaign,
      userAccount,
      shopOptions,
      searchItems,
      invalidItems,
      shopPreselected,
      loading,
      handleBack,
      handleAdd,
      handleNext,
      handleChange,
      handleSearch,
    }}
  />
);
