import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button';
import { Popup, PopupItem } from 'components/Popup';
import { ReactComponent as EditIcon } from 'assets/icons/IconEdit.svg';
import styles from './table.module.scss';
import './styles.scss';

const nameColumn = {
  title: <FormattedMessage id="col_name" defaultMessage="Name" />,
  dataIndex: 'name',
  sorter: (a, b) => a.name.localeCompare(b.name),
};

const sizeColumn = {
  title: <FormattedMessage id="col_c" defaultMessage="Size" />,
  dataIndex: 'dimensions',
  render: (text, record) => {
    return <div>
      {record?.dimensions?.width} x {record?.dimensions?.height} 
    </div>;
  },
};

const updatedAtColumn = {
  title: <FormattedMessage id="col_c" defaultMessage="Updated At" />,
  dataIndex: 'updatedAt',
};

const descriptionColumn = {
  title: <FormattedMessage id="col_c" defaultMessage="Description" />,
  dataIndex: 'description',
};

const ActionsPopup = props => createPortal(<Popup {...props} />, document.getElementById('root'));

const Edit = ({ record }) => {
  const { id } = record;

  const btnRef = useRef(null);

  const [isPopupShown, setShowPopup] = useState(false);

  const [coords, setCoords] = useState({}); // takes current button coordinates

  const updateTooltipCoords = button => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      right: rect.width * 2, // add half the width of the button for centering
      top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const handleOpen = ({ target }) => {
    updateTooltipCoords(target);
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.popupContainer} ref={btnRef}>
      <Link to={`/zones/${id}`}>
        <Button iconOnly type="primary" icon={<EditIcon />} />
      </Link>
      {/* <ActionsPopup isVisible={isPopupShown} onClose={handleClose} coords={coords}>
          <Link to={`/zones/${zoneId}`}>
            <PopupItem>
              <FormattedMessage id="btn_edit" />
            </PopupItem>
          </Link>
      </ActionsPopup> */}
    </div>
  );
};

const editColumn = {
  width: 56,
  render: (text, record) => {
    return <Edit record={record} />;
  },
};

const columnConfig = [nameColumn, sizeColumn, updatedAtColumn, descriptionColumn, editColumn];

export const BannerZonesTable= ({ items }) => {
  const [tablePageSize, setTablePageSize] = useState(10);

  const handleChange = e => {
    if (tablePageSize !== e.pageSize) {
      setTablePageSize(e.pageSize);
    }
  };

  return (
        <Table
          columns={columnConfig}
          dataSource={items}
          loading={false}
          showSorterTooltip={false}
          scroll={{ x: true }}
          rowKey="id"
          onChange={handleChange}
          className="banner-zones-table"
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '100'],
          }}
        />
  );
};
