import React, { Fragment } from 'react';
import { Header } from 'components/Header';
import { BannerZonesRouter } from './router';

const BannerZones = () => {
  return (
    <Fragment>
      <Header />
      <BannerZonesRouter />
    </Fragment>
  );
};

export default BannerZones;
