import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { AuthContainer } from 'components/AuthContainer';
import { ReactComponent as Icon } from 'assets/icons/IconDone.svg';
import styles from './password_reset.module.scss';

export const PasswordResetView = ({
  email,
  status,
  error,
  loading,
  handleEmailChange,
  handleEmailFocus,
  handleValidate,
}) => (
  <Fragment>
    {status !== 'success' ? (
      <AuthContainer
        title={<FormattedMessage id="forgot_password_form_title" defaultMessage="Forgot password?" />}
        subtitle={
          <FormattedMessage
            id="forgot_password_form_subtitle"
            defaultMessage="You will receive password reset link to your email."
          />
        }
      >
        <form className={styles.passwordReset} onSubmit={handleValidate} autoComplete="off">
          <div className={styles.inputs}>
            <TextInput
              value={email}
              onChange={handleEmailChange}
              label={<FormattedMessage id="forgot_password_form_email" defaultMessage="Enter your email address" />}
              error={error}
              errorText={
                <FormattedMessage
                  id="forgot_password_form_email_error"
                  defaultMessage="We didn’t recognize the user email / ID you’ve entered, please try again."
                />
              }
              onFocus={handleEmailFocus}
            />
          </div>
          <div className={styles.buttons}>
            <Button type="ghost" className={styles.button}>
              <Link to="/martailer-login">
                <FormattedMessage id="forgot_password_form_back_btn" defaultMessage="Back" />
              </Link>
            </Button>
            <Button className={styles.button} loading={loading} htmlType="submit">
              <FormattedMessage id="forgot_password_form_send_link_btn" defaultMessage="Send reset link" />
            </Button>
          </div>
        </form>
      </AuthContainer>
    ) : (
      <AuthContainer>
        <div className={styles.passwordReset}>
          <div className={styles.linkSent}>
            <Icon />
            <div className={styles.title}>
              <FormattedMessage id="forgot_password_form_link_sent" defaultMessage="Reset link sent" />
            </div>
            <div className={styles.subtitle}>
              <FormattedMessage
                id="forgot_password_form_success_sent"
                defaultMessage="We’ve sent a password reset link to: "
              />
              <br />
              <span>{email}</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Link to="/martailer-login">
              <Button type="ghost" className={styles.link}>
                <FormattedMessage id="forgot_password_form_back_to_sign_in_btn" defaultMessage="Back to sign in" />
              </Button>
            </Link>
          </div>
        </div>
      </AuthContainer>
    )}
  </Fragment>
);
