import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Icon } from 'assets/icons/IconLogo.svg';
import { ReactComponent as IconPigu } from 'assets/icons/IconLogoPowered.svg';
import { ReactComponent as AddIcon } from 'assets/icons/IconCreate.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/IconMenu.svg';
import { ReactComponent as LanguageIcon } from 'assets/icons/IconLanguage.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/IconHelp.svg';

import { Container } from 'components/Container';
import { Button } from 'components/Button';
import { Popup, PopupItem } from 'components/Popup';
import styles from './Header.module.scss';

export const HeaderView = ({
  showLanguagesPopupHandler,
  selectLanguageHandler,
  showHelpPopupHandler,
  userAccount,
  showMenuPopupHandler,
  logOutHandler,
  isPopupsShown,
  settings,
  isDemoAccount,
}) => (
  <header>
    <div className={styles.visible}>
      <Container className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/dashboard" className={styles.logoLink}>
            {window.location.hostname === 'pigu.martailer.com' ? (
              <IconPigu className={styles.logo} />
            ) : (
              <Icon className={styles.logo} />
            )}
          </Link>
          <Link to="/new-campaign">
            <Button icon={<AddIcon />}>
              <FormattedMessage id="btn_new_campaign" defaultMessage="Create new campaign" />
            </Button>
          </Link>
          {isDemoAccount && (
            <Link to="/zones/new">
              <Button icon={<AddIcon />}>
                <FormattedMessage id="btn_new_banner_zone" defaultMessage="Create new banner zone" />
              </Button>
            </Link>
          )}
        </div>
        <div className={styles.userMenu}>
          {isDemoAccount && (
            <Fragment>
              <Link to="/zones">
                <Button>
                  <FormattedMessage id="banner_zones" defaultMessage="Banner zones" />
                </Button>
              </Link>
              <Link to="/analytics">
                <Button>
                  <FormattedMessage id="analytics" defaultMessage="Analytics" />
                </Button>
              </Link>
            </Fragment>
          )}
          <Button type={'primary'} icon={<LanguageIcon />} onClick={showLanguagesPopupHandler}>
            {settings.language.toUpperCase()}
            <LanguagePopup
              settings={settings}
              isVisible={isPopupsShown.languages}
              onSelect={selectLanguageHandler}
              onClose={showLanguagesPopupHandler}
            />
          </Button>
          <Button type={'primary'} icon={<HelpIcon />} onClick={showHelpPopupHandler}>
            <FormattedMessage id="btn_help" defaultMessage="Help" />
            <HelpPopup settings={settings} isVisible={isPopupsShown.help} onClick={showHelpPopupHandler} />
          </Button>
          <div className={styles.userMenuButton} onClick={showMenuPopupHandler}>
            <MenuIcon className={styles.menuIcon} />
            <div className={styles.userName}>{userAccount ? userAccount.name : ''}</div>

            <MenuPopup isVisible={isPopupsShown.menu} onClick={showMenuPopupHandler} logOutHandler={logOutHandler} />
          </div>
        </div>
      </Container>
    </div>
    <div className={styles.marginDummy} />
  </header>
);

const LanguagePopup = ({ settings, isVisible, onSelect, onClose }) => (
  <Popup isVisible={isVisible} onClose={onClose}>
    {settings.languages.map(item => (
      <PopupItem key={item.value} onClick={() => onSelect(item.value)} withAddon>
        {item.title}
      </PopupItem>
    ))}
  </Popup>
);

const HelpPopup = ({ settings, isVisible, onClick }) => {
  const openChat = () => {
    if (window.$crisp) window.$crisp.push(['do', 'chat:open']);
  };
  return (
    <Popup isVisible={isVisible} onClose={onClick}>
      {window.location.hostname !== 'app.martailer.com' && (
        <Link to="/faq">
          <PopupItem>
            <FormattedMessage id="menu_item_faq" defaultMessage="F.A.Q." />
          </PopupItem>
        </Link>
      )}
      <a href="mailto:support@martailer.com">
        <PopupItem>
          <FormattedMessage id="menu_item_email_support" defaultMessage="Email support" />
        </PopupItem>
      </a>
      {window.location.hostname !== 'app.martailer.com' && (
        <PopupItem onClick={openChat}>
          <FormattedMessage id="menu_item_online_chat" defaultMessage="Online chat" />
        </PopupItem>
      )}
    </Popup>
  );
};

const MenuPopup = ({ isVisible, onClick, logOutHandler }) => (
  <Popup isVisible={isVisible} onClose={onClick}>
    <div className={styles.popup}>
      <Link to="/change-password">
        <PopupItem onClick={onClick}>
          <FormattedMessage id="title_account_settings" defaultMessage="Change password" />
        </PopupItem>
      </Link>
      {/* <PopupItem onClick={onClick}>
        <FormattedMessage
          id="account_settings_user_access"
          defaultMessage="User access"
        />
      </PopupItem>
      <PopupItem onClick={onClick}>
        <FormattedMessage
          id="account_settings_billing"
          defaultMessage="Billing"
        />
      </PopupItem> */}
      <PopupItem red onClick={logOutHandler}>
        <FormattedMessage id="account_settings_log_out" defaultMessage="Log out" />
      </PopupItem>
    </div>
  </Popup>
);
