import React from 'react';
import { DemoCategoryForm } from 'forms/demo_category';

export const DemoCategoryView = ({
  step,
  steps,
  campaign,
  initialItems,
  invalidItems,
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleItemsChange,
  handleAdd,
}) => (
  <DemoCategoryForm
    {...{
      step,
      steps,
      campaign,
      initialItems,
      invalidItems,
      loading,
      handleBack,
      handleNext,
      handleChange,
      handleItemsChange,
      handleAdd,
    }}
    isEdit
  />
);
