import React, { useEffect, useState } from 'react';
import { PlacementView } from './views';

export const Placement = ({
  items = [],
  handleSelect,
  handleSelectAll,
  handleAdd,
  noSearch,
  small,
  isEdit,
  searchPlaceholder,
  searchDisabled,
  withAdd,
  ...props
}) => {
  const [allItems, setAllItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [query, setQuery] = useState('');
  const [expanded, setExpanded] = useState(!isEdit);

  const [addNewActive, setAddNewActive] = useState(false);
  const [newValue, setNewValue] = useState('');

  const handleNewActivate = value => {
    setAddNewActive(value);
    if (!value) {
      setNewValue('');
    }
  };

  const handleSaveNew = () => {
    handleAdd(newValue);
    setAddNewActive(false);
    setNewValue('');
  };

  const handleNewChange = ({ target: { value } }) => {
    setNewValue(value);
  };

  const handleItemSelect = (id, { target: { checked } }) => {
    handleSelect(id, checked);
  };

  const handleItemRemove = id => {
    handleSelect(id, false);
  };

  const handleClearAll = () => {
    handleSelectAll(false);
  };

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const handleExpand = value => {
    setExpanded(value);
  };

  useEffect(() => {
    setAllItems(items);
    setSelectedItems(items.filter(item => item.selected));
  }, [items]);

  useEffect(() => {
      const lowercaseQuery = query.toLowerCase();
      setFilteredItems(
        allItems.filter(item => {
          const title = item.title && item.title.toLowerCase();
          return title.includes(lowercaseQuery);
        })
      );
  }, [allItems, query]);

  return (
    <PlacementView
      {...{
        items,
        allItems: filteredItems,
        selectedItems,
        query,
        handleItemSelect,
        handleItemRemove,
        handleClearAll,
        handleQueryChange,
        noSearch,
        small,
        expanded,
        searchDisabled,
        handleExpand,
        searchPlaceholder,
        addNewActive,
        handleNewActivate,
        newValue,
        handleNewChange,
        handleSaveNew,
        withAdd,
      }}
      {...props}
    />
  );
};
