import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container } from 'components/Container';
import { Row } from 'components/Row';
import { Col } from 'components/Col';
import { Chart } from 'components/Chart';
import styles from './charts.module.scss';

export const Charts = ({ chartData }) => {
  const { formatNumber } = useIntl();

  return (
    <Container>
      <Row className={styles.chartRow}>
        <Col className={styles.col}>
          <Chart
            data={chartData}
            label={<FormattedMessage id="clicks_counter" defaultMessage="Clicks" />}
            valueY={'clicks'}
            yTickFormatter={value => formatNumber(value)}
          />
        </Col>
        <Col className={styles.col}>
          <Chart
            data={chartData}
            label={<FormattedMessage id="impressions_counter" defaultMessage="Impressions" />}
            valueY={'impressions'}
            yTickFormatter={value => formatNumber(value)}
          />
        </Col>
      </Row>
    </Container>
  );
};
