import React, { useEffect } from 'react';
import { OverlayFormView } from './views';

const setBodyScroll = shouldScroll => {
  document.querySelector('body').style.overflow = shouldScroll ? 'auto' : 'hidden';
};

export const OverlayForm = props => {
  useEffect(() => {
    setBodyScroll(false);
    return () => {
      setBodyScroll(true);
    };
  }, []);

  return <OverlayFormView {...props} />;
};
