import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { retailProductsSteps, homeAndProductsSteps, categoryPageSteps, bannerSteps, facebookAdsSteps } from 'components/ProgressBar';
import { settingsSelector } from 'store/settings';
import { loadingSelector } from 'pages/dashboard/redux';
import { selectCategoryAction, selectedCategorySelector, setStepAction, setStepsAction } from '../redux';
import { ChooseCampaignView } from './views';

export const ChooseCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = useSelector(settingsSelector);
  const selectedCategory = useSelector(selectedCategorySelector);
  const loading = useSelector(loadingSelector);

  const handleCategorySelect = category => {
    dispatch(selectCategoryAction(category));
    dispatch(setStepAction(1));
  };

  const handleNext = () => {
    history.push(`/new-campaign/${selectedCategory}`);
  };

  const getSteps = category => {
    switch (category) {
      case 'retail-product':
        return retailProductsSteps;
      case 'product':
      case 'demo-product':
      case 'vendor-product':
      case 'sponsored-product':
      case 'sponsored-brands':
        return homeAndProductsSteps;
      case 'cpm-category':
      case 'demo-category':
        return categoryPageSteps;
      case 'banner':
      case 'retail-banner':
      case 'vendor-banner':
        return bannerSteps;
      case 'facebook-ads':
        return facebookAdsSteps;
      default:
        return homeAndProductsSteps;
    }
  };

  useEffect(() => {
    dispatch(setStepsAction(getSteps(selectedCategory)));
  }, [selectedCategory]);

  useEffect(() => {
    dispatch(setStepAction(selectedCategory ? 1 : 0));
  }, []);

  return (
    <ChooseCampaignView
      {...{
        loading,
        handleNext,
        selectedCategory,
        handleCategorySelect,
        settings,
      }}
    />
  );
};
