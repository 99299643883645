import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const postLogin = createAsyncThunk('login/postLogin', async formData => {
  const { data } = await api.post('/login', formData);
  if (data) {
    return data;
  } else {
    throw Error('Login failed');
  }
});

export const getLogin = createAsyncThunk('login/getLogin', async () => {
  const { data } = await api.get('/login');
  if (data) {
    return data;
  } else {
    throw Error('Login failed');
  }
});

export const logOut = createAsyncThunk('login/logOut', async () => {
  const response = await api.get('/logout');
  if (response && response.status === 200) {
    return response.data;
  }
});

export const resetPassword = createAsyncThunk('login/resetPassword', async (placeholder, { getState }) => {
  const newPassword = getState().login.newPassword;
  const { shouldResetPassword, ...user } = getState().login.user;
  const payload = {
    ...user,
    password: newPassword,
  };

  const response = await api.post('/api/put', { users: [payload] });
  if (response && response.status === 200 && response.data && response.data.users && response.data.users.length > 0) {
    return response.data.users[0];
  }
});

export const userRegister = createAsyncThunk('login/userRegister', async (placeholder, { getState }) => {
  const { newEmail, newPassword, phone, termsAndConditions } = getState().login;
  const { piguNewSeller, ...user } = getState().login.user;

  const payload = {
    ...user,
    email: newEmail,
    password: newPassword,
    phone: phone,
    terms_and_conditions: termsAndConditions,
  };

  const response = await api.post('/api/put', { users: [payload] });
  if (response && response.status === 200 && response.data && response.data.users && response.data.users.length > 0) {
    return response.data.users[0];
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: {},

    // login form
    email: null,
    password: null,

    // password_reset and user_register forms
    newEmail: null,
    newPassword: null,
    newPasswordRepeat: null,
    phone: null,
    termsAndConditions: false,

    status: 'loading',
    loading: false,
    error: {
      email: null,
      password: null,
      newEmail: null,
      newPassword: null,
      newPasswordRepeat: null,
    },
  },
  reducers: {
    setLoginAction: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setLoginErrorAction: (state, action) => {
      state.error[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postLogin.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload;
        state.email = null;
        state.password = null;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.status = 'error';
        state.error.login = true;
      })

      .addCase(getLogin.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getLogin.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload || {};
      })
      .addCase(getLogin.rejected, (state, action) => {
        state.status = 'error';
      })

      .addCase(logOut.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.status = null;
        state.email = null;
        state.user = {};
      })
      .addCase(logOut.rejected, (state, action) => {
        state.status = 'error';
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(userRegister.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.loading = false;
        state.error.newEmail = true;
        state.error.newPassword = true;
      });
  },
});

export const { setLoginAction, setLoginErrorAction } = loginSlice.actions;

export const loginSelector = state => state.login;
export const userSelector = state => state.login.user;
export const statusSelector = state => state.login.status;

export default loginSlice.reducer;
