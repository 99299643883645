import React from 'react';
import { Input } from 'antd';
import { classList } from 'utils/classList';
import { FloatLabel } from '../FloatLabel';
import './styles.scss';

export const TextArea = ({ value, onChange, label, rows = 10, disabled, error }) => (
  <FloatLabel label={label} name={value} value={value} error={error}>
    <Input.TextArea
      className={classList('textarea', error && 'error')}
      value={value}
      onChange={onChange}
      rows={rows}
      resize={'none'}
      disabled={disabled}
    />
  </FloatLabel>
);
