import React, { useEffect, useState } from 'react';
import { isUrl } from 'utils/validator';
import { BannerItemView } from './views';

const CATEGORIES = [
  { id: '1', title: 'Home page main', selected: false },
  { id: '2', title: 'Home page zone A', selected: false },
  { id: '3', title: 'Home page zone B', selected: false },
  { id: '4', title: 'Home page zone C', selected: false },
  { id: '5', title: 'Home carousel 1', selected: false },
  { id: '6', title: 'Home carousel 2', selected: false },
  { id: '7', title: 'Home carousel 3', selected: false },
  { id: '8', title: 'Home carousel 4', selected: false },
  { id: '9', title: 'Home carousel 5', selected: false },
  { id: '10', title: 'Category page level 1', selected: false },
  { id: '11', title: 'Category page level 2', selected: false },
  { id: '12', title: 'Category page level 3', selected: false },
  { id: '13', title: 'Category page level 4', selected: false },
  { id: '14', title: 'Category page level 5', selected: false },
  { id: '15', title: 'Category grid level 1', selected: false },
  { id: '16', title: 'Category grid level 2', selected: false },
  { id: '17', title: 'Category grid level 3', selected: false },
  { id: '18', title: 'Product page zone A', selected: false },
  { id: '19', title: 'Product page zone B', selected: false },
  { id: '20', title: 'Product page zone C', selected: false },
];

const SENUKAI_CATEGORIES = [
  { id: '10', title: 'Category page level 1 (1920x300) (960x150)', selected: false },
  { id: '11', title: 'Category page level 2 (1920x300) (960x150)', selected: false },
  { id: '12', title: 'Category page level 3 (1920x300) (960x150)', selected: false },
  { id: '13', title: 'Category page level 4 (1920x300) (960x150)', selected: false },
];

const RADIOPOPULAR_CATEGORIES = [
  { id: '1', title: 'Homepage Top desktop (1400x230)', dimensions: [1400, 230], selected: false },
  { id: '2', title: 'Homepage Top mobile (400x100)', dimensions: [400, 100], selected: false },
  { id: '3', title: 'Homepage Bottom desktop (1400x230)', dimensions: [1400, 230], selected: false },
  { id: '4', title: 'Homepage Bottom mobile (400x100)', dimensions: [400, 100], selected: false },
  { id: '5', title: 'Category page Top desktop (1050x150)', dimensions: [1050, 150], selected: false },
  { id: '6', title: 'Category page Top mobile (400x100)', dimensions: [400, 100], selected: false },
  { id: '7', title: 'Category Filter Menu desktop (350x250)', dimensions: [350, 250], selected: false },
  { id: '8', title: 'Category page listings Top desktop (565x972)', dimensions: [565, 972], selected: false },
  { id: '9', title: 'Category page listings Top mobile (565x972)', dimensions: [565, 972], selected: false },
  { id: '10', title: 'Category page listings Bottom desktop (565x972)', dimensions: [565, 972], selected: false },
  { id: '11', title: 'Category page listings Bottom mobile (565x972)', dimensions: [565, 972], selected: false },
  { id: '12', title: 'Search results page Top desktop (1050x150)', dimensions: [1050, 150], selected: false },
  { id: '13', title: 'Search results page Top mobile (1050x150)', dimensions: [1050, 150], selected: false },
  { id: '14', title: 'Search results listings Top desktop (565x972)', dimensions: [565, 972], selected: false },
  { id: '15', title: 'Search results listings Top mobile (565x972)', dimensions: [565, 972], selected: false },
  { id: '16', title: 'Search results listings Bottom desktop (565x972)', dimensions: [565, 972], selected: false },
  { id: '17', title: 'Search results listings Bottom mobile (565x972)', dimensions: [565, 972], selected: false },
  { id: '18', title: 'Product Page Top desktop (1150x200)', dimensions: [1150, 200], selected: false },
  { id: '19', title: 'Product Page Top mobile (400x100)', dimensions: [400, 100], selected: false },
];

const RADIOPOPULAR_SEARCH_CATEGORIES = ['12', '13','14', '15', '16', '17'];
const RADIOPOPULAR_DELIVERY_CATEGORIES = ['18', '19'];
const RADIOPOPULAR_TARGET_CATEGORIES = ['5', '6','7', '8', '9', '10', '11'];

const mapCategories = (all, selected = []) => {
  return all.map(category => ({
    ...category,
    selected: !!selected.find(item => (item?.id || item) === category.id),
  }));
};

const categoryDimensionsMatch = (width, height, dimensions) => width === dimensions[0] && height === dimensions[1];

export const BannerItem = ({
  banner,
  name,
  url,
  landingUrl,
  size,
  onUpdate,
  onRemove,
  userAccount,
  isEdit,
  isInitial,
}) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [enableUrlOverride, setEnableUrlOverride] = useState(false);
  const [urlOverrideError, setUrlOverrideError] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  let baseCategories = CATEGORIES;
  const isSenukai = userAccount.shopIds.includes('senukailt');
  const isRadiopopular = userAccount.shopIds.includes('radiopopularpt');
  if (isSenukai) baseCategories = SENUKAI_CATEGORIES;
  if (isRadiopopular) baseCategories = RADIOPOPULAR_CATEGORIES;
  const [allCategories, setAllCategories] = useState(mapCategories(baseCategories, banner.categories));
  const [hasDeliveryRules, setHasDeliveryRules] = useState(false);
  const [hasTargetCategories, setHasTargetCategories] = useState(false);
  const [hasSearch, setHasSearch] = useState(false);

  useEffect(() => {
    onUpdate({
      ...banner,
      categories: allCategories.filter(item => item.selected),
    });
  }, []);
  
  useEffect(() => {
    if (isRadiopopular) {
      const hasSearch = isRadiopopular && banner.categories?.some(category => RADIOPOPULAR_SEARCH_CATEGORIES.includes(category.id));
      const hasDeliveryRules = isRadiopopular && banner.categories?.some(category => RADIOPOPULAR_DELIVERY_CATEGORIES.includes(category.id));
      const hasTargetCategories = isRadiopopular && banner.categories?.some(category => RADIOPOPULAR_TARGET_CATEGORIES.includes(category.id));
      setHasSearch(hasSearch);
      setHasDeliveryRules(hasDeliveryRules);
      setHasTargetCategories(hasTargetCategories);
    }
  }, [banner.categories]);

  const handleShowPreview = value => {
    setShowPreview(value);
  };

  const handleRemoveDialogOpen = value => {
    setRemoveDialogOpen(value);
  };

  const handleConfirm = () => {
    onRemove(url);
    setRemoveDialogOpen(false);
  };

  const handleEnableUrlOverride = value => {
    if (!value) {
      onUpdate({
        ...banner,
        landingUrl: null,
      });
      setUrlOverrideError(false);
    }
    setEnableUrlOverride(value);
  };

  const handleUrlOverride = ({ target: { value } }) => {
    setUrlOverrideError(value && !isUrl(value));
    onUpdate({
      ...banner,
      landingUrl: value,
    });
  };

  const handleSelectCategory = (id, selected) => {
    const { categories = [] } = banner;
    if (selected) {
      onUpdate({
        ...banner,
        categories: [...categories, allCategories.find(item => item.id === id)],
      });
    } else {
      onUpdate({
        ...banner,
        categories: categories.filter(item => item.id !== id),
      });
    }

    const newCategories = allCategories.map(item => {
      if (item.id === id) {
        return { ...item, selected };
      }
      return item;
    });
    setAllCategories(newCategories);
  };

  const handleSelectAllCategories = selected => {
    onUpdate({
      ...banner,
      categories: selected ? allCategories : [],
    });
    const newCategories = allCategories.map(item => ({
      ...item,
      selected: selected,
    }));
    setAllCategories(newCategories);
  };

  const { deliveryRules = [{ rule: '', url: '' }] } = banner;

  const handleDeliveryRulesChange = (id, value) => {
    const { deliveryRules = [{ rule: '', url: '' }] } = banner;
    onUpdate({
      ...banner,
      deliveryRules: [...deliveryRules.slice(0, id), value, ...deliveryRules.slice(id + 1)],
    });
  };

  const handleAddDeliveryRule = rule => {
    const { deliveryRules = [{ rule: '', url: '' }] } = banner;
    onUpdate({
      ...banner,
      deliveryRules: [...deliveryRules, rule],
    });
  };

  const handleRemoveDeliveryRule = id => {
    const { deliveryRules = [{ rule: '', url: '' }] } = banner;
    onUpdate({
      ...banner,
      deliveryRules: [...deliveryRules.slice(0, id), ...deliveryRules.slice(id + 1)],
    });
  };

  const handleKeywordsChange = ({ target: { value } }) => {
    onUpdate({
      ...banner,
      keywords: value,
    });
  };

  const handleLoad = ({ target: { naturalWidth, naturalHeight } }) => {
    setDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
    if (isSenukai) {
      if ((naturalWidth === 1920 && naturalHeight === 300) || (naturalWidth === 960 && naturalHeight === 150)) {
        setAllCategories(allCategories.map(item => ({ ...item, selected: true, disabled: false })));
        handleSelectAllCategories(true);
      } else {
        setAllCategories(allCategories.map(item => ({ ...item, selected: false, disabled: true })));
        handleSelectAllCategories(false);
      }
      setHasDeliveryRules(true);
    }
    if (isRadiopopular) {
      const categories = allCategories.map(item => ({
        ...item,
        ...(!isInitial && { selected: categoryDimensionsMatch(naturalWidth, naturalHeight, item.dimensions) }), // don't need to preselect if editing a banner
        disabled: !categoryDimensionsMatch(naturalWidth, naturalHeight, item.dimensions),
      }));
      setAllCategories(categories);
      onUpdate({
        ...banner,
        categories: categories.filter(item => item.selected),
        ...(!isInitial && { deliveryRules: [{ rule: 'contains', url: '' }] }), //preselect delivery rule
      });
      setHasSearch(categories.some(category => RADIOPOPULAR_SEARCH_CATEGORIES.includes(category.id)));
      setHasDeliveryRules(categories.some(category => RADIOPOPULAR_DELIVERY_CATEGORIES.includes(category.id)));
    }
  };

  return (
    <BannerItemView
      {...{
        name,
        url,
        parentLandingUrl: landingUrl,
        dimensions,
        size,
        landingUrl: banner.landingUrl,
        keywords: banner.keywords,
        handleKeywordsChange,
        showPreview,
        removeDialogOpen,
        handleLoad,
        handleShowPreview,
        handleRemoveDialogOpen,
        handleConfirm,
        allCategories,
        handleSelectCategory,
        handleSelectAllCategories,
        deliveryRules,
        handleDeliveryRulesChange,
        handleAddDeliveryRule,
        handleRemoveDeliveryRule,
        enableUrlOverride,
        handleEnableUrlOverride,
        handleUrlOverride,
        urlOverrideError,
        hasSearch,
        hasDeliveryRules,
        hasTargetCategories,
        isEdit,
      }}
    />
  );
};
