import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from 'components/Header';
import { Spinner } from 'components/Spinner';
import { campaignSelector, adsetSelector, adsetItemsSelector } from 'pages/dashboard/redux';
import { EditCampaignRouter } from './router';
import { setInitialCampaignAction, setAdsetAction, setItemsAction } from './redux';
import { userSelector } from 'pages/login/redux';
import { fetchDashboardDataFast } from 'pages/dashboard/async';

const EditCampaign = () => {
  const { campaignId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(true);

  const campaign = useSelector(state => campaignSelector(state, '', campaignId)); //TODO IMPROVE

  const adset = useSelector(state => adsetSelector(state, '', campaignId)); //TODO IMPROVE

  const items = useSelector(state => adsetItemsSelector(state, adset ? adset.adsetId : '')); //TODO IMPROVE

  useEffect(() => {
    if (campaign && adset && items) {
      dispatch(setInitialCampaignAction(campaign));
      dispatch(setAdsetAction(adset));
      dispatch(setItemsAction(items));

      setLoading(false);

      if (campaign.campaignType) {
        history.replace(`/edit-campaign/${campaignId}/${campaign.campaignType}`);
        return;
      }
      if (campaign.category) {
        history.replace(`/edit-campaign/${campaignId}/category`);
      } else {
        history.replace(`/edit-campaign/${campaignId}/product`);
      }
    }
  }, [campaign, adset, items]);

  useEffect(() => {
    dispatch(fetchDashboardDataFast(user.accountId));
    return () => dispatch(setInitialCampaignAction({}));
  }, []);

  return (
    <Fragment>
      <Header />
      {loading ? <Spinner fullScreen size="large" /> : <EditCampaignRouter />}
    </Fragment>
  );
};
export default EditCampaign;
