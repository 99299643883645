import React, { useEffect, useRef } from 'react';
import { classList } from 'utils/classList';
import styles from './Popup.module.scss';

const useOutsideClick = (ref, isVisible, onClose) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (isVisible && ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isVisible, onClose]);
};

export const Popup = ({ children, isVisible = false, onClose, coords = {} }) => {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, isVisible, onClose);

  return (
    <div
      ref={wrapperRef}
      className={styles.popup}
      style={isVisible ? { visibility: 'visible', ...coords } : { visibility: 'hidden' }}
    >
      {children}
    </div>
  );
};

export const PopupItem = ({ withAddon, red, children, ...props }) => (
  <div className={classList(styles.popupItem, withAddon && styles.addon, red && styles.red)} {...props}>
    {children}
  </div>
);
