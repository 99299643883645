import React from 'react';
import { DeliveryRulesView } from './views';

export const DeliveryRules = ({ rules, onAdd, onRemove, onChange }) => {
  const handleRuleChange = (value, id, rule) => {
    onChange(id, {
      ...rule,
      rule: value,
    });
  };

  const handleUrlChange = ({ target: { value } }, id, rule) => {
    onChange(id, {
      ...rule,
      url: value,
    });
  };

  const handleAddRule = () => {
    onAdd({ rule: '', url: '' });
  };

  const handleRemoveRule = id => {
    onRemove(id);
  };

  return (
    <DeliveryRulesView
      {...{
        rules,
        handleRuleChange,
        handleUrlChange,
        handleAddRule,
        handleRemoveRule,
      }}
    />
  );
};
