import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import './style.scss';

export const Tooltip = ({ title, children }) => {
  return (
    <AntTooltip placement="top" color="white" overlayClassName="tooltip-component" title={title}>
      {children}
    </AntTooltip>
  );
};
