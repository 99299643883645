import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { BannerZonesDashboard } from './dashboard';
import { CreateBannerZone } from './create';
import { EditBannerZone } from './edit';

export const BannerZonesRouter = () => (
  <Switch>
    <Route exact path="/zones" component={BannerZonesDashboard} />
    <Route exact path="/zones/new" component={CreateBannerZone} />
    <Route exact path="/zones/:zoneId" component={EditBannerZone} />
    <Redirect to="/zones" />
  </Switch>
);
