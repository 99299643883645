import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PasswordInput } from 'components/TextInput';
import { Button } from 'components/Button';
import { OverlayForm } from 'components/OverlayForm';
import styles from './reset_password.module.scss';

export const ResetPasswordView = ({
  newPassword,
  newPasswordRepeat,
  handlePasswordChange,
  handlePasswordRepeatChange,
  submitDisabled,
  handleSubmit,
  loading,
  error,
}) => (
  <OverlayForm
    title={<FormattedMessage id="reset_password_form_title" />}
    subtitle={<FormattedMessage id="register_form_subtitle" />}
  >
    <PasswordInput
      value={newPassword}
      onChange={handlePasswordChange}
      label={<FormattedMessage id="register_form_password" />}
      className={styles.password}
      error={error.newPassword}
      errorText={<FormattedMessage id="register_form_password_requirements" />}
    />
    <PasswordInput
      value={newPasswordRepeat}
      onChange={handlePasswordRepeatChange}
      label={<FormattedMessage id="reset_password_form_repeat" />}
      className={styles.passwordRepeat}
      error={error.newPasswordRepeat}
      errorText={<FormattedMessage id="reset_password_form_missmatch" />}
    />
    <Button className={styles.submit} disabled={submitDisabled} onClick={handleSubmit} loading={loading}>
      <FormattedMessage id="btn_continue" />
    </Button>
  </OverlayForm>
);
