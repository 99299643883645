import React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { ReactComponent as Icon } from 'assets/icons/IconCheckmark.svg';
import styles from './ProgressBar.module.scss';

export const homeAndProductsSteps = [
  {
    id: 1,
    title: 'campaign_steps_select_title',
  },
  {
    id: 2,
    title: 'campaign_steps_add_title',
  },
  {
    id: 3,
    title: 'campaign_steps_budget_title',
  },
];

export const editHomeAndProductsSteps = [
  {
    id: 1,
    title: 'campaign_steps_add_title',
  },
  {
    id: 2,
    title: 'campaign_steps_budget_title',
  },
];

export const categoryPageSteps = [
  {
    id: 1,
    title: 'campaign_steps_select_title',
  },
  {
    id: 2,
    title: 'campaign_steps_add_title',
  },
];

export const editCategoryPageSteps = [
  {
    id: 1,
    title: 'campaign_steps_add_title',
  },
];

export const bannerSteps = [
  {
    id: 1,
    title: 'campaign_steps_select_title',
  },
  {
    id: 2,
    title: 'campaign_steps_add_banners_title',
  },
  {
    id: 3,
    title: 'campaign_steps_set_duration_title',
  },
];

export const editBannerSteps = [
  {
    id: 1,
    title: 'campaign_steps_add_banners_title',
  },
  {
    id: 2,
    title: 'campaign_steps_set_duration_title',
  },
];

export const retailProductsSteps = [
  {
    id: 1,
    title: 'campaign_steps_select_title',
  },
  {
    id: 2,
    title: 'campaign_steps_add_title',
  },
  {
    id: 3,
    title: 'campaign_steps_set_duration_title',
  },
];

export const editRetailProductsSteps = [
  {
    id: 1,
    title: 'campaign_steps_add_title',
  },
  {
    id: 2,
    title: 'campaign_steps_set_duration_title',
  },
];

export const facebookAdsSteps = [
  {
    id: 1,
    title: 'campaign_steps_select_title',
  },
  {
    id: 2,
    title: 'campaign_steps_budget_title',
  },
];

export const editFacebookAdsSteps = [
  {
    id: 1,
    title: 'campaign_steps_budget_title',
  },
];

export const ProgressBar = ({ step, steps = [] }) => {
  const displaySteps = step === 0 && steps ? [steps[0]] : steps;
  return (
    displaySteps && (
      <div className={styles.base}>
        <div className={styles.stepsContainer}>
          {displaySteps.map(({ id, title, subtitle }) => (
            <div
              key={id}
              className={classList(styles.stepContainer, (step >= id || step === 0) && styles.active)}
              style={{ width: `${100 / displaySteps.length}%` }}
            >
              <div className={styles.stepTitle}>
                <FormattedMessage id={title} />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.barContainer}>
          {displaySteps.map(({ id }) => (
            <div key={id} className={styles.barStepContainer} style={{ width: `${100 / displaySteps.length}%` }}>
              <div className={classList(styles.barDot, (step >= id || step === 0) && styles.active)} />
              <div className={classList(styles.barLine, step > id && styles.active)} />
              {id === displaySteps.length && step !== 0 && (
                <div className={classList(styles.finalStep, step > displaySteps.length && styles.active)}>
                  <Icon />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

ProgressBar.defaultProps = {
  step: 0,
  steps: [],
};
