import React from 'react';
import { ProductCollectionSelectorView } from './views';

export const ProductCollectionSelector = ({
  productCount,
  allProductSelected,
  setAllProductSelected,
}) => {
  const handleAllSelection = () => setAllProductSelected(true);

  const handleManualSelection = () => setAllProductSelected(false);
  return (
    <ProductCollectionSelectorView
      {...{
        productCount,
        allProductSelected,
        handleAllSelection,
        handleManualSelection,
      }}
    />
  );
};
