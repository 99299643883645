import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'utils/classList';
import { FormContent } from 'components/FormContent';
import { FormGroup } from 'components/FormGroup';
import { TextInput } from 'components/TextInput';
import { TextArea } from 'components/TextArea';
import { SelectInput } from 'components/SelectInput';
import { Button } from 'components/Button';
import { ReactComponent as IconDown } from 'assets/icons/IconDown.svg';
import styles from './form.module.scss';

const zoneTypeOptions = [
  {
    name: 'Banner',
    value: 'banner',
  },
  {
    name: 'Text ad',
    value: 'text',
  },
  {
    name: 'Email zone',
    value: 'email',
  },
  {
    name: 'Video ad',
    value: 'video',
  },
];

export const BannerZoneFormView = ({
  name,
  description,
  type,
  dimensions,
  comments,
  selectedSizePreset,
  sizeOverrideEnabled,
  handleSizeOverrideEnable,
  isEdit,
  loading,
  nextDisabled,
  sizePresetOptions,
  handleNameChange,
  handleDescriptionChange,
  handleTypeChange,
  handleSizePresetChange,
  handleSizeOverrideChange,
  handleCommentsChange,
  handleNext,
  handleBack,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_zones_edit_title" /> : <FormattedMessage id="form_zones_create_title" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          {isEdit ? <FormattedMessage id="btn_save" /> : <FormattedMessage id="btn_create"  />}
        </Button>
      </Fragment>
    }
  >
    <FormGroup
      title={<FormattedMessage id="form_zones_name" />}
      subtitle={<FormattedMessage id="form_zones_name_description" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_zones_name" />} />
    </FormGroup>

    <FormGroup
      title={<FormattedMessage id="form_zones_description" />}
      subtitle={<FormattedMessage id="form_zones_description_description" />}
    >
      <TextInput
        value={description}
        onChange={handleDescriptionChange}
        label={<FormattedMessage id="form_zones_description" />}
      />
    </FormGroup>

    <FormGroup
      title={<FormattedMessage id="form_zones_type" />}
      subtitle={<FormattedMessage id="form_zones_type_description" />}
    >
      <SelectInput
        label={<FormattedMessage id="form_zones_type" />}
        value={type}
        options={zoneTypeOptions}
        onChange={handleTypeChange}
      />
    </FormGroup>

    <FormGroup
      title={<FormattedMessage id="form_zones_size" />}
      subtitle={<FormattedMessage id="form_zones_size_description" />}
    >
      <SelectInput
        className={styles.sizePresets}
        label={<FormattedMessage id="form_zones_size" />}
        value={selectedSizePreset}
        options={sizePresetOptions}
        onChange={handleSizePresetChange}
      />
      <a className={styles.sizeOverrideToggle} onClick={handleSizeOverrideEnable}>
        <FormattedMessage id="form_zones_custom_dimensions" />
        <IconDown className={classList(styles.icon, sizeOverrideEnabled && styles.expanded)} />
      </a>
      {sizeOverrideEnabled && (
        <div className={styles.sizesContainer}>
          <TextInput
            type="number"
            value={dimensions.width}
            onChange={e => handleSizeOverrideChange('width', e)}
            label={<FormattedMessage id="form_zones_width" />}
          />
          <TextInput
            type="number"
            value={dimensions.height}
            onChange={e => handleSizeOverrideChange('height', e)}
            label={<FormattedMessage id="form_zones_height" />}
          />
        </div>
      )}
    </FormGroup>

    <FormGroup title={<FormattedMessage id="form_zones_comments" />}>
      <TextArea
        rows={6}
        value={comments}
        onChange={handleCommentsChange}
        label={<FormattedMessage id="form_zones_comments" />}
      />
    </FormGroup>
  </FormContent>
);
