import React, { Fragment } from 'react';
import styles from './OverlayForm.module.scss';

export const OverlayFormView = ({ title, subtitle, children }) => (
  <Fragment>
    <div className={styles.overlay} />
    <div className={styles.form}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      {children}
    </div>
  </Fragment>
);
