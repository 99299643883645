import React from 'react';
import { classList } from 'utils/classList';
import { ReactComponent as IconInfo } from 'assets/icons/IconInfo.svg';
import { Tooltip } from 'components/Tooltip';
import styles from './FormGroup.module.scss';

export const FormGroup = ({ title, subtitle, tooltip, className, children, ...props }) => (
  <div className={classList(styles.formGroup, className)} {...props}>
    <div className={styles.labelContainer}>
      <div className={styles.labelTitle}>
        {title}
        {tooltip && (
          <Tooltip title={tooltip}>
            <IconInfo className={styles.icon} />
          </Tooltip>
        )}
      </div>
      {subtitle && <div className={styles.labelSubtitle}>{subtitle}</div>}
    </div>
    <div className={styles.inputContainer}>{children}</div>
  </div>
);
