import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isNotEmpty, isNotEmptyArray, isUrl } from 'utils/validator';
import { radioPopularSizesConfig } from 'components/SupportedSizes';
import { BannerAddFormView } from './views';

const isValidDeliveryRules = (rules = []) => isNotEmptyArray(rules) && rules.every(rule => isNotEmpty(rule.rule) && isNotEmpty(rule.url));

export const BannerAddForm = ({
  step,
  steps,
  campaign,
  shopOptions,
  initialItems = [],
  loading,
  handleBack,
  handleNext,
  handleChange,
  handleItemsChange,
  uploadBanner,
  userAccount,
  shopPreselected,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { name, shopId, landingUrl, banners = [] } = campaign;

  const [nextDisabled, setNextDisabled] = useState(false);
  const [landingUrlError, setLandingUrlError] = useState(false);
  const [showLandingUrlConfirm, setShowLandingUrlConfirm] = useState(false);
  const [initialItemsExpanded, setInitialItemsExpanded] = useState(false);

  const isRadiopopular = userAccount.shopIds.includes('radiopopularpt');
  const supportedSizes = isRadiopopular ? radioPopularSizesConfig : undefined;

  const handleItemsExpand = value => {
    setInitialItemsExpanded(value);
  };

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = value => {
    handleChange({ key: 'shopId', value });
  };

  const handleLandingUrlChange = ({ target: { value } }) => {
    handleChange({ key: 'landingUrl', value });
    setLandingUrlError(!isNotEmpty(value))
  };

  const handleShowLandingUrlConfirm = value => {
    setShowLandingUrlConfirm(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleBannersChange = async values => {
    const promises = values.map(value => dispatch(uploadBanner(value)));
    const files = await Promise.all(promises);
    const newBanners = files.map(({ payload }) => payload).filter(item => !!item);

    handleChange({ key: 'banners', value: [...banners, ...newBanners] });
  };

  const handleUpdateBanner = value => {
    handleChange({
      key: 'banners',
      value: banners.map(item => {
        if (item.url === value.url) {
          return value;
        }
        return item;
      }),
    });
  };

  const handleRemoveBanner = value => {
    handleChange({ key: 'banners', value: banners.filter(item => item.url !== value) });
  };

  const handleInitialItemsChange = value => {
    const newItems = initialItems.map(item => {
      if (item.id === value.id) {
        return value;
      }
      return item;
    });
    handleItemsChange(newItems);
  };

  const handleInitialItemsRemove = value => {
    const newItems = initialItems.filter(item => item.bannerUrl !== value);
    handleItemsChange(newItems);
  };


  const handleValidation = () => {
    if (!isNotEmpty(landingUrl)) {
      handleShowLandingUrlConfirm(true);
      setLandingUrlError(true);
    } else if(!isUrl(landingUrl)) {
      setLandingUrlError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      handleNext();
    }
  }

  useEffect(() => {
    const disabled = isEdit
      ? !isNotEmpty(name) || !shopId || !isNotEmptyArray([...initialItems, ...banners])
      : !isNotEmpty(name) || !shopId || !isNotEmptyArray(banners);
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, landingUrl, initialItems, banners]);

  return (
    <BannerAddFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext: handleValidation,
        handleConfirm: handleNext,
        loading,
        nextDisabled,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        landingUrl,
        handleLandingUrlChange,
        banners,
        handleBannersChange,
        handleUpdateBanner,
        handleRemoveBanner,
        initialItems,
        handleInitialItemsChange,
        handleInitialItemsRemove,
        landingUrlError,
        showLandingUrlConfirm,
        handleShowLandingUrlConfirm,
        userAccount,
        supportedSizes,
        shopPreselected,
        initialItemsExpanded,
        handleItemsExpand,
        isEdit,
      }}
    />
  );
};
