import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validateEmail } from 'utils/validator';
import { passwordResetSelector, setPasswordResetAction, postPasswordReset } from './redux';
import { PasswordResetView } from './views';

export const PasswordReset = () => {
  const dispatch = useDispatch();
  const { email, status, error, loading } = useSelector(passwordResetSelector);

  const handleEmailChange = ({ target: { value } }) => {
    dispatch(setPasswordResetAction({ key: 'email', value: value }));
  };

  const handleValidate = e => {
    e.preventDefault();
    let emailIsValid = validateEmail(email);
    if (!emailIsValid) {
      dispatch(setPasswordResetAction({ key: 'error', value: true }));
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let formData = new FormData();

    formData.append('email', email);
    dispatch(postPasswordReset(formData));
  };

  const handleEmailFocus = () => {
    dispatch(setPasswordResetAction({ key: 'error', value: false }));
  };

  return (
    <PasswordResetView
      {...{
        email,
        status,
        error,
        loading,
        handleEmailChange,
        handleEmailFocus,
        handleValidate,
      }}
    />
  );
};
