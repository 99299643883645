import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { uniqBy } from 'lodash';
import { RetailProductItemView } from './views';

const mapKeyword = item => ({id: item, title: item.replaceAll("+", " "), selected: true});

export const RetailProductItem = ({ itemId, name, url, status, isEdit, isSearch, product, onRemove, onUpdate }) => {
  const [tooltipText, setTooltipText] = useState('Copy');
  const [showKeywords, setShowKeywords] = useState(!!isEdit && !!isSearch);
  const [allKeywords, setAllKeywords] = useState([]);

  useEffect(() => {
    const searchTerms = (product.searchTerms|| []).map(mapKeyword);
    const keywords = (product.keywords|| []).map(mapKeyword);
    const newKeywords = uniqBy([...searchTerms, ...keywords], 'id');
    setAllKeywords(newKeywords)

    onUpdate({
      ...product,
      keywords: newKeywords.filter(kw => kw.selected).map(kw => kw.id),
    });
  }, []);

  const handleShowKeywords = () => {
    setShowKeywords(!showKeywords);
  };

  const handleCopy = value => {
    copy(value);
    setTooltipText('Copied!');
    setTimeout(() => setTooltipText('Copy'), 1000);
  };

  const handleSelectKeyword = (id, selected) => {
    const newKeywords = allKeywords
      .filter(item => !!item.id)
      .map(item => {
        if (item.id === id) {
          return { ...item, selected };
        }
        return item;
      });
    setAllKeywords(newKeywords);
    
    onUpdate({
      ...product,
      keywords: newKeywords.filter(kw => kw.selected).map(kw => kw.id),
    });
  };

  const handleSelectAllKeywords = selected => {
    const newKeywords = allKeywords.map(item => ({
      ...item,
      selected: selected,
    }));
    setAllKeywords(newKeywords);
    
    onUpdate({
      ...product,
      keywords: newKeywords.filter(kw => kw.selected).map(kw => kw.id),
    });
  };

  const handleAddNewKeyword = value => {
    const keyword = { id: value.replaceAll(" ", "+"), title: value, selected: true };
    const newKeywords = [...allKeywords, keyword]
    setAllKeywords(newKeywords);
    
    onUpdate({
      ...product,
      keywords: newKeywords.filter(kw => kw.selected).map(kw => kw.id),
    });
  };

  const handleStatusChange = value => {
    onUpdate({
      ...product,
      disabled: !value,
    });
  };

  return (
    <RetailProductItemView
      {...{
        itemId,
        name,
        url,
        status,
        handleStatusChange,
        onRemove,
        tooltipText,
        handleCopy,
        allKeywords,
        handleSelectKeyword,
        handleSelectAllKeywords,
        showKeywords,
        handleShowKeywords,
        handleAddNewKeyword,
        isSearch,
        isEdit,
      }}
    />
  );
};
