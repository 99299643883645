import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button';
import { TextInput } from 'components/TextInput';
import { SelectInput } from 'components/SelectInput';
import { FormGroup } from 'components/FormGroup';
import { FormContent } from 'components/FormContent';
import { ProgressBar } from 'components/ProgressBar';
import { Placement } from 'components/Placement';
import { ProductPicker } from 'components/ProductPicker';
import { RetailProductItem } from 'components/RetailProductItem';
import styles from './retail_product_add_form.module.scss';

export const RetailProductFormView = ({
  step,
  steps,
  handleBack,
  handleAdd,
  handleNext,
  handleSelectedItemsChange,
  loading,
  nextDisabled,
  name,
  handleNameChange,
  shopId,
  handleStoreChange,
  shopOptions,
  shopPreselected,
  itemUrlText,
  handleItemsUrlChange,
  initialItems,
  handleInitialItemsChange,
  items,
  invalidItems,
  handleUpdateItem,
  handleRemoveItem,
  handleRemoveAllItems,
  products,
  allPlacements,
  handleSelectPlacement,
  handleSearch,
  initialItemsExpanded,
  handleItemsExpand,
  isSearch,
  isEdit,
}) => (
  <FormContent
    title={isEdit ? <FormattedMessage id="form_edit_campaign" /> : <FormattedMessage id="form_create_new_campaign" />}
    footerControls={
      <Fragment>
        <Button type="ghost" size="large" onClick={handleBack}>
          <FormattedMessage id="btn_back" />
        </Button>
        <Button size="large" onClick={handleNext} loading={loading} disabled={nextDisabled}>
          <FormattedMessage id="btn_next" />
        </Button>
      </Fragment>
    }
  >
    {steps && <ProgressBar steps={steps} step={step} />}
    <FormGroup
      title={<FormattedMessage id="form_campaign_name" />}
      subtitle={<FormattedMessage id="form_campaign_name_description" />}
      tooltip={<FormattedMessage id="form_campaign_name_tooltip" />}
    >
      <TextInput value={name} onChange={handleNameChange} label={<FormattedMessage id="form_campaign_name" />} />
    </FormGroup>
    {!isEdit && !shopPreselected && (
      <FormGroup
        title={<FormattedMessage id="form_shop" />}
        subtitle={<FormattedMessage id="form_shop_description" />}
        tooltip={<FormattedMessage id="form_shop_tooltip" />}
      >
        <SelectInput
          value={shopId}
          onChange={handleStoreChange}
          label={<FormattedMessage id="form_shop_placeholder" />}
          options={shopOptions}
        />
      </FormGroup>
    )}
    {isEdit && !!initialItems.length && (
      <Fragment>
        <FormGroup
          style={{ marginBottom: '0px' }}
          title={
            <span>
              <FormattedMessage id="form_products" /> ({initialItems.length})
            </span>
          }
        >
          <div className={styles.expandContainer}>
            {initialItemsExpanded ? (
              <Button type="ghost" size="small" onClick={() => handleItemsExpand(false)}>
                <FormattedMessage id="btn_collapse_items" />
              </Button>
            ) : (
              <Button type="ghost" size="small" onClick={() => handleItemsExpand(true)}>
                <FormattedMessage id="btn_expand_items" />
              </Button>
            )}
          </div>
        </FormGroup>
        {initialItemsExpanded && (
          <div>
            {initialItems.map(item => (
              <RetailProductItem
                key={item.id}
                product={item}
                itemId={item.itemId}
                name={item.name}
                url={item.url}
                status={!item.disabled}
                onUpdate={handleInitialItemsChange}
                isSearch={isSearch}
                isEdit
              />
            ))}
          </div>
        )}
      </Fragment>
    )}
    <FormGroup
      title={<FormattedMessage id="form_product_ad_placement" />}
      subtitle={<FormattedMessage id="form_product_ad_placement_description" />}
    >
      <Placement items={allPlacements} handleSelect={handleSelectPlacement} noSearch />
    </FormGroup>
    <FormGroup
      title={<FormattedMessage id="form_add_products" />}
      subtitle={<FormattedMessage id="form_product_urls_description" />}
      tooltip={<FormattedMessage id="form_product_urls_tooltip" />}
    ></FormGroup>
    <div className={styles.searchContainer}>
      <ProductPicker
        items={products}
        invalidItems={invalidItems}
        onQueryChange={handleSearch}
        handleSelect={handleSelectedItemsChange}
        handleRemoveAll={handleRemoveAllItems}
        itemUrlText={itemUrlText}
        handleAdd={handleAdd}
        handleItemsUrlChange={handleItemsUrlChange}
        searchPlaceholder={<FormattedMessage id="form_products_search_placeholder" />}
        searchDisabled={!shopId}
      />
    </div>
    {!!items.length && (
      <div>
        {items.map(item => (
          <RetailProductItem
            key={item.itemId}
            product={item}
            itemId={item.itemId}
            name={item.name}
            url={item.url}
            status={!item.disabled}
            onRemove={handleRemoveItem}
            onUpdate={handleUpdateItem}
            isSearch={isSearch}
          />
        ))}
      </div>
    )}
  </FormContent>
);
