import React, { useEffect, useState } from 'react';
import { isNotEmpty } from 'utils/validator';
import { FacebookAdsFormView } from './views';

const dailyBudgetOptions = [5, 10, 20, 30, 40, 50].map(item => ({
  name: `€${item.toFixed(2)}`,
  value: item,
}));

const campaignDurationOptions = [1, 2, 3, 4, 5].map(item => ({
  name: item,
  value: item,
}));

export const FacebookAdsForm = ({
  step,
  steps,
  campaign,
  shopOptions,
  loading,
  handleBack,
  handleNext,
  handleChange,
  isEdit,
}) => {
  const { name, shopId, dailyBudget, duration } = campaign;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [totalCosts, setTotalCosts] = useState(0);

  const handleNameChange = ({ target: { value } }) => {
    handleChange({ key: 'name', value });
  };

  const handleStoreChange = value => {
    handleChange({ key: 'shopId', value });
  };

  const handleDailyBudgetChange = value => {
    handleChange({ key: 'dailyBudget', value });
  };

  const handleCampaignDurationChange = value => {
    handleChange({ key: 'duration', value });
  };

  useEffect(() => {
    const totalCosts = dailyBudget * 30 * duration;
    setTotalCosts(totalCosts);
  }, [dailyBudget, duration]);

  useEffect(() => {
    const disabled = isEdit ? !isNotEmpty(name) || !dailyBudget || !duration : !isNotEmpty(name) || !shopId || !dailyBudget || !duration;
    setNextDisabled(disabled);
  }, [isEdit, name, shopId, dailyBudget, duration]);

  return (
    <FacebookAdsFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        name,
        handleNameChange,
        shopId,
        handleStoreChange,
        shopOptions,
        dailyBudget,
        handleDailyBudgetChange,
        dailyBudgetOptions,
        duration,
        handleCampaignDurationChange,
        campaignDurationOptions,
        loading,
        nextDisabled,
        totalCosts,
        isEdit,
      }}
    />
  );
};
