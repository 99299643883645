import React from 'react';
import { VendorProductBudgetForm } from 'forms/vendor_product/vendor_product_budget_form';

export const VendorProductBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <VendorProductBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
  />
);
