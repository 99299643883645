import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { RetailProductBudgetFormView } from './views';

export const RetailProductBudgetForm = ({
  step,
  steps,
  campaign,
  loading,
  handleBack,
  handleNext,
  handleChange,
  isEdit,
}) => {
  const {
    priorityType,
    priorityLevel,
    startDate,
    endDate,
    pricingModel,
    pricingModelPrice,
    totalBudget,
    limit,
    limitAmount,
    limitType,
  } = campaign;
  const [minDate] = useState(moment().startOf('day'));
  const minEndDate = isEdit ? (moment(startDate).isSameOrAfter(minDate) ? null : minDate) : null;
  const [nextDisabled, setNextDisabled] = useState(false);
  const [totalBudgetEnabled, setTotalBudgetEnabled] = useState(!!totalBudget);

  const handlePriorityTypeChange = value => {
    handleChange({ key: 'priorityType', value });
  };

  const handlePriorityLevelChange = value => {
    handleChange({ key: 'priorityLevel', value });
  };

  const handleStartDateChange = value => {
    handleChange({ key: 'startDate', value });
  };

  const handleEndDateChange = value => {
    handleChange({ key: 'endDate', value });
  };

  const handlePricingModelChange = value => {
    handleChange({ key: 'pricingModel', value });
  };

  const handlePricingModelPriceChange = value => {
    handleChange({ key: 'pricingModelPrice', value });
  };

  const handleTotalBudgetChange = value => {
    handleChange({ key: 'totalBudget', value });
  };

  const handleLimitChange = value => {
    handleChange({ key: 'limit', value });
  };

  const handleLimitAmountChange = ({ target: { value } }) => {
    handleChange({ key: 'limitAmount', value });
  };

  const handleLimitTypeChange = value => {
    handleChange({ key: 'limitType', value });
  };

  const handleTotalBudgetToggle = value => {
    setTotalBudgetEnabled(value);
    if (!value) {
      handleTotalBudgetChange(0);
    }
  };

  useEffect(() => {
    setNextDisabled(!startDate || !pricingModel /*|| !pricingModelPrice*/);
  }, [startDate, pricingModel, pricingModelPrice]);

  return (
    <RetailProductBudgetFormView
      {...{
        step,
        steps,
        handleBack,
        handleNext,
        loading,
        nextDisabled,
        priorityType,
        priorityLevel,
        minDate,
        minEndDate,
        startDate,
        endDate,
        pricingModel,
        pricingModelPrice,
        totalBudgetEnabled,
        totalBudget,
        handleTotalBudgetToggle,
        handleTotalBudgetChange,
        limit,
        limitAmount,
        limitType,
        handlePriorityTypeChange,
        handlePriorityLevelChange,
        handleStartDateChange,
        handleEndDateChange,
        handlePricingModelChange,
        handlePricingModelPriceChange,
        handleLimitChange,
        handleLimitAmountChange,
        handleLimitTypeChange,
        isEdit,
      }}
    />
  );
};
