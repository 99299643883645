import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadView } from './views';

export const FileUpload = ({ placeholder, onChange }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(newFiles);
      onChange(newFiles);
    },
    // Disable keydown behavior
    noKeyboard: true,
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <FileUploadView
      files={files}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      placeholder={placeholder}
      isDragActive={isDragActive}
    />
  );
};
