import React from 'react';
import { RetailProductBudgetForm } from 'forms/retail_product/retail_product_budget_form';

export const RetailProductBudgetView = ({ step, steps, campaign, loading, handleBack, handleNext, handleChange }) => (
  <RetailProductBudgetForm
    {...{
      step,
      steps,
      campaign,
      loading,
      handleBack,
      handleNext,
      handleChange,
    }}
  />
);
